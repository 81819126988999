import SheetsConfirm from '@/pages/matchs/sheetConfirm/index' // EXCEL数据确认
import MatchReport from '@/pages/matchs/matchReport/index' // 匹配结果简报
import MatchDetail from '@/pages/matchs/matchDetail/index' // 匹配结果详情
import SettingResult from '@/pages/matchs/settingSuccess/index' // 设置结果

export default [
  {
    path: '/matchs/sheetsConfirm',
    name: 'EXCEL确认',
    component: SheetsConfirm
  },
  {
    path: '/matchs/matchReport',
    name: '匹配结果简报',
    component: MatchReport
  },
  {
    path: '/matchs/matchDetail',
    name: '匹配结果详情',
    component: MatchDetail
  },
  {
    path: '/matchs/settingResult',
    name: '设置结果',
    component: SettingResult
  }
]
