import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Select } from 'antd'

const { Option } = Select

class UploadSheetForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    name: PropTypes.string,
    list: PropTypes.array,
    file: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }

  state = {}

  handleConfirm = () => {
    const { form, file, onConfirm } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        onConfirm({ ...values, file: file })
      }
    })
  }

  render () {
    const { form, name, list = [], onCancel } = this.props
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 }
    }

    return (
      <>
        <Modal title='列表选择'
          visible
          onCancel={() => onCancel()}
          onOk={() => this.handleConfirm()}
        >
          <Form>
            <Form.Item label='EXCEL名称' {...formItemLayout}>{ name }</Form.Item>
            <Form.Item label='选择列表' {...formItemLayout}>
              {
                getFieldDecorator('sheetNum', {
                  initialValue: list.length > 0 ? 0 : null,
                  rules: [
                    { required: true, message: '请选择列表' }
                  ]
                })(
                  <Select>
                    {
                      list.map((item, i) => (
                        <Option value={i} key={`${item}-${i}`}>{ item }</Option>
                      ))
                    }
                  </Select>
                )
              }
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }
}

const UploadSheetModal = Form.create()(UploadSheetForm)

export default UploadSheetModal
