import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Table, Descriptions, InputNumber, Input, message, Alert } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import Api from '@/common/api/index'
import consts from '@/common/utils/consts'

const { SERIALWIDTH, MAX_NUMBER } = consts

const EditableContext = React.createContext()

class EditableCell extends Component {
  static propTypes = {
    editing: PropTypes.bool,
    dataIndex: PropTypes.string,
    title: PropTypes.string,
    inputType: PropTypes.string,
    record: PropTypes.object,
    index: PropTypes.number,
    children: PropTypes.array
  }

  getInput = () => {
    const { inputType, dataIndex, record = {} } = this.props

    if (inputType === 'number') {
      return (
        <InputNumber size='small' disabled={(record.arrivalQuantity - record.deliveryQuantity) <= 0}
          min={0}
          max={dataIndex === 'prepareSendQuantity' ? (record.arrivalQuantity - record.deliveryQuantity) : (MAX_NUMBER)}
        />
      )
    }
    return <Input size='small' />
  }

  renderCell = ({ getFieldDecorator }) => {
    const { editing, dataIndex, title, inputType, record, index, children, ...restProps } = this.props

    return (
      <td {...restProps}>
        {editing
          ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(`${record.commoditySkuId}-${dataIndex}`, {
              rules: [
                { required: false, message: `请输入 ${title}!` }
              ],
              initialValue: record[dataIndex]
            })(this.getInput())}
          </Form.Item>
            )
          : (
              children
            )}
      </td>
    )
  }

  render () {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
  }
}

class EditableTable extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }

  state = {
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'prepareCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'prepareCol-1',
      dataIndex: 'brandName',
      ellipsis: true
    },
    {
      title: '型号',
      key: 'prepareCol-2',
      width: 140,
      dataIndex: 'model',
      ellipsis: true
    },
    {
      title: '订货号',
      key: 'prepareCol-3',
      width: 130,
      dataIndex: 'itemNumber',
      ellipsis: true
    },
    {
      title: '产品名称',
      key: 'prepareCol-4',
      dataIndex: 'name',
      ellipsis: true
    },
    {
      title: '订单数量',
      key: 'prepareCol-5',
      width: 90,
      dataIndex: 'quantity'
    },
    {
      title: '已订数量',
      key: 'prepareCol-6',
      width: 90,
      dataIndex: 'arrivalQuantity'
    },
    {
      title: '待订数量',
      key: 'prepareCol-7',
      width: 90,
      render: (text, record, index) => (
        <>{ +(record.quantity) - +(record.arrivalQuantity)}</>
      )
    },
    {
      title: '已发货数量',
      key: 'prepareCol-8',
      width: 90,
      dataIndex: 'deliveryQuantity'
    },
    {
      title: '本次发货数量',
      key: 'prepareCol-9',
      width: 110,
      editable: true,
      dataIndex: 'prepareSendQuantity',
      render: (text, record, index) => (
        <>{ (+record.quantity) - (+record.arrivalQuantity)}</>
      )
    }
  ]

  componentDidMount () {
    const { detailInfo = {} } = this.props
    const params = {
      orderId: detailInfo.orderId,
      purchaseOrderId: detailInfo.orderType === 'ORDER_CLOUD_FUTURES' ? detailInfo.outboundOrderNo : null,
      pageNo: 1,
      pageSize: 999
    }

    this.getCloudfuturesOrderProductDetails(params)
  }

  // 获取云期货供应订单产品详情
  getCloudfuturesOrderProductDetails = async (params) => {
    const res = await Api.getCloudfuturesOrderProductDetails(params)
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || []
      })
    }
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = {}, onConfirm } = this.props
        const { tableData = [] } = this.state
        const newArr = $lodash.map(values, (val, key) => {
          const keyArr = $lodash.split(key, '-')
          const obj = $lodash.find(tableData, (item) => { return item.commoditySkuId === +keyArr[0] })

          return {
            commoditySkuId: +keyArr[0],
            quantity: val,
            maxQuantity: obj !== undefined ? (obj.arrivalQuantity - obj.deliveryQuantity) : null
          }
        })

        const newdatas = $lodash.filter(newArr, (o) => { return o.quantity && +o.quantity > 0 })

        if (newdatas.length === 0) {
          return message.warning('请至少填写一种产品的发货数量')
        }

        const params = {
          orderId: detailInfo.orderId,
          orderType: detailInfo.orderType,
          purchaseOrderId: detailInfo.orderType === 'ORDER_CLOUD_FUTURES' ? detailInfo.outboundOrderNo : null,
          batchQuantity: newdatas
        }

        onConfirm(params)
      }
    })
  }

  render () {
    const { detailInfo = {} } = this.props
    const { tableData = [] } = this.state
    const components = {
      body: {
        cell: EditableCell
      }
    }

    const columns = this.columns.map(col => {
      if (!col.editable) { return col }

      return {
        ...col,
        onCell: record => ({
          record,
          inputType: 'number',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: col.editable || false
        })
      }
    })

    return (
      <>
        <Modal title='分批发货' width={1000} bodyStyle={{ padding: '16px' }}
          visible
          maskClosable={false}
          onOk={() => this.handleConfirm()}
          onCancel={() => this.props.onCancel()}
        >
          <Descriptions>
            <Descriptions.Item label='订单编号'>{ detailInfo.orderId }</Descriptions.Item>
            <Descriptions.Item label='订单日期'>
              {detailInfo.orderTime ? moment(detailInfo.orderTime).format('YYYY-MM-DD HH:mm:ss') : null}
            </Descriptions.Item>
            <Descriptions.Item label='客户名称'>{ detailInfo.customerName }</Descriptions.Item>
          </Descriptions>

          <div style={{ marginBottom: '15px' }}>
            <Alert type='warning' showIcon
              message={<span style={{ fontSize: '12px', color: '#666666' }}>请在下方输入您本次想发货的数量（注：本功能为期货部分发货功能，请按实际情况填写本次发货的数量，本次发货数量限制为发货数量≤已订数量-已发数量，确认后会通知买家按数量比例进行付款。）</span>}
            />
          </div>

          <EditableContext.Provider value={this.props.form}>
            <Table rowKey='commoditySkuId'
              components={components}
              columns={columns}
              dataSource={tableData}
              pagination={false}
            />
          </EditableContext.Provider>
        </Modal>
      </>
    )
  }
}

const PrepareSendModal = Form.create()(EditableTable)

export default PrepareSendModal
