import React, { Component } from 'react'
import { Card, Radio } from 'antd'

import BillDb from '../transportBill-DB/index'

const menuMaps = [
  { id: 'SAASDBLBILL', name: '运费账单（德邦）' }
]

export default class index1 extends Component {
  state={
    activeTabKey: 'SAASDBLBILL'
  }

  // 点击切换tab
  handleToggleTabmenu = (key) => {
    this.setState({
      activeTabKey: key
    })
  }

  render () {
    const { activeTabKey = 'SAASDBLBILL' } = this.state

    return (
      <>
        <Card bordered={false}>
          <div style={{ marginBottom: '24px' }}>
            <Radio.Group buttonStyle='solid' value={activeTabKey} onChange={(e) => this.handleChangeTabPane(e.target.value)}>
              {
                menuMaps.map((item, n) => (
                  <Radio.Button value={item.id} key={item.id}>{ item.name }</Radio.Button>
                ))
              }
            </Radio.Group>
          </div>

          {
            activeTabKey === 'SAASDBLBILL'
              ? <BillDb></BillDb>
              : null
          }
        </Card>
      </>
    )
  }
}
