import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Table, Row, Col, message, Modal, Upload, Button, InputNumber, Popconfirm, Icon, Dropdown, Menu, Tag } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import BatchSearchMenu from '@/components/batchSearchMenu/index'
import SoldInLogModal from '../soldInLogModal/index' // 操作日志
import ProductModal from '../productModal/index'// 添加产品
import UploadSheetModal from '@/components/uploadSheetModal/index'

import Api from '@/common/api'
import UploadApi from '@/common/api/uploadApi'
import DownloadApi from '@/common/api/downloadApi'
import { getStore, renderBrandAgencyStatusLabel, renderProductPutOnStatusLabel, renderCloudStoreLevelLabel } from '@/common/utils/mUtils'
import { setShowLoading } from '@/reducers/action'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'

const { uploadMatchExcelUrl } = UploadApi
const { pageSizeOptions, productPutOnMaps, brandAgencyStatusMaps, importConfig, cloudStoreLevelMaps } = filter
const { confirm } = Modal
const { SERIALWIDTH, REQUEST_TIME_OUT } = consts
const SKUTYPE = 'FUTURES'

// 可编辑单元格
const EditableContext = React.createContext()

class EditableCell extends Component {
  static propTypes = {
    form: PropTypes.object,
    editing: PropTypes.bool,
    dataIndex: PropTypes.string,
    title: PropTypes.string,
    inputType: PropTypes.string,
    record: PropTypes.object,
    index: PropTypes.number,
    children: PropTypes.array
  }

  renderCell = ({ getFieldDecorator }) => {
    const { editing, dataIndex, title, inputType, record, index, children, ...restProps } = this.props

    return (
      <td {...restProps}>
        {editing
          ? (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(dataIndex, {
                rules: [
                  { required: true, message: `请输入${title}!` }
                ],
                initialValue: record[dataIndex]
              })(<InputNumber />)}
            </Form.Item>
            )
          : (
              children
            )}
      </td>
    )
  }

  render () {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
  }
}

class SupplyFuturesProducts extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object,
    dispatch: PropTypes.func
  }

  state = {
    searchData: [
      {
        name: 'keyword',
        formType: 'Input',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入型号或订货号',
          autoComplete: 'off'
        }
      },
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showLabel']
      },
      {
        name: 'cloudSupplyFuturesStatus',
        formType: 'Select',
        itemParams: {
          label: '产品状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...productPutOnMaps
        ]
      },
      {
        name: 'brandAgencyStatus',
        formType: 'Select',
        itemParams: {
          label: '代理证状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...brandAgencyStatusMaps
        ]
      },
      {
        name: 'outDateLevel',
        formType: 'Select',
        itemParams: {
          label: '云仓等级'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...cloudStoreLevelMaps
        ]
      },
      {
        name: 'modifyTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '更新时间'
        },
        cptParams: {

        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    selectedRowKeys: [],
    logModal: false,
    productModal: false,
    rowDetail: {}, // 当前选择的行数据
    editingKey: '', // 编辑中的产品ID
    showPage: false,
    uploadSheetModal: false,
    uploadSheetDetails: {}
  }

  columns = [
    {
      title: '序号',
      key: 'supplyFuturesProductsCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'supplyFuturesProductsCol-1',
      width: 120,
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'supplyFuturesProductsCol-2',
      width: 140,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <span dangerouslySetInnerHTML={{ __html: record.model }} />
        </>
      )
    },
    {
      title: '订货号',
      key: 'supplyFuturesProductsCol-3',
      width: 120,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
        </>
      )
    },
    {
      title: '产品名称',
      key: 'supplyFuturesProductsCol-4',
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '系列',
      key: 'supplyFuturesProductsCol-5',
      width: 120,
      dataIndex: 'series',
      ellipsis: true
    },
    {
      title: '云供应价格',
      key: 'supplyFuturesProductsCol-6',
      dataIndex: 'cloudSupplyFuturesPrice',
      editable: true,
      render: (text, record, index) => (
        <>
          {
            record.cloudSupplyFuturesPrice
              ? <>&yen;{record.cloudSupplyFuturesPrice}</>
              : '----'
          }
        </>
      )
    },
    {
      title: '货期',
      key: 'supplyFuturesProductsCol-7',
      dataIndex: 'deliveryPeriodDays',
      editable: true,
      render: (text, record, index) => (
        <>
          {
            record.deliveryPeriodDays
              ? <>{ +record.deliveryPeriodDays }&nbsp;工作日</>
              : '----'
          }
        </>
      )
    },
    {
      title: '预付比例',
      key: 'supplyFuturesProductsCol-8',
      dataIndex: 'prepaymentRatio',
      editable: true,
      render: (text, record, index) => (
        <>
          {
            record.prepaymentRatio
              ? <>{ +record.prepaymentRatio }%</>
              : '----'
          }
        </>
      )
    },
    {
      title: '产品状态',
      key: 'supplyFuturesProductsCol-9',
      width: 120,
      render: (text, record, index) => (
        <>{ renderProductPutOnStatusLabel(record.cloudSupplyFuturesStatus) }</>
      )
    },
    {
      title: '代理证状态',
      key: 'supplyFuturesProductsCol-10',
      width: 120,
      dataIndex: 'brandAgencyStatus',
      render: (text, record, index) => (
        <>{ renderBrandAgencyStatusLabel(record.brandAgencyStatus) }</>
      )
    },
    {
      title: '云仓等级',
      key: 'ssupplyFuturesProductsCol-11',
      dataIndex: 'outDateLevel',
      render: (text, record, index) => (
        <>{ renderCloudStoreLevelLabel(record.outDateLevel) }</>
      )
    },
    {
      title: '更新时间',
      key: 'supplyFuturesProductsCol-12',
      dataIndex: 'modifyTime',
      render: (text, record, index) => (
        <>{ record.modifyTime ? moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '操作',
      key: 'supplyFuturesProductsCol-13',
      width: 260,
      render: (text, record, index) => {
        const { editingKey } = this.state
        const editable = this.isEditing(record)

        return editable
          ? (
          <>
            <EditableContext.Consumer>
              {
                form => (
                  <Button type='link' size='small' onClick={() => this.handleSave(form, record)} >保存</Button>
                )
              }
            </EditableContext.Consumer>

            <Popconfirm title='确定放弃编辑?' onConfirm={() => this.handleCancel()}>
              <Button type='link' size='small'>取消</Button>
            </Popconfirm>
          </>
            )
          : (
          <>
            {
              record.brandAuthStatus
                ? <>
                    {
                      record.cloudSupplyFuturesStatus === 'ENABLE'
                        ? <>
                            <Button type='link' size='small' disabled={editingKey !== ''} onClick={() => this.handleEdit(record.commoditySkuId)}>编辑价格</Button>
                            <Button type='link' size='small' disabled={editingKey !== ''} onClick={() => this.handleSetSoldOut([record.commoditySkuId])}>产品下架</Button>
                        </>
                        : <Button type='link' size='small' disabled={editingKey !== ''} onClick={() => this.handleEdit(record.commoditySkuId)}>产品上架</Button>
                    }
                </>
                : null
            }

            {
              !record.brandAuthStatus || record.cloudSupplyFuturesStatus === 'DISABLE'
                ? <Button type='link' size='small' disabled={editingKey !== ''} onClick={() => this.handleDeleteProducts([record.commoditySkuId])}>删除产品</Button>
                : null
            }

            <Button type='link' size='small' disabled={editingKey !== ''} onClick={() => this.handleShowModal('logModal', record)}>
              操作日志
            </Button>
          </>
            )
      }
    }
  ]

  componentDidMount () {
    this.getInitPageData()
  }

  // 获取页面相关数据
  getInitPageData = async () => {
    const result = await Api.getBrandList({ brandListType: 'CLOUD_SUPPLY_BRAND' })

    if (+result.code === 10000 && result.data && result.data.length > 0) {
      const { searchData = [] } = this.state

      result.data.forEach(item => {
        item.showLabel = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
      })

      searchData[1].options = $lodash.concat([{ brandId: 0, showLabel: '全部' }], result.data)

      this.setState({
        searchData
      }, () => { this.getCloudFuturesProductList() })
    }
  }

  // 获取云供应期货产品列表
  getCloudFuturesProductList = () => {
    const { pages, query } = this.state

    const params = {
      ...query,
      skuType: SKUTYPE,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getCloudFuturesProductList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 云供应上架
  saveCloudSupplySoldIn = async (params) => {
    const res = await Api.saveCloudSupplySoldIn(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')

      setTimeout(() => {
        this.setState({
          editingKey: ''
        }, () => {
          this.getCloudFuturesProductList()
        })
      }, REQUEST_TIME_OUT)
    } else {
      message.warning(res.message)
    }
  }

  // 云供应下架
  saveCloudSupplySoldOut = async (params) => {
    const res = await Api.saveCloudSupplySoldOut(params)
    const { code, data } = res
    if (+code === 10000) {
      const { successNum = 0, failNum = 0 } = data

      this.successCountDown('SOLDOUT', successNum, failNum, () => {
        this.setState({
          editingKey: '',
          selectedRowKeys: []
        }, () => this.getCloudFuturesProductList())
      })
    } else {
      message.warning(res.message)
    }
  }

  // 云供应产品删除
  saveCloudSupplyDelete = async (params) => {
    const res = await Api.saveCloudSupplyDelete(params)
    const { code, data } = res
    if (+code === 10000) {
      const { successNum = 0, failNum = 0 } = data

      this.successCountDown('DELETE', successNum, failNum, () => {
        this.setState({
          editingKey: '',
          selectedRowKeys: []
        }, () => this.getCloudFuturesProductList())
      })
    } else {
      message.warning(res.message)
    }
  }

  // 上传EXCEL智能解析
  saveUploadExcelMatchSmartparse = async (params) => {
    const res = await Api.saveUploadExcelMatchSmartparse(params)
    const { code, data } = res
    if (+code === 10000) {
      this.closeModal()
      this.changeUploadSuccess(data)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    const { brandId = 0, modifyTimes } = search

    if (modifyTimes) {
      search.beginDate = modifyTimes.length === 2 ? modifyTimes[0].format('YYYY-MM-DD') : undefined
      search.endDate = modifyTimes.length === 2 ? modifyTimes[1].format('YYYY-MM-DD') : undefined
    }

    search.brandIdList = brandId > 0 ? [brandId] : [0]

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => {
      this.getCloudFuturesProductList()
    })
  }

  // 点击下架(批量下架)产品
  handleSetSoldOut = (keys = []) => {
    confirm({
      title: '确定下架云供应产品？',
      content: '',
      onOk: () => {
        const { pages } = this.state
        const params = {
          commoditySkuIdList: keys,
          skuType: SKUTYPE,
          pageNo: pages.pageNo,
          pageSize: pages.pageSize
        }
        this.saveCloudSupplySoldOut(params)
      }
    })
  }

  // 点击删除产品
  handleDeleteProducts = (keys = []) => {
    confirm({
      title: '确定删除?',
      content: '',
      onOk: () => {
        this.saveCloudSupplyDelete({ commoditySkuIdList: keys, skuType: SKUTYPE })
      }
    })
  }

  // 选择产品
  onSelectChange = (keys, rows) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 点击编辑
  handleEdit = (key) => {
    this.setState({
      editingKey: key
    })
  }

  // 点击保存编辑
  handleSave = (form, record) => {
    form.validateFields((err, values) => {
      if (!err) {
        values.commoditySkuId = record.commoditySkuId
        values.skuType = SKUTYPE

        this.saveCloudSupplySoldIn(values)
      }
    })
  }

  // 取消编辑
  handleCancel = () => {
    this.setState({
      editingKey: ''
    })
  }

  // 编辑中
  isEditing = (record) => record.commoditySkuId === this.state.editingKey

  // 点击下载模板
  handleDownload = () => {
    DownloadApi.downloadCloudSupplyFuturesTemplate({})
  }

  // 点击查看日志 && 添加产品
  handleShowModal = (type, record) => {
    this.setState({
      rowDetail: record || {},
      [type]: true
    })
  }

  // 上传价格
  onChangeUploadFile = (info) => {
    if (info.file.status === 'uploading') {
      this.props.dispatch(setShowLoading({ loading: true }))

      return false
    }

    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        setTimeout(() => {
          this.props.dispatch(setShowLoading({ loading: false }))
          this.setState({
            uploadSheetDetails: {
              name: data.tableName || '',
              list: data.sheetNames || [],
              file: info.file.originFileObj
            },
            uploadSheetModal: true
          })
        }, REQUEST_TIME_OUT)
      } else {
        this.props.dispatch(setShowLoading({ loading: false }))
        message.error(info.file.response.message || `${info.file.name}上传失败`)
      }
    } else if (info.file.status === 'error') {
      this.props.dispatch(setShowLoading({ loading: false }))
      message.error(`${info.file.name}上传失败`)
    }
  }

  // 点击确定选择导入EXCEL的sheet
  handleConfirmUploadSheet = (values) => {
    const formData = new FormData()

    $lodash.forEach(values, (value, key) => {
      formData.append(key, value)
    })

    this.saveUploadExcelMatchSmartparse(formData)
  }

  // 监听上传成功
  changeUploadSuccess = (params) => {
    const config = {
      pageSourch: 'supplyFuturesSetting',
      sheetSourch: importConfig.supplyFuturesSetting.sheetSourch
    }

    this.props.history.push({ pathname: '/matchs/sheetsConfirm', state: { config: config, data: { importId: params } } })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      rowDetail: {},
      uploadSheetDetails: {},
      logModal: false,
      productModal: false,
      uploadSheetModal: false
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCloudFuturesProductList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCloudFuturesProductList()
    })
  }

  // 倒计时
  successCountDown = (operation, successNum = 0, failNum = 0, onOkCallBack) => {
    const modeltype = successNum > 0 ? 'success' : 'error'
    let secondsToGo = 3
    const modal = Modal[modeltype]({
      title: <>{ operation === 'DELETE' ? `删除${successNum > 0 ? '成功' : '失败'}` : `下架${successNum > 0 ? '成功' : '失败'}` }</>,
      content: <>
        <span>已{operation === 'DELETE' ? '删除' : '下架'}{successNum}个型号</span>
        { operation === 'DELETE' && failNum > 0 ? <span style={{ marginLeft: '10px', color: '#FF4B4A' }}>{failNum}个型号已上架，请下架后重新删除</span> : null }
      </>,
      okButtonProps: { disabled: secondsToGo > 0 },
      onOk: () => { onOkCallBack() }
    })

    const timer = setInterval(() => {
      secondsToGo -= 1
      modal.update({
        okButtonProps: { disabled: secondsToGo > 0 }
      })
    }, 1000)

    setTimeout(() => {
      clearInterval(timer)
    }, secondsToGo * 1000)
  }

  render () {
    const { searchData, tableData, pages, logModal = false, productModal = false, rowDetail, selectedRowKeys = [], uploadSheetModal = false, uploadSheetDetails = {} } = this.state

    const components = {
      body: {
        cell: EditableCell
      }
    }

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: 'number',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      }
    })

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const rowSelection = {
      columnWidth: 40,
      selectedRowKeys,
      onChange: this.onSelectChange
    }

    const dropDownMenus = (
      <Menu>
        <Menu.Item key='SOLD_OUT' onClick={() => this.handleSetSoldOut(selectedRowKeys)}><Icon type="down-square" />批量下架</Menu.Item>
        <Menu.Item key='DELETE' onClick={() => this.handleDeleteProducts(selectedRowKeys)}><Icon type='delete' />批量删除</Menu.Item>
      </Menu>
    )

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <div style={{ display: 'inline-block', width: '120px', lineHeight: '40px', verticalAlign: 'top' }}>
            <BatchSearchMenu
              onConfirm={(values) => this.handleSearch(values)}
            />
          </div>

          <div style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </div>
        </div>

        <Row gutter={24} style={{ marginBottom: '15px' }}>
          <Col span={12}>
            <Dropdown overlay={dropDownMenus} disabled={selectedRowKeys.length === 0}>
              <Button type='primary'><Icon type="select" />批量操作</Button>
            </Dropdown>

            <Upload name='file' showUploadList={false}
              headers={{ Token: getStore('token') }}
              accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              action={uploadMatchExcelUrl}
              onChange={this.onChangeUploadFile}
            >
              <Button type='primary' style={{ marginLeft: '10px' }}><Icon type="upload" />导入价格</Button>
            </Upload>

            <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => this.handleDownload()}><Icon type="download" />下载模板</Button>

            <Button type='primary' onClick={() => this.handleShowModal('productModal', {})} style={{ marginLeft: '10px' }}><Icon type="plus" />添加产品</Button>
          </Col>

          <Col span={12} style={{ textAlign: 'right' }}>
            <span style={{ color: '#FF4B4A' }}>注：请尽快更新产品价格或库存，超过120个小时未更新的产品数据将不会显示在云仓中</span><Tag color='#D4DCE4' style={{ marginLeft: '12px' }}>无效产品</Tag>
          </Col>
        </Row>

        <EditableContext.Provider value={this.props.form}>
          <Table rowKey='commoditySkuId'
            components={components}
            columns={columns}
            dataSource={tableData}
            pagination={pagination}
            rowSelection={rowSelection}
            rowClassName={(record) => { return record.outDateLevel === 'LAST_LEVEL' ? 'darkBackground' : null }}
            scroll={{ x: 2000 }}
          />
        </EditableContext.Provider>

        {
          logModal
            ? <SoldInLogModal
              skuType={SKUTYPE}
              detailInfo={rowDetail}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          productModal
            ? <ProductModal
              skuType={SKUTYPE}
              onConfirm={() => this.getCloudFuturesProductList()}
              onCancel={() => this.closeModal()} />
            : null
        }

        {
          uploadSheetModal
            ? <UploadSheetModal
              name={uploadSheetDetails.name}
              list={uploadSheetDetails.list}
              file={uploadSheetDetails.file}
              onConfirm={(values) => this.handleConfirmUploadSheet(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const EditableFormTable = Form.create()(SupplyFuturesProducts)

export default connect()(EditableFormTable)
