
export const tableColumnsMaps = {
  PRODUCT_CLOUD_JUST_SUPPLY: [ // 现货云供应价格导入
    { key: 'indexNumber' },
    { key: 'brandName' },
    { key: 'matchNo' },
    { key: 'matchResult' },
    { key: 'failReason' },
    { key: 'importGuestPrice' },
    { key: 'model' },
    { key: 'itemNumber' },
    { key: 'name' }
  ],
  PRODUCT_CLOUD_FUTURES_JUST_SUPPLY: [ // 期货云供应价格导入
    { key: 'indexNumber' },
    { key: 'brandName' },
    { key: 'matchNo' },
    { key: 'matchResult' },
    { key: 'failReason' },
    { key: 'importGuestPrice' },
    { key: 'deliveryPeriodDays' },
    { key: 'prepaymentRatio' },
    { key: 'model' },
    { key: 'itemNumber' },
    { key: 'name' }
  ],
  PRODUCT_STOCK_JUST_SUPPLY: [ // 现货入库导入
    { key: 'indexNumber' },
    { key: 'brandName' },
    { key: 'matchNo' },
    { key: 'matchResult' },
    { key: 'failReason' },
    { key: 'totalStock' },
    { key: 'model' },
    { key: 'itemNumber' },
    { key: 'name' }
  ]
}
