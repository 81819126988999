import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Row, Card, Tabs, Icon, Button, Table, InputNumber, Badge, Input } from 'antd'
import $lodash from 'lodash'

import MatchExplain from './matchExplain/index'

import Api from '@/common/api/index'
import { setShowLoading } from '@/reducers/action'
import { tableColumnsMaps } from './localdatas'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'

const { TabPane } = Tabs
const tabMaps = [
  { id: 'ALL', name: '所有型号', color: '' },
  { id: 'SUCCESS', name: '匹配成功', color: '#52c41a' },
  { id: 'FAIL', name: '需要修正', color: '#f5222d' }
]
const { pageSizeOptions } = filter
const { SERIALWIDTH } = consts

// 可编辑单元格
const EditableContext = React.createContext()

class EditableCell extends React.Component {
  static propTypes = {
    form: PropTypes.object,
    editing: PropTypes.bool,
    dataIndex: PropTypes.string,
    title: PropTypes.string,
    inputType: PropTypes.string,
    record: PropTypes.object,
    index: PropTypes.number,
    children: PropTypes.array
  }

  renderCell = ({ getFieldDecorator }) => {
    const { editing, dataIndex, title, inputType, record, index, children, ...restProps } = this.props
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input autoComplete='off' />

    return (
      <td {...restProps}>
        {editing
          ? (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(dataIndex, {
                rules: [
                  { required: true, message: `请输入${title}!` }
                ],
                initialValue: record[dataIndex]
              })(inputNode)}
            </Form.Item>
            )
          : (
              children
            )}
      </td>
    )
  }

  render () {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
  }
}

class MatchDetail extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object,
    dispatch: PropTypes.func
  }

  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    activeTabKey: 'ALL',
    detailInfo: {},
    editingKey: '',
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'indexNumber',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'brandName',
      width: 120,
      dataIndex: 'brandName',
      ellipsis: true
    },
    {
      title: '匹配编号',
      key: 'matchNo',
      width: 140,
      dataIndex: 'matchNo',
      ellipsis: true
    },
    {
      title: '状态',
      key: 'matchResult',
      width: 100,
      dataIndex: 'matchResult',
      render: (text, record, index) => (
        <>
          {
            record.matchResult === 'SUCCESS' ? <Badge status='success' text='成功' /> : <Badge status='error' text='失败' />
          }
        </>
      )
    },
    {
      title: '原因',
      key: 'failReason',
      dataIndex: 'failReason',
      render: (text, record, index) => (
        <div style={{ color: record.matchResult === 'SUCCESS' ? 'rgb(82, 196, 26)' : 'rgb(245, 34, 45)' }}>
          {record.failReason}
        </div>
      )
    },
    {
      title: '库存总数',
      key: 'totalStock',
      width: 120,
      dataIndex: 'importTotalStock',
      editable: true
    },
    {
      title: '供应价格',
      key: 'importGuestPrice',
      width: 120,
      dataIndex: 'importGuestPrice',
      editable: true
    },
    {
      title: '货期',
      key: 'deliveryPeriodDays',
      dataIndex: 'importDeliveryPeriodDays',
      editable: true,
      render: (text, record, index) => (
        <>
          {
          record.importDeliveryTime && record.importDeliveryTime !== ''
            ? <>{+record.importDeliveryTime }工作日</>
            : null
          }
        </>
      )
    },
    {
      title: '预付比例',
      key: 'prepaymentRatio',
      dataIndex: 'importPrepayment',
      editable: true,
      render: (text, record, index) => (
        <>
          {
            record.importPrepayment && record.importPrepayment !== ''
              ? <>{(+record.importPrepayment * 100 / 100).toFixed(2)}%</>
              : null
          }
        </>
      )
    },
    {
      title: '型号',
      key: 'model',
      ellipsis: true,
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'itemNumber',
      ellipsis: true,
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'name',
      ellipsis: true,
      dataIndex: 'name'
    }
  ]

  componentDidMount () {
    this.props.dispatch(setShowLoading({ loading: false }))
    const { state = {} } = this.props.history.location

    this.setState({
      activeTabKey: 'ALL',
      detailInfo: state.detailInfo,
      config: state.config
    }, () => {
      this.getUploadPriceMatchResult()
    })
  }

  // 保存导入价格设置
  saveImportPriceSetting = (params) => {
    Api.saveImportPriceSetting(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        setTimeout(() => {
          this.props.dispatch(setShowLoading({ loading: false }))
          const { detailInfo, config = {} } = this.state
          this.props.history.push({ pathname: '/matchs/settingResult', state: { importId: detailInfo.importId, config: config } })
        }, 3000)
      } else {
        this.props.dispatch(setShowLoading({ loading: false }))
      }
    })
  }

  // 点击保存
  handleSaveImportSetting = () => {
    const { detailInfo } = this.state
    this.props.dispatch(setShowLoading({ loading: true }))
    this.saveImportPriceSetting({ importId: detailInfo.importId })
  }

  // 获取导入结果详细
  getUploadPriceMatchResult = () => {
    const { detailInfo, activeTabKey = 'ALL', pages } = this.state

    const params = {
      importId: detailInfo.importId,
      result: activeTabKey,
      matchType: 'MATCH',
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getUploadPriceMatchResult(params).then(res => {
      const { code, data = [] } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 点击切换tab
  handleChangeTabPane = (key) => {
    const { pages } = this.state
    this.setState({
      activeTabKey: key,
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20,
        total: 0
      }
    }, () => {
      this.getUploadPriceMatchResult()
    })
  }

  // 点击编辑
  handleEdit = (record) => {
    this.setState({
      editingKey: record.rowId
    })
  }

  // 编辑中
  isEditing = (record) => record.rowId === this.state.editingKey

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getUploadPriceMatchResult()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getUploadPriceMatchResult()
    })
  }

  render () {
    const { pages, config = {}, detailInfo = {}, tableData = [] } = this.state
    const _columns = config.sheetSourch ? $lodash.intersectionBy(this.columns, tableColumnsMaps[config.sheetSourch], 'key') : this.columns

    const components = {
      body: {
        cell: EditableCell
      }
    }

    const columns = _columns.map(col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: 'number',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      }
    })

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bordered={false}>
          <MatchExplain totalNum={+detailInfo.totalNum} successNum={+detailInfo.successNum} failNum={+detailInfo.failNum} />

          <div>
            <Row>
              <Tabs onChange={this.handleChangeTabPane} tabBarExtraContent={<div>
                <Button type='primary' onClick={() => this.handleSaveImportSetting()}>
                  下一步<Icon type='arrow-right' />
                </Button>
              </div>}>
                {
                  tabMaps.map((titem, n) => (
                    <TabPane tab={titem.name} key={titem.id} />
                  ))
                }
              </Tabs>
            </Row>

            <EditableContext.Provider value={this.props.form}>
              <Table rowKey='rowId' bordered
                components={components}
                columns={columns}
                dataSource={tableData}
                pagination={pagination}
                scroll={{ x: 1400 }}
              />
            </EditableContext.Provider>
          </div>
        </Card>
      </>
    )
  }
}

const EditableFormTable = Form.create()(MatchDetail)

export default connect()(EditableFormTable)
