import React, { Component } from 'react'
import styles from './index.module.scss'
import Info from './../../assets//json/homeinfo.json'

export default class index extends Component {
  state={

  }

  componentDidMount () {

  }

  render () {
    const n = Info.length
    const i = parseInt(Math.random() * n)
    return (
      <div className={`${styles.homeWrapper}`}>
        <div className={`${styles.containerWrap}`}>
          <div className={`${styles.welcomeWrap}`}>
            <h3>IIASaaS&nbsp;&nbsp;供应管理平台</h3>
            <p>{Info[i].text}</p>
          </div>
        </div>
      </div>
    )
  }
}
