import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, Cascader, Switch, Row, message } from 'antd'
import $lodash from 'lodash'

import DiscernAddress from '../discernAddress/index'
import { getCityName, addressToTree } from '@/common/utils/mUtils'

import styles from './index.module.scss'

const FormItem = Form.Item
const { TextArea } = Input
const cityOptions = addressToTree()

class AddressForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number, // 1-新增 2-修改
    endUserId: PropTypes.number,
    detailInfo: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }

  state = {
    detailInfo: this.props.detailInfo
  }

  componentDidMount () {

  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { endUserId = 0, type, detailInfo = {} } = this.props
        const { receiver, phoneNumber, citys, address, isDefault } = values

        if ($lodash.trim(receiver).length === 0) { return message.warning('请输入收货人名称') }

        if ($lodash.trim(address).length === 0) { return message.warning('请输入详细地址') }

        if (+citys[1] === +citys[2]) { return message.warning('请选择行政区！') }

        const names = getCityName(citys[0], citys[1], citys[2])

        const params = {
          receiver: $lodash.trim(receiver),
          phoneNumber: $lodash.trim(phoneNumber),
          province: citys[0],
          city: citys[1],
          county: citys[2],
          address: $lodash.trim(address),
          endUserId: endUserId,
          category: isDefault ? 2 : 0,
          addressRegion: names.join('-')
        }

        if (type === 2) {
          params.id = detailInfo.id
        }

        this.props.onConfirm(params)
      }
    })
  }

  handleCancel = () => {
    this.props.onCancel()
  }

  // 渲染默认地址
  renderCascaderDefaultValue = () => {
    const { detailInfo = {} } = this.state
    const options = []

    if (+detailInfo.province > 0) {
      options.push(`${detailInfo.province}`, `${detailInfo.city}`, `${detailInfo.county}`)
    }

    return options
  }

  // 智能地址设置显示
  setIntelAddress = (params) => {
    params.id = this.props.detailInfo.id
    params.category = this.props.detailInfo.category
    this.setState({
      detailInfo: params,
      intelModal: false
    })
  }

  render () {
    const { type = 1 } = this.props
    const { getFieldDecorator } = this.props.form
    const { detailInfo = {} } = this.state

    return (
      <>
        <Modal visible width={560} title={+type === 1 ? '新增地址' : '修改地址'}
          bodyStyle={{ padding: '5px 40px' }}
          maskClosable={false}
          onOk={() => this.handleConfirm()}
          onCancel={() => this.handleCancel()}
        >
          <Row>
            <DiscernAddress onConfirm={(params) => this.setIntelAddress(params)} />
          </Row>

          <Form className={styles.from}>
            <FormItem label='收货人姓名' colon={false}>
              {
                getFieldDecorator('receiver', {
                  initialValue: detailInfo.receiver || '',
                  rules: [
                    { required: true, message: '请您填写收货人姓名' }
                  ]
                })(
                  <Input placeholder='请输入收货人姓名' autoComplete='off' />
                )
              }
            </FormItem>
            <FormItem label='联系方式' colon={false}>
              {
                getFieldDecorator('phoneNumber', {
                  initialValue: detailInfo.phoneNumber || '',
                  validate: [
                    {
                      trigger: 'onChange',
                      rules: [{ required: true, message: '请填写收货人联系方式' }]
                    }
                  ]
                })(
                  <Input placeholder='请输入收货人联系方式' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem label='收货地区' colon={false}>
              {
                getFieldDecorator('citys', {
                  rules: [
                    { required: true, message: '请选择省、市、区' }
                  ],
                  initialValue: this.renderCascaderDefaultValue()
                })(
                  <Cascader placeholder='请选择省市区'
                    fieldNames={{ value: 'code', label: 'name', children: 'childList' }}
                    options={cityOptions}
                  />
                )
              }
            </FormItem>

            <FormItem label='详细地址' colon={false}>
              {
                getFieldDecorator('address', {
                  rules: [
                    { required: true, message: '请填写详细地址' }
                  ],
                  initialValue: detailInfo.address || ''
                })(
                  <TextArea rows={4} placeholder='请输入详细地址' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem style={{ marginBottom: '0px' }}>
              <FormItem style={{ display: 'inline-block', width: 'calc(100% - 200px)' }}><label style={{ color: '#333333' }}>设置为默认地址</label></FormItem>
              <FormItem style={{ display: 'inline-block', width: '200px', textAlign: 'right' }}>
                {
                  getFieldDecorator('isDefault', {
                    initialValue: +detailInfo.category === 2,
                    valuePropName: 'checked'
                  })(
                    <Switch checkedChildren='是' unCheckedChildren='否' />
                  )
                }
              </FormItem>
            </FormItem>
          </Form>
        </Modal>
      </>
    )
  }
}

const AddressModal = Form.create()(AddressForm)

export default AddressModal
