export const sheetSourchMaps = [
  {
    type: 'PRODUCT_STOCK_JUST_SUPPLY',
    sourch: [
      { name: '品牌', type: 'brandNameColNum', data: {}, selectedPartIndex: 0 },
      { name: '匹配编号', type: 'matchNoColNum', data: {}, selectedPartIndex: 1 },
      { name: '总库存', type: 'totalStockColNum', data: {}, selectedPartIndex: 2 }
    ]
  },
  {
    type: 'PRODUCT_CLOUD_JUST_SUPPLY', // 现货云供应价格
    sourch: [
      { name: '品牌', type: 'brandNameColNum', data: {}, selectedPartIndex: 0 },
      { name: '匹配编号', type: 'matchNoColNum', data: {}, selectedPartIndex: 1 },
      { name: '供应价格', type: 'guestPriceColNum', data: {}, selectedPartIndex: 2 }
    ]
  },
  {
    type: 'PRODUCT_CLOUD_FUTURES_JUST_SUPPLY', // 期货云供应导入设置
    sourch: [
      { name: '品牌', type: 'brandNameColNum', data: {}, selectedPartIndex: 0 },
      { name: '匹配编号', type: 'matchNoColNum', data: {}, selectedPartIndex: 1 },
      { name: '价格', type: 'guestPriceColNum', data: {}, selectedPartIndex: 2 },
      { name: '货期', type: 'dekiveryTimeColNum', data: {}, selectedPartIndex: 3 },
      { name: '预付比例', type: 'prepaymentColNum', data: {}, selectedPartIndex: 4 }
    ]
  }
]
