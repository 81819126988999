import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Checkbox, Row, Col, message } from 'antd'
import $lodash from 'lodash'

import Api from '@/common/api/index'

export default class DowncolModal extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }

  state = {
    productOptions: [], // 产品字段集合
    productSelectedKeys: [], // 产品选择字段
    productCheckedAll: false,
    productIndeterminate: true,

    orderOptions: [], // 订单字段集合
    orderSelectedKeys: [], // 订单选择字段
    orderCheckedAll: false,
    orderIndeterminate: true
  }

  componentDidMount () {
    this.getOrderDownloadProductFieldOptions()
    this.getOrderDownloadOrderFieldOptions()
  }

  // 获取产品字段项
  getOrderDownloadProductFieldOptions = async () => {
    const res = await Api.getOrderDownloadProductFieldOptions({})
    const { code, data = {} } = res
    if (+code === 10000) {
      this.setState({
        productOptions: data ? this.setOptions(data) : []
      }, () => {
        if (this.state.productOptions.length > 0) {
          this.handleProductCheckedAll(true)
        }
      })
    }
  }

  // 获取订单导出自定义项-订单字段项
  getOrderDownloadOrderFieldOptions = async () => {
    const res = await Api.getOrderDownloadOrderFieldOptions({})
    const { code, data = {} } = res
    if (+code === 10000) {
      this.setState({
        orderOptions: data ? this.setOptions(data) : []
      }, () => {
        if (this.state.orderOptions.length > 0) {
          this.handleOrderCheckedAll(true)
        }
      })
    }
  }

  // 点击确定
  handleConfirm = () => {
    const { productSelectedKeys = [], orderSelectedKeys = [] } = this.state
    if (productSelectedKeys.length === 0 && orderSelectedKeys.length === 0) {
      return message.warning('请选择要导出的字段')
    }

    const fields = [...productSelectedKeys, ...orderSelectedKeys]

    this.props.onConfirm(fields)
  }

  // 选择产品导出项
  handleChangeCheckedProduct = (values) => {
    const { productOptions } = this.state
    this.setState({
      productsSelectedKeys: values,
      productIndeterminate: !!values.length && values.length < productOptions.length
    })
  }

  // 选择订单导出项
  handleChangeCheckedOrder = (values) => {
    const { orderOptions } = this.state
    this.setState({
      orderSelectedKeys: values,
      orderIndeterminate: !!values.length && values.length < orderOptions.length
    })
  }

  // 点击产品信息全选
  handleProductCheckedAll = (checked) => {
    const { productOptions = [] } = this.state
    this.setState({
      productSelectedKeys: checked ? $lodash.map(productOptions, (o) => { return o.id }) : [],
      productCheckedAll: checked,
      productIndeterminate: false
    })
  }

  // 点击订单信息全选
  handleOrderCheckedAll = (checked) => {
    const { orderOptions = [] } = this.state
    this.setState({
      orderSelectedKeys: checked ? $lodash.map(orderOptions, (o) => { return o.id }) : [],
      orderCheckedAll: checked,
      orderIndeterminate: false
    })
  }

  setOptions = (data = []) => {
    const newdatas = []

    $lodash.forEach(data, (values, key) => {
      newdatas.push({ id: key, name: values })
    })

    return newdatas
  }

  render () {
    const {
      productOptions = [], orderOptions = [], productSelectedKeys = [], orderSelectedKeys = [],
      productCheckedAll = true, orderCheckedAll = true, productIndeterminate = true, orderIndeterminate = true
    } = this.state

    return (
      <Modal title='自定义导出项'
        visible
        maskClosable={false}
        onCancel={this.props.onCancel}
        onOk={this.handleConfirm}
      >
        <Row gutter={24}>
          <Col span={12}>
            <div>
              <Checkbox checked={productCheckedAll}
                indeterminate={productIndeterminate}
                onChange={(e) => this.handleProductCheckedAll(e.target.checked)}
              >产品信息</Checkbox>
            </div>
            <Checkbox.Group value={productSelectedKeys} onChange={(values) => this.handleChangeCheckedProduct(values)}>
              {
                productOptions.map((ptem, i) => (
                  <div key={`productOpt-${i}`} style={{ marginLeft: '16px' }}>
                    <Checkbox value={ptem.id}>{ ptem.name }</Checkbox>
                  </div>
                ))
              }
            </Checkbox.Group>
          </Col>
          <Col span={12}>
            <div>
              <Checkbox checked={orderCheckedAll}
                indeterminate={orderIndeterminate}
                onChange={(e) => this.handleOrderCheckedAll(e.target.checked)}>订单信息</Checkbox>
            </div>

            <Checkbox.Group value={orderSelectedKeys} onChange={(values) => this.handleChangeCheckedOrder(values)}>
              {
                orderOptions.map((otem, n) => (
                  <div key={`orderOpt-${n}`} style={{ marginLeft: '16px' }}>
                    <Checkbox value={otem.id}>{ otem.name }</Checkbox>
                  </div>
                ))
              }
            </Checkbox.Group>
          </Col>
        </Row>
      </Modal>
    )
  }
}
