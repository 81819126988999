import Ajax from '../utils/ajax'

export default {
  // 修改密码
  UpdatePassword (params) {
    return Ajax.post('/api/home/page/update/account/passwordByPhone', params)
  },

  // 获取商户基本信息
  getMerchantBaseInfo (params) {
    return Ajax.get('/api/mall/manage/get/platformMerchantInfo', { params })
  },

  // 获取商城配置信息
  getMallConfig (params) {
    return Ajax.get('/api/mall/manage/get/mallInfoConfig', { params })
  },

  // 保存合同章
  saveMallConfig (params) {
    return Ajax.post('/api/mall/manage/save/mallInfoConfig', params)
  }
}
