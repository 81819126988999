
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_MENU = 'SET_MENU'
export const SET_CALLBACK_QUERY = 'SET_CALLBACK_QUERY'
export const SET_HANDLE_MODAL = 'SET_HANDLE_MODAL'
export const SET_COUNT_DOWN = 'SET_COUNT_DOWN'
export const SET_UNNOTICE_COUNT = 'SET_UNNOTICE_COUNT'
export const SET_NOTICE_DETAIL = 'SET_NOTICE_DETAIL'
export const SET_BATCHSKU_LIST = 'SET_BATCHSKU_LIST'
export const SET_SHOW_LOADING = 'SET_SHOW_LOADING'

// 设置用户信息
export function setUserInfo (data) {
  return { type: SET_USER_INFO, data }
}

// 设置菜单
export function setMenus (data) {
  return { type: SET_MENU, data }
}

// 设置上传设置成功 返回原页面参数
export function setCallBackQuery (data) {
  return { type: SET_CALLBACK_QUERY, data }
}

// 设置handleModal
export function setHandleModal (data) {
  return { type: SET_HANDLE_MODAL, data }
}

// 倒计时
export function setCountDown (data) {
  return { type: SET_COUNT_DOWN, data }
}

// 设置未读消息数
export function setUnnoticeCount (data) {
  return { type: SET_UNNOTICE_COUNT, data }
}

// 设置消息详情信息
export function setNoticeDetail (data) {
  return { type: SET_NOTICE_DETAIL, data }
}

// 设置快速下单商品数据
export function setOrderBatchSkuList (data) {
  return { type: SET_BATCHSKU_LIST, data }
}

// 设置全局loading
export function setShowLoading (data) {
  return { type: SET_SHOW_LOADING, data }
}
