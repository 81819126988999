import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Button, Table, Icon } from 'antd'
import moment from 'moment'

import DetailModal from './detailModal'
import CourierJson from '@/assets/json/courier.json'
import consts from '@/common/utils/consts'
import filter from '@/common/utils/filter'

const { SERIALWIDTH } = consts
const { pageSizeOptions } = filter

export default class BilldetailCard extends Component {
  static propTypes = {
    pages: PropTypes.object,
    tableData: PropTypes.array,
    onDownload: PropTypes.func,
    pageChange: PropTypes.func,
    pageSizeChange: PropTypes.func
  }

  state = {
    rowDetail: {},
    detailModal: false
  }

  columns = [
    {
      title: '序号',
      key: 'detailList-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '订单编号',
      key: 'detailList-1',
      dataIndex: 'orderId'
    },
    {
      title: '发货日期',
      key: 'detailList-2',
      width: 150,
      render: (text, record, index) => (
        <div>
          { record.senderTime ? moment(record.senderTime).format('YYYY-MM-DD HH:mm:ss') : null }
        </div>
      )
    },
    {
      title: '收货日期',
      key: 'detailList-3',
      width: 150,
      render: (text, record, index) => (
        <div>
          { record.signedStatus === 'SIGNED' && record.receiverTime ? moment(record.receiverTime).format('YYYY-MM-DD HH:mm:ss') : null }
        </div>
      )
    },
    {
      title: '运单号',
      key: 'detailList-4',
      dataIndex: 'mailNoList',
      render: (text, record, index) => (
        <div>{ record.mailNoList && record.mailNoList.length > 0 ? record.mailNoList.join(',') : '----'}</div>
      )
    },
    {
      title: '发货地址',
      key: 'detailList-5',
      ellipsis: true,
      width: 160,
      render: (text, record, index) => (
        <span>{record.senderProvince}&nbsp;{record.senderCity}&nbsp;{record.senderCounty}&nbsp;{record.senderAddress}</span>
      )
    },
    {
      title: '收货地址',
      key: 'detailList-6',
      ellipsis: true,
      width: 150,
      render: (text, record, index) => (
        <span>
          {record.receiverProvince}&nbsp;{record.receiverCity}&nbsp;{record.receiverCounty}&nbsp;{record.receiverAddress}
        </span>
      )
    },
    {
      title: '快递公司',
      key: 'detailList-7',
      render: (text, record, index) => (
        <div>{ this.renderCourierName(record.courierCode) }</div>
      )
    },
    {
      title: '发货人',
      key: 'detailList-8',
      dataIndex: 'senderName'
    },
    {
      title: '收货人',
      key: 'detailList-9',
      dataIndex: 'receiverName'
    },
    {
      title: '货物名称',
      key: 'detailList-10',
      width: 80,
      render: (text, record, index) => (
        <a onClick={() => this.showDetailModal(record)}>工业品</a>
      )
    },
    {
      title: '件数',
      key: 'detailList-11',
      dataIndex: 'totalNumber'
    },
    {
      title: '重量(kg)',
      key: 'detailList-12',
      dataIndex: 'totalWeight'
    },
    {
      title: <span>体积m<sup>3</sup></span>,
      key: 'detailList-13',
      dataIndex: 'totalVolume'
    },
    {
      title: '运费金额',

      key: 'detailList-14',
      render: (text, record, index) => (
        <div>&yen;{((record.totalPrice * 100 - record.transportPrice * 100 + record.merchantTransportPrice * 100) / 100).toFixed(2)}</div>
      )
    }
  ]

  // 点击查看详情
  showDetailModal = (record) => {
    this.setState({
      detailModal: true,
      rowDetail: record
    })
  }

  closeModal = () => {
    this.setState({
      detailModal: false,
      rowDetail: {}
    })
  }

  // 渲染快递公司名称
  renderCourierName = (code) => {
    const obj = CourierJson.find((item) => { return item.courierCode === code })
    const label = obj !== undefined ? obj.courierName : '----'

    return label
  }

  // 翻页
  pageChange = (page, pageSize) => {
    this.props.pageChange(page, pageSize)
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    this.props.pageSizeChange(current, size)
  }

  render () {
    const { pages, tableData = [] } = this.props
    const { detailModal = false, rowDetail = {} } = this.state
    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card title='账单明细' bodyStyle={{ padding: '0px' }} bordered={false}
          extra={<Button type='primary' onClick={() => this.props.onDownload()}><Icon type='download' />月度明细</Button>}
        >
          <Table rowKey={(record, index) => { return `billDetailFlow-${record.orderId}-${index}` }}
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
            scroll={{ x: 1300 }}
          />
        </Card>

        {
          detailModal
            ? <DetailModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}
