import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import { Dropdown, Button, Icon, Menu } from 'antd'

import Api from '@/common/api/index'
import { removeStore } from '@/common/utils/mUtils'
import LOGO_PRIMARY from '@/assets/images/header_logo.png'

import styles from './index.module.scss'

class HeaderLand extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    userInfo: {}
  }

  componentDidMount () {
    this.getLoginUserInfo()
  }

  // 获取登录用户个人信息
  getLoginUserInfo = async () => {
    const res = await Api.getLoginUserInfo({})
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        userInfo: data || {}
      })
    }
  }

  // 退出
  handleLogout = () => {
    removeStore('token')
    this.setState({
      userInfo: {}
    }, () => {
      this.props.history.push({ pathname: '/login' })
    })
  }

  // 首页
  getHome = () => {
    this.props.history.push('/home')
  }

  dropdownMenu = () => {
    return (
      <Menu>
        <Menu.Item key='user'>
          <Link to='/account/personal'><Icon type="user" /><span style={{ marginLeft: '8px' }}>个人中心</span></Link>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.handleLogout()}><Icon type="logout" /><span style={{ marginLeft: '8px' }}>退出登录</span></a>
        </Menu.Item>
      </Menu>
    )
  }

  render () {
    const { userInfo = {} } = this.state

    return (
      <>
        <div className={`${styles.globalHeader}`}>
          <img className={`${styles.logo}`} width={180} src={LOGO_PRIMARY} />

          <div style={{ flex: '1 1 0%', height: '100%' }} />

          <div className={`${styles.spaceContent}`}>
            <Dropdown overlay={this.dropdownMenu()}>
              <Button type='link'>{userInfo.username}<Icon type="down" /></Button>
            </Dropdown>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(HeaderLand)
