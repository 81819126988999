import accountApi from './accountApi'
import customerApi from './customerApi'
import financialApi from './financialApi'
import cloudSupplyApi from './cloudSupplyApi'
import warehouseApi from './warehouseApi'
import loginApi from './loginApi'
import commonApi from './commonApi'
import productApi from './productApi'
import matchsApi from './matchsApi'
import orderApi from './orderApi'
import homeApi from './homeApi'
import invoiceApi from './invoiceApi'
import saledApi from './saledApi'

export default {
  ...accountApi,
  ...customerApi,
  ...financialApi,
  ...cloudSupplyApi,
  ...warehouseApi,
  ...loginApi,
  ...commonApi,
  ...productApi,
  ...matchsApi,
  ...orderApi,
  ...homeApi,
  ...invoiceApi,
  ...saledApi
}
