import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Descriptions, Table, Row, Col, Radio, Select, Input, InputNumber, Icon, Button, message, DatePicker } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import UploadThumbnail from '@/components/uploadThumbnail'
import AddressModal from '@/pages/warehouse/sendAddress/sendAddressModal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { getCourierName, renderAddressLabel, renderSaledTypeLabel, renderSaledReasonTypeLabel, renderSaledRefundTypeLabel } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'
import CourierJson from '@/assets/json/courier.json'
// import SaasCourierJson from '@/assets/json/saasCourier.json'
import styles from './index.module.scss'

const FormItem = Form.Item
const Option = Select.Option
const { TextArea } = Input
const { RangePicker } = DatePicker

const { saasCouierCodeMaps, expressTypeMaps, expressReceiptFlags, expressReceiptReturnRequirements, expressPackWoodenFrames } = filter
const { SERIALWIDTH } = consts

let formitemId = 1

export default class DrawerContents extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onSelectSendAddress: PropTypes.func
  }

  state = {
    info: {},
    tableData: [],
    imageUrls: [],
    sendAddressList: [],
    addressModal: false
  }

  columns = [
    {
      title: '序号',
      key: 'supplyProductCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '型号',
      key: 'supplyProductCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'supplyProductCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'supplyProductCol-4',
      dataIndex: 'name'
    },
    {
      title: '单位',
      key: 'supplyProductCol-5',
      dataIndex: 'unit'
    },
    {
      title: '数量',
      key: 'supplyProductCol-6',
      dataIndex: 'quantity'
    },
    {
      title: '含税单价',
      key: 'supplyProductCol-7',
      dataIndex: 'sellingPrice',
      render: (text, record, index) => (
        <>&yen;{+record.sellingPrice}</>
      )
    },
    {
      title: '含税单品小计',
      key: 'supplyProductCol-8',
      dataIndex: '_totalPrice',
      render: (text, record, index) => (
        <>&yen;{ ($lodash.round(+record.quantity * +record.sellingPrice, 2) * 100 / 100).toFixed(2) }</>
      )
    }
  ]

  componentDidMount () {
    const { detailInfo = {} } = this.props
    this.getSupplyOrderSaledDetails()

    if (['CHANGE_PRODUCTS', 'ADD_DELIVER'].includes(detailInfo.type) && detailInfo.status === 'WAITING_AUDIT') {
      this.getSendAddressList()
    }
  }

  // 获取发货地址列表
  getSendAddressList = async () => {
    const res = await Api.getSendAddressList({})
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        sendAddressList: data || []
      })
    }
  }

  // 新增发货地址
  saveSendAddress = async (params) => {
    const res = await Api.saveSendAddress(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getSendAddressList()
    }
  }

  // 获取云供应订单售后详情
  getSupplyOrderSaledDetails = async () => {
    const { detailInfo = {} } = this.props
    const res = await Api.getSupplyOrderSaledDetails({ id: detailInfo.id })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        info: data || {},
        tableData: data.skuInfoList || [],
        imageUrls: data.goodsImageList || []
      })
    }
  }

  // 监听发货地址选择
  handleChangeSendAddress = (value) => {
    const { sendAddressList = [] } = this.state
    const obj = sendAddressList.find((item) => { return item.id === value })

    this.props.onSelectSendAddress(obj)
  }

  // 点击新增地址
  showAddressModal = () => {
    this.setState({
      addressModal: true
    })
  }

  // 渲染快递方式选项
  renderTransportTypeOptions = (courierCode) => {
    const newArr = expressTypeMaps[courierCode]

    return (
      <Select>
        {
          newArr.map((item) => (
            <Option value={item.id} key={item.id}>{ item.name }</Option>
          ))
        }
      </Select>
    )
  }

  // 渲染SAAS服务内容
  renderSAASServerNodes = () => {
    const { getFieldDecorator, getFieldValue } = this.props.form

    const { sendAddressList = [] } = this.state
    const wayEnums = getFieldValue('logisticsWayEnum').split('-')

    return (
      <>
        <Row className={`${styles.formitemRow}`}>
          <FormItem label='快递公司'>
            <span>{getCourierName(wayEnums[1])}</span>
          </FormItem>
        </Row>

        <Row>
          <Col span={12}>
            <Row className={`${styles.formitemRow}`}>
              <FormItem label='快递方式'>
                {
                  getFieldDecorator('transportType', {
                    rules: [
                      { required: true, message: '请选择快递方式' }
                    ]
                  })(this.renderTransportTypeOptions(getFieldValue('logisticsWayEnum')))
                }
              </FormItem>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Row className={`${styles.formitemRow}`}>
              <FormItem label='包裹数量'>
                {
                  getFieldDecorator('totalNumber', {
                    rules: [
                      { required: true, message: '请填写包裹数量' }
                    ]
                  })(
                    <InputNumber min={1} />
                  )
                }
              </FormItem>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col span={16}>
            <Row className={`${styles.formitemRow}`}>
              <FormItem label='发货地址'>
                {
                  getFieldDecorator('sendAddressId', {

                    rules: [
                      { required: true, message: '请选择快递发货地址' }
                    ]
                  })(
                    <Select onChange={(value) => this.handleChangeSendAddress(value)}>
                      {
                        sendAddressList.map((opt, n) => (
                          <Option value={opt.id} key={`addressOpt-${opt.id}-${n}`}>
                            { renderAddressLabel(opt.province, opt.city, opt.county, opt.address) } {opt.name} {opt.mobile}
                          </Option>
                        ))
                      }
                    </Select>
                  )
                }
              </FormItem>
            </Row>
          </Col>
          <Col span={4}>
            <FormItem>
              <Button type='link' size='small' onClick={() => this.showAddressModal()}>新增地址</Button>
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Row className={`${styles.formitemRow}`}>
              <FormItem label='付费类型'>
                {
                  getFieldDecorator('logisticsPayType', {
                    rules: [
                      { required: true, message: '请选择付费类型' }
                    ]
                  })(
                    <Radio.Group buttonStyle='solid'>
                      <Radio.Button value='MONTHLY_STATEMENT'>包邮</Radio.Button>
                      <Radio.Button value='FREIGHT_COLLECT'>到付</Radio.Button>
                    </Radio.Group>
                  )
                }
              </FormItem>
            </Row>
          </Col>
        </Row>

        {
          ['SAAS-DBL', 'SAAS-KYSY'].includes(getFieldValue('logisticsWayEnum'))
            ? <Row>
              <Col span={12}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='保价金额'>
                    {
                      getFieldDecorator('insuranceValue', {
                        rules: [
                          { required: false, message: '请填写保价金额' }
                        ]
                      })(
                        <InputNumber formatter={value => `￥ ${value}`} style={{ width: '120px' }} />
                      )
                    }
                  </FormItem>
                </div>
              </Col>
            </Row>
            : null
        }

        {
          ['SAAS-DBL', 'SAAS-SF', 'SAAS-JD', 'SAAS-KYSY'].includes(getFieldValue('logisticsWayEnum'))
            ? <Row>
              <Col span={12}>
                <Row className={`${styles.formitemRow}`}>
                  <FormItem label='签单返还'>
                    {
                      getFieldDecorator('receiptType', {
                        initialValue: expressReceiptFlags[getFieldValue('logisticsWayEnum')][0].id,
                        rules: [
                          { required: true, message: '请选择签单返还' }
                        ]
                      })(
                        <Select>
                          {
                            expressReceiptFlags[getFieldValue('logisticsWayEnum')].map((receiOpt, i) => (
                              <Option value={receiOpt.id} key={`JDRECEIPT-${i}`}>{ receiOpt.name }</Option>
                            ))
                          }
                        </Select>
                      )
                    }
                  </FormItem>
                </Row>
              </Col>
            </Row>
            : null
        }

        {
          ['SAAS-DBL'].includes(getFieldValue('logisticsWayEnum')) && ['DEPPON_1', 'DEPPON_2'].includes(getFieldValue('receiptType'))
            ? <Row>
              <Col span={12}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='签收回单返单类型'>
                    {
                      getFieldDecorator('returnRequirement', {
                        rules: [
                          { required: true, message: '请选择签收回单返单类型' }
                        ]
                      })(
                        <Select>
                          {
                            expressReceiptReturnRequirements[getFieldValue('logisticsWayEnum')].map((receiOpt, i) => (
                              <Option value={receiOpt.id} key={`returnRequirement-${i}`}>{ receiOpt.name }</Option>
                            ))
                          }
                        </Select>
                      )
                    }
                  </FormItem>
                </div>
              </Col>
            </Row>
            : null
        }

        { // 木架方式
          ['SAAS-KYSY'].includes(getFieldValue('logisticsWayEnum'))
            ? <Row>
              <Col span={12}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='木架方式'>
                    {
                      getFieldDecorator('woodenFrame', {
                        initialValue: '0',
                        rules: [
                          { required: true, message: '请选择木架方式' }
                        ]
                      })(
                        <Select>
                          {
                            expressPackWoodenFrames[getFieldValue('logisticsWayEnum')].map((woodOpt, i) => (
                              <Option value={woodOpt.id} key={`woodenFrameOpt-${i}`}>{ woodOpt.name }</Option>
                            ))
                          }
                        </Select>
                      )
                    }
                  </FormItem>
                </div>
              </Col>
            </Row>
            : null
        }

        {
          ['SAAS-DBL'].includes(getFieldValue('logisticsWayEnum'))
            ? <Row>
              <Col span={12}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='上门接货期限'>
                    {
                      getFieldDecorator('pickUpTimes', {

                      })(
                        <RangePicker showTime />
                      )
                    }
                  </FormItem>
                </div>
              </Col>
            </Row>
            : null
        }

        {
          ['SAAS-KYSY'].includes(getFieldValue('logisticsWayEnum'))
            ? <Row>
              <Col span={12}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='预计重量'>
                    {
                      getFieldDecorator('actualWeight', {

                      })(
                        <InputNumber style={{ width: '120px' }} />
                      )
                    }
                    <span style={{ marginLeft: '8px' }}>kg</span>
                    <span className={'redColor'} style={{ marginLeft: '8px' }}>此项会影响调配车辆，请认真估计</span>
                  </FormItem>
                </div>
              </Col>
            </Row>
            : null
        }

        {
          ['SAAS-DBL', 'SAAS-SF', 'SAAS-KYSY'].includes(getFieldValue('logisticsWayEnum'))
            ? <Row>
              <Col span={12}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='备注'>
                    {
                      getFieldDecorator('waybillRemark', {

                      })(
                        <TextArea placeholder={`请输入内容，不超过${['SAAS-DBL', 'SAAS-SF'].includes(getFieldValue('logisticsWayEnum')) ? 100 : 200}字...`}
                          rows={4}
                          maxLength={['SAAS-DBL', 'SAAS-SF'].includes(getFieldValue('logisticsWayEnum')) ? 100 : 200}
                        />
                      )
                    }
                  </FormItem>
                </div>
              </Col>
            </Row>
            : null
        }
      </>
    )
  }

  // 点击添加快递单号输入框
  handleAddInput = () => {
    const { form } = this.props
    const keys = form.getFieldValue('keys')
    const nextKeys = keys.concat(formitemId++)

    form.setFieldsValue({
      keys: nextKeys
    })
  }

  // 删除快递单号输入框
  handleDeleteInput = (k) => {
    const { form } = this.props

    const keys = form.getFieldValue('keys')

    if (keys.length === 1) { return false }

    form.setFieldsValue({
      keys: keys.filter(key => key !== k)
    })
  }

  // 渲染自营服务内容
  renderSelfServerFormItem = () => {
    const { getFieldDecorator, getFieldValue } = this.props.form
    getFieldDecorator('keys', { initialValue: [0] })
    const keys = getFieldValue('keys')

    return (
      <>
        <Row>
          <Col span={12}>
            <div className={`${styles.formitemRow}`}>
              <FormItem label='快递公司'>
                {
                  getFieldDecorator('courierCode', {
                    rules: [
                      { required: true, message: '请选择快递公司' }
                    ]
                  })(
                    <Select placeholder='请选择...'
                      showSearch
                      optionFilterProp='children'
                    >
                      {
                        CourierJson.map((item) => (
                          <Option value={item.courierCode} key={item.courierCode}>
                            {item.courierName}
                          </Option>
                        ))
                      }
                    </Select>
                  )
                }
              </FormItem>
            </div>
          </Col>
        </Row>

        {
          keys.map((row, i) => (
            <Row key={`formInput-${i}`}>
              <Col span={12}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='运单号'>
                    {
                      getFieldDecorator(`courierNumberList[${row}]`, {
                        rules: [
                          { required: true, message: '请输入运单号' }
                        ]
                      })(
                        <Input placeholder='请输入运单号' autoComplete='off' />
                      )
                    }
                  </FormItem>
                </div>
              </Col>
              <Col span={4}>
                <FormItem>
                  {
                    i === keys.length - 1
                      ? <a style={{ padding: '2px 3px', margin: '0px 5px' }} onClick={() => this.handleAddInput()}>
                        <Icon type='plus' style={{ fontSize: '20px' }} />
                      </a>
                      : <span style={{ display: 'inline-block', width: '24px', height: '24px', margin: '0px 5px' }} />
                  }

                  <a style={{ padding: '2px 3px' }} onClick={() => this.handleDeleteInput(row)}>
                    <Icon type='delete' style={{ fontSize: '20px' }} />
                  </a>
                </FormItem>
              </Col>
            </Row>
          ))
        }
      </>
    )
  }

  closeModal = () => {
    this.setState({
      addressModal: false
    })
  }

  render () {
    const { form, detailInfo = {} } = this.props
    const { getFieldDecorator } = form
    const { info = {}, tableData = [], imageUrls = [], addressModal = false } = this.state
    const { receiverAddress = {} } = info

    return (
      <>
        <Descriptions>
        <Descriptions.Item label='订单编号'>{ detailInfo.purchaseOrderId }</Descriptions.Item>
          <Descriptions.Item label='订单日期'>{ info.orderDate ? moment(info.orderDate).format('YYYY-MM-DD HH:mm:ss') : null }</Descriptions.Item>
        </Descriptions>

        <Table rowKey={(record, index) => `${record.commoditySkuId}-${record.skuGoodsType}-${index}`} bordered
          columns={this.columns}
          dataSource={tableData}
          pagination={false}
        />

        <div style={{ marginTop: '8px' }}>
        <div className={`${styles.formitemRow}`}>
            <FormItem label='售后类型' style={{ marginBottom: '0px' }}>
              {renderSaledTypeLabel(info.type)}
            </FormItem>
          </div>

          <div className={`${styles.formitemRow}`}>
            <FormItem label='售后原因' style={{ marginBottom: '0px' }}>{ renderSaledReasonTypeLabel(info.reasonType) }</FormItem>
          </div>

          <div className={`${styles.formitemRow}`}>
            <FormItem label='详细描述' style={{ marginBottom: '0px' }}>
              <p style={{ marginBottom: '0px', wordBreak: 'break-all' }}>{ info.backRemark }</p>
            </FormItem>
          </div>

          <FormItem label='可添加图片来帮助售后人员判断(最多9张)' style={{ marginBottom: '0px' }}>
            {
              imageUrls.map((item, index) => (
                <div className={`${styles.pictureWrap}`} key={`purchaseSaledPictureCol-${index}`}>
                  <UploadThumbnail imageUrl={item} />
                </div>
              ))
            }
          </FormItem>

          {
            ['REFUND_RETURN_PRODUCTS', 'REFUND'].includes(info.type)
              ? <>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='退款方式' style={{ marginBottom: '0px' }}>{ renderSaledRefundTypeLabel(info.refundType) }</FormItem>
                </div>

                <div className={`${styles.formitemRow}`}>
                  <FormItem label='预估退款金额' style={{ marginBottom: '0px' }}>&yen;{ ($lodash.round(+info.refundAmount, 2) * 100 / 100).toFixed(2)}</FormItem>
                </div></>
              : null
          }

          {
            ['CHANGE_PRODUCTS', 'ADD_DELIVER'].includes(info.type)
              ? <>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='换货、补寄收货地址' style={{ marginBottom: '0px' }}>
                    {
                      receiverAddress && receiverAddress.province
                        ? <>{ renderAddressLabel(receiverAddress.province, receiverAddress.city, receiverAddress.county, receiverAddress.address) } { receiverAddress.name } { receiverAddress.mobile }</>
                        : null
                    }
                  </FormItem>
                </div>
              </>
              : null
          }

          {
            ['CHANGE_PRODUCTS', 'ADD_DELIVER'].includes(info.type) && detailInfo.status === 'WAITING_AUDIT'
              ? <>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='快递服务'>
                    {
                      getFieldDecorator('logisticsWayEnum', {
                        initialValue: 'SAAS-DBL',
                        rules: [
                          { required: true, message: '请选择快递服务' }
                        ]
                      })(
                        <Radio.Group>
                          {
                            saasCouierCodeMaps.map((item, i) => (
                              <Radio value={item.id} key={item.id}>{ item.courierName }</Radio>
                            ))
                          }
                        </Radio.Group>
                      )
                    }
                  </FormItem>
                </div>

                { this.renderSAASServerNodes() }
              </>
              : null
          }
        </div>

        {
          addressModal
            ? <AddressModal type={1}
                onCancel={() => this.closeModal()}
                onConfirm={(values) => this.saveSendAddress(values) }
            />
            : null
        }
      </>
    )
  }
}
