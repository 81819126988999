import Home from './homeRouter'
import Account from './accountRouter'
import Customer from './customerRouter'
import Financial from './financialRouter'
import Supply from './supplyRouter'
import Warehouse from './warehouseRouter'
import matchsRouter from './matchsRouter'

export const routerMap = [
  ...Home,
  ...Account,
  ...Customer,
  ...Financial,
  ...Supply,
  ...Warehouse,
  ...matchsRouter
]
