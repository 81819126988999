import React, { Component } from 'react'
import { Descriptions, Upload, Icon, message, Button } from 'antd'
import LazyLoad from 'react-lazyload'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'

import { renderAddressLabel, getStore } from '@/common/utils/mUtils'

import styles from './index.module.scss'

const { uploadOrdinaryImageUrl } = UploadApi

export default class AccountInformation extends Component {
  state={
    imageMap: [],
    data: {}, // 基本信息
    info: {} // 合同章
  }

  componentDidMount () {
    this.getMerchantBaseInfo()
    this.getMallConfig()
  }

  // 获取基本信息
  getMerchantBaseInfo = () => {
    Api.getMerchantBaseInfo({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          data: data || {}
        })
      }
    })
  }

  // 获取合同章
  getMallConfig = () => {
    Api.getMallConfig({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          info: data || {},
          imageMap: [{ imageUrl: data.commonSealImageUrl }]
        })
      }
    })
  }

  // 保存商城配置
  saveMallConfig = async (params) => {
    const res = await Api.saveMallConfig(params)
    const { code } = res

    if (+code === 10000) {
      message.success('操作成功')
      this.getMallConfig()
    }
  }

  // 上传前的检验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < 2
    if (!isLt1M) {
      message.warning(`图片大小不可超过 ${2}MB！`)
    } else if (file.type !== 'image/png') {
      message.warning('仅支持png格式！')
    }

    return isLt1M
  }

  changeUploadBannerImage = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        const { imageMap = [] } = this.state
        imageMap.push({
          imageUrl: data.imageUrl
        })
        this.setState({
          imageMap
        })
      } else {
        message.error('上传失败')
      }
    }
  }

  // 删除图片
  removeImg = (i) => {
    const { imageMap } = this.state
    imageMap.splice(i, 1)
    this.setState({
      imageMap
    })
  }

  // 保存
  handleOk = () => {
    const { imageMap } = this.state

    if (imageMap.length === 0) {
      return message.warning('请上传公司合同章')
    }

    const params = {
      commonSealImageUrl: imageMap[0].imageUrl,
      name: '快乐',
      textAdvertisement: '快乐',
      customerPhoneNumber: '15713935339',
      logoImageUrl: imageMap[0].imageUrl,
      contacts: []
    }
    this.saveMallConfig(params)
  }

  render () {
    const { imageMap = [], data = {} } = this.state

    return (
      <>
        <Descriptions bordered className={styles.des}>
          <Descriptions.Item label="公司名称">{data.merchantName}</Descriptions.Item>
          <Descriptions.Item label="统一社会信用代码">{data.merchantCreditNo}</Descriptions.Item>
          <Descriptions.Item label="地区">{ renderAddressLabel(data.province, data.city, data.county) }</Descriptions.Item>
          <Descriptions.Item label="注册地址">{data.address}</Descriptions.Item>
          <Descriptions.Item label="开票电话"> {data.mobile}</Descriptions.Item>
          <Descriptions.Item label="营业执照电子版">
            <div className={styles.thumbWrap}>
              <div className={styles.thumbInfo}>
                <a style={{ display: 'block' }} href={data.businessLicenseUrl} target='_blank' rel='noopener noreferrer'>
                  <img src={data.businessLicenseUrl} />
                </a>
              </div>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="开户银行">{data.accountBank}</Descriptions.Item>
          <Descriptions.Item label="银行账号">{data.account}</Descriptions.Item>
          <Descriptions.Item label="商户权限">供应商</Descriptions.Item>
        </Descriptions>

        <div style={{ marginTop: '30px' }}>
          <h1 style={{ fontWeight: 'bold', fontSize: '20px' }}>合同信息配置</h1>
        </div>
        <div className={styles.info}>
          <div className={styles.txt}>公司合同章</div>

          {
            imageMap && imageMap.length !== 0
              ? <>
                <LazyLoad height={200}>
                  {
                    imageMap.map((item, i) => (
                      <div key={i} className={styles.imgmain}>
                        <div className={styles.showdivimg}>
                          <img src={item.imageUrl} className={styles.imgshow}></img>
                        </div>

                        <div className={styles.mainmask}>
                          <div className={styles.mask}>
                            <a style={{ color: '#ffffff' }}><Icon type='delete' style={{ fontSize: '20px', color: '#ffffff' }} onClick={() => this.removeImg(i)} /></a>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </LazyLoad>
              </>
              : <Upload showUploadList={false}
                  listType='picture-card'
                  accept='image/png, image/jpg, image/jpeg'
                  headers={{ Token: getStore('token') }}
                  action={uploadOrdinaryImageUrl}
                  beforeUpload={this.beforeUpload}
                  onChange={(info) => this.changeUploadBannerImage(info)}
                >
                  <Icon type='plus' />
                </Upload>
          }
        </div>

        <div className={styles.btn} >
          <Button type='primary' onClick={() => { this.handleOk() }}>保存</Button>
        </div>
      </>
    )
  }
}
