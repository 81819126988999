import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import { aesEncrypt } from '@/common/utils/ase'

import Api from '@/common/api/index'

import styles from './verifySlide.module.scss'

export default class VerifySlide extends Component {
  static propTypes = {
    onSuccess: PropTypes.func
  }

  state = {
    info: {}, // 验证码图片信息
    text: '向右滑动完成验证', // 按钮文案
    tipWords: '', // 验证提示文案
    passFlag: '', // 是否验证通过标识
    moveBlockLeft: undefined,
    leftBarWidth: undefined,
    // 移动中样式
    moveBlockBackgroundColor: undefined,
    leftBarBorderColor: '#e4e7eb',
    iconClass: 'arrow-right',
    iconColor: undefined,
    isEnd: false,
    status: false, // 鼠标状态
    showRefresh: true, // 是否显示刷新按钮
    transitionLeft: '',
    transitionWidth: '',

    startMoveTime: '', // 移动开始时间
    endMovetime: '' // 移动结束时间
  }

  componentDidMount () {
    this.getCaptchaVerifyInfo()

    // 监听鼠标移动
    window.addEventListener('mousemove', (e) => {
      this.handleTouchMove(e)
    })

    window.addEventListener('mouseup', (e) => {
      this.handleTouchEnd()
    })
  }

  componentWillUnmount () {
    // 卸载监听
    window.removeEventListener('mousemove', (e) => {
      this.handleTouchMove(e)
    })

    window.removeEventListener('mouseup', (e) => {
      this.handleTouchEnd()
    })
  }

  // 获取验证码信息
  getCaptchaVerifyInfo = () => {
    Api.getCaptchaVerifyInfo({ captchaType: 'blockPuzzle' }).then(res => {
      const { repCode, repData } = res
      if (repCode === '0000') {
        this.setState({
          info: repData || {}
        })
      }
    })
  }

  // 鼠标按下
  handleTouchStart = (e) => {
    e = e || window.event
    let { startLeft, startMoveTime, isEnd = false, text = '', moveBlockBackgroundColor, leftBarBorderColor, status, iconColor } = this.state
    const x = e.clientX
    const _barAreaLeft = document.getElementById('barAreaRefs').getBoundingClientRect().left

    startLeft = Math.floor(x - _barAreaLeft)
    startMoveTime = +new Date()

    if (!isEnd) {
      text = ''
      moveBlockBackgroundColor = '#00A5FF'
      leftBarBorderColor = '#00A5FF'
      iconColor = '#fff'
      e.stopPropagation()
      status = true
    }

    this.setState({
      startLeft,
      startMoveTime,
      text,
      moveBlockBackgroundColor,
      leftBarBorderColor,
      status,
      iconColor
    })
  }

  // 鼠标移动
  handleTouchMove = (e) => {
    const { status, isEnd = false, blockSize = {}, startLeft } = this.state
    e = e || window.event

    if (status && !isEnd) {
      const x = e.clientX
      const barAreaRefs = document.getElementById('barAreaRefs')
      const _barAreaLeft = barAreaRefs.getBoundingClientRect().left

      let _moveBlockLeft = x - _barAreaLeft // 小方块相对于父元素的left值

      if (_moveBlockLeft >= barAreaRefs.offsetWidth - parseInt(parseInt(blockSize.width) / 2) - 2) {
        _moveBlockLeft = barAreaRefs.offsetWidth - parseInt(parseInt(blockSize.width) / 2) - 2
      }

      if (_moveBlockLeft <= 0) {
        _moveBlockLeft = parseInt(parseInt(blockSize.width) / 2)
      }

      // 拖动后小方块的left值
      this.setState({
        moveBlockLeft: `${_moveBlockLeft - startLeft}px`,
        leftBarWidth: `${_moveBlockLeft - startLeft}px`
      })
    }
  }

  // 鼠标松开
  handleTouchEnd = () => {
    let {
      info = {}, startMoveTime, endMovetime, status, isEnd = false, moveBlockLeft, passFlag = false,
      moveBlockBackgroundColor, leftBarBorderColor, showRefresh = true, tipWords, iconClass, iconColor
    } = this.state

    endMovetime = +new Date()

    // 判断是否重合
    if (status && !isEnd) {
      let _moveLeftDistance = parseInt((moveBlockLeft || '').replace('px', ''))
      _moveLeftDistance = _moveLeftDistance * 310 / parseInt('330px')

      const params = {
        captchaType: 'blockPuzzle',
        token: info.token,
        pointJson: info.secretKey ? aesEncrypt(JSON.stringify({ x: _moveLeftDistance, y: 5.0 }), info.secretKey) : JSON.stringify({ x: _moveLeftDistance, y: 5.0 })
      }

      // api请求 验证通过
      Api.checkCaptchaVerify(params).then(res => {
        const { repCode } = res
        if (repCode === '0000') {
          moveBlockBackgroundColor = '#52c41a'
          leftBarBorderColor = '#52c41a'
          showRefresh = false
          isEnd = true
          passFlag = true
          iconClass = 'check'
          iconColor = '#ffffff'
          tipWords = `${((endMovetime - startMoveTime) / 1000).toFixed(2)}s验证成功`

          const captchaVerification = info.secretKey ? aesEncrypt(`${info.token}---${JSON.stringify({ x: _moveLeftDistance, y: 5.0 })}`, info.secretKey) : `${info.token}---${JSON.stringify({ x: _moveLeftDistance, y: 5.0 })}`

          this.setState({
            moveBlockBackgroundColor,
            leftBarBorderColor,
            showRefresh,
            isEnd,
            passFlag,
            tipWords,
            status: false,
            iconClass,
            iconColor
          }, () => {
            this.props.onSuccess({ captchaVerification: captchaVerification })
          })
        } else {
          moveBlockBackgroundColor = '#f5222d'
          leftBarBorderColor = '#f5222d'
          passFlag = false
          tipWords = '验证失败'
          iconClass = 'close'
          iconColor = '#ffffff'

          this.setState({
            moveBlockBackgroundColor,
            leftBarBorderColor,
            passFlag,
            tipWords,
            iconClass,
            iconColor,
            status: false
          })

          setTimeout(() => {
            this.handleRefresh()
            this.setState({
              tipWords: ''
            })
          }, 1000)
        }
      })
    }
  }

  // 点击刷新
  handleRefresh = () => {
    this.setState({
      showRefresh: true,
      leftBarWidth: undefined,
      isEnd: false,
      moveBlockLeft: 0,
      leftBarBorderColor: '#e4e7eb',
      moveBlockBackgroundColor: '#ffffff',

      transitionLeft: 'left 0.3s',
      transitionWidth: 'width 0.3s',
      iconClass: 'arrow-right',
      iconColor: '#666666'
    }, () => {
      this.getCaptchaVerifyInfo()

      setTimeout(() => {
        this.setState({
          transitionWidth: '',
          transitionLeft: '',
          text: '向右滑动完成验证'
        })
      }, 300)
    })
  }

  render () {
    const {
      info = {}, text, showRefresh = true, moveBlockBackgroundColor, moveBlockLeft, leftBarWidth, leftBarBorderColor, transitionWidth,
      iconClass = 'arrow-right', iconColor, passFlag, tipWords = ''
    } = this.state

    return (
      <div style={{ position: 'relative' }}>
        <div className={`${styles.verifyImgOut}`}>
          <div className={`${styles.verifyImgPanel}`}>
            <img src={`data:image/png;base64,${info.originalImageBase64}`} alt='' />

            {
              showRefresh
                ? <a className={`${styles.verifyRefresh}`} onClick={() => this.handleRefresh()}>
                  <Icon type='reload' style={{ fontSize: '20px', color: '#ffffff' }} />
                </a>
                : null
            }

            {/* 校验提示 */}
            <div className={`${styles.checkTips} ${passFlag ? styles.successBg : styles.errorBg} ${tipWords !== '' ? styles.showTip : ''}`}>
              <span className={`${styles.tipText}`}>{tipWords}</span>
            </div>

          </div>
        </div>

        {/* 拖动条区域 */}
        <div className={`${styles.verifyBarArea}`} id='barAreaRefs'>
          <span className={`${styles.verifyMsg}`}>{text}</span>
          <div className={`${styles.verifyLeftBar}`}
            style={{ width: (leftBarWidth !== undefined) ? leftBarWidth : '50px', borderColor: leftBarBorderColor, transaction: transitionWidth }}
          >
            <div className={`${styles.verifyMoveBlock}`}
              style={{ backgroundColor: moveBlockBackgroundColor, left: moveBlockLeft }}
              onTouchStart={(e) => this.handleTouchStart(e)}
              onMouseDown={(e) => this.handleTouchStart(e)}
            >
              <Icon type={iconClass} style={{ display: 'inline-block', width: '18px', height: '18px', margin: 'auto', fontSize: '18px', color: iconColor }} />

              <div className={`${styles.verifySubBlock}`}
                style={{
                  width: Math.floor(330 * 47 / 310) + 'px',
                  height: '155px',
                  top: `-${157}px`
                }}
              >
                <img style={{ width: '100%', height: '100%', display: 'block' }}
                  src={`data:image/png;base64,${info.jigsawImageBase64}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }
}
