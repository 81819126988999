import Ajax from '../utils/ajax'

/* 新产品模块 */

export default {
  /* 根据类型获取所有品牌(用于品牌检索)
   * ALL_BRAND-全部品牌   MERCHANT_BRAND-商户的品牌  CLOUD_SUPPLY_BRAND-云供应品牌
  */
  getBrandList (params) {
    return Ajax.post('/api/brand/listBrandBaseInfo', params)
  },

  // 上架产品清单-商品标签状态修改
  setProdLbaleStatus (params) {
    return Ajax.post('/api/product/commodity/update/label/status', params)
  },

  // 查看添加到我的产品库进度
  getBatchAddProgress (params, headerConfig) {
    return Ajax.get('/api/product/listLazyAddToMyProductAcceptedStatus', { params, headers: { ...headerConfig } })
  },

  /* 新产品模块start */
  // 获取型号库列表
  getModelLibraryListInProductModule (params, headerConfig) {
    return Ajax.post('/api/merchant/product/info/model/warehouse/list/page', params, { headers: { ...headerConfig } })
  },

  // 设置产品上架&添加到产品库
  setProductPutOnShelves (params) {
    return Ajax.post('/api/product/putOnShelves', params)
  },

  // 设置产品下架
  setProductPullOffShelves (params) {
    return Ajax.post('/api/product/pullOffShelves', params)
  },

  // 删除产品
  deleteProductInProductModule (params) {
    return Ajax.post('/api/product/delete', params)
  },

  // 获取我的产品库列表
  getMyProductListInProductModule (params) {
    return Ajax.post('/api/merchant/product/info/product/warehouse/list/page', params)
  },

  // 获取条件搜索产品列表
  getProductListInsearchCondition (params) {
    return Ajax.post('/api/merchant/product/info/list/page', params)
  },

  // 确定提交条件批量操作任务
  saveBatchOperateByConditionInProductModule (params) {
    return Ajax.post('/api/product/task/accept', params)
  },

  // 查看任务进度
  getProductTaskProgress (params) {
    return Ajax.get('/api/product/listLatestTask', { params })
  },

  // 获取商户sku图片信息
  getProductSkuImg (params) {
    return Ajax.post('/api/merchant/sku/attribute/image/getMerchantSkuImageInfo', params)
  },

  // 更细sku图片信息
  updateProductSkuImg (params) {
    return Ajax.post('/api/merchant/sku/attribute/image/updateMerchantSkuImageInfo', params)
  }

}
