import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, Button, message, Modal } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'
import { getLodop } from '@/assets/plugin/LodopFuncs'

import SalesUserInfo from './salesUserInfo/index'
import SendProductTable from './sendProductTable/index'
import SendExpress from './sendExpress/index'
import OutboundTableHtm from '@/components/outboundTableHtm/index'

import Api from '@/common/api/index'

class ExpressServiceForm extends Component {
  static propTypes = {
    form: PropTypes.object
  }

  state = {
    receiverDetail: {},
    senderDetail: {},
    endUserDetail: {} // 选择的客户信息
  }

  componentDidMount () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  // 快递服务-确认发货
  saveExpressServiceSendGoods = async (params) => {
    const res = await Api.saveExpressServiceSendGoods(params)
    const { code } = res
    if (+code === 10000) {
      Modal.success({
        title: '发货成功',
        onOk: () => {
          window.location.reload()
        }
      })
    }
  }

  // 点击确认发货
  handleConfirm = (type) => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { logisticsWayEnum, pickUpTimes } = values

        const newArr = []
        $lodash.forEach(values, (rows, key) => {
          if (key.indexOf('-') > -1) {
            newArr.push(rows)
          }
        })

        if (newArr.length > 0) {
          newArr.forEach((item) => {
            item.brandId = ''
            item.model = item.keyword || ''
            item.itemNumber = item.keyword || ''
          })
        }

        values.distributorPickingGoods = newArr
        values.logisticsWayEnum = logisticsWayEnum.split('-')[0]
        values.courierCode = logisticsWayEnum.split('-')[1]
        values.sendStartTime = pickUpTimes && pickUpTimes.length === 2 ? moment(pickUpTimes[0]).format('YYYY-MM-DD HH:mm:ss') : undefined
        values.sendEndTime = pickUpTimes && pickUpTimes.length === 2 ? moment(pickUpTimes[1]).format('YYYY-MM-DD HH:mm:ss') : undefined

        if (type === 1) {
          this.saveExpressServiceSendGoods(values)
        } else if (type === 2) {
          this.handlePrintOutboundHtm(values)
        }
      }
    })
  }

  // 点击打印拣货单
  handlePrintOutboundHtm = (values) => {
    this.setState({
      outboundInfo: {
        distributorOrderNo: values.distributorOrderNo,
        customerName: values.customerName,
        remarks: values.remarks,
        time: moment().format('YYYY-MM-DD HH:mm:ss')
      },
      outboundTable: values.distributorPickingGoods
    }, () => {
      this.lodopPrintSalesBill()
    })
  }

  // 打印拣货单
  lodopPrintSalesBill = () => {
    const htmlTemplateObj = document.getElementById('outboundTableHtm').innerHTML
    const LODOP = getLodop()
    if (!LODOP) {
      return message.error('请下载打印插件')
    }

    LODOP.PRINT_INIT('初始化打印') // 打印初始化
    LODOP.ADD_PRINT_HTM('8mm', '3mm', '195mm', '290mm', htmlTemplateObj)
    LODOP.PREVIEW()
  }

  // 选择收货地址
  handleSelectAddress = (obj, type) => {
    this.setState({
      [type]: obj
    })
  }

  render () {
    const { outboundInfo = {}, outboundTable = [], receiverDetail = {} } = this.state

    return (
      <Card bordered={false}>
        <SalesUserInfo {...this.props}
          onSelectAddress={(obj) => this.handleSelectAddress(obj, 'receiverDetail')}
          onSelectCustomer={(obj) => this.handleSelectAddress(obj, 'endUserDetail')}
        />

        <SendProductTable {...this.props} />

        <SendExpress {...this.props}
          receiverDetail={receiverDetail}
          onSelectAddress={(obj) => this.handleSelectAddress(obj, 'senderDetail')}
        />

        <div style={{ marginTop: '24px', textAlign: 'center' }}>
          <Button type='primary' onClick={() => this.handleConfirm(2)}>打印拣货单</Button>

          <Button type='primary' style={{ marginLeft: '30px' }} onClick={() => this.handleConfirm(1)}>确认发货</Button>
        </div>

        <OutboundTableHtm
          orderInfo={outboundInfo}
          orderDetail={outboundTable}
        />
      </Card>
    )
  }
}

const ExpressService = Form.create()(ExpressServiceForm)

export default ExpressService
