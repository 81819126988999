import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Descriptions, Form, Row, Col, Select } from 'antd'
import moment from 'moment'

import { renderAddressLabel } from '@/common/utils/mUtils'
import styles from './index.module.scss'

const FormItem = Form.Item
const Option = Select.Option

class SalesUserInfoForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    info: PropTypes.object,
    sendAddressList: PropTypes.array
  }

  state = {

  }

  render () {
    const { form, info = {}, sendAddressList = [] } = this.props
    const { getFieldDecorator } = form

    return (
      <Card title='单据信息' bodyStyle={{ padding: '15px' }}>
        <Descriptions>
          <Descriptions.Item label='出库单号'>{info.outboundOrderNo}</Descriptions.Item>
          <Descriptions.Item label='订单编号'>{ info.orderId }</Descriptions.Item>
          <Descriptions.Item label='订单日期'>
            { info.orderTime ? moment(info.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
          </Descriptions.Item>

          <Descriptions.Item label='客户名称'>{info.customerName}</Descriptions.Item>
          <Descriptions.Item label='收货人'>{info.receiverName}</Descriptions.Item>
          <Descriptions.Item label='联系方式'>{info.mobile}</Descriptions.Item>
          <Descriptions.Item label='收货地址'>----</Descriptions.Item>
        </Descriptions>

        <Form>
          <Row>
            <Col span={12}>
              <Row className={`${styles.formitemRow}`}>
                {
                  sendAddressList.length > 0
                    ? <FormItem label='发货地址'>
                      {
                        getFieldDecorator('addressId', {
                          initialValue: sendAddressList && sendAddressList.length > 0 ? sendAddressList[0].id : null,
                          rules: [
                            { required: true, message: '请选择快递发货地址' }
                          ]
                        })(
                          <Select onChange={this.handleChangeSelectedAddress}>
                          {
                            sendAddressList.map((opt, n) => (
                              <Option value={opt.id} key={`addressOpt-${opt.id}-${n}`}>
                                {renderAddressLabel(opt.province, opt.city, opt.county)} {opt.address} {opt.name} {opt.mobile}
                              </Option>
                            ))
                          }
                          </Select>
                        )
                      }
                    </FormItem>
                    : <FormItem label='发货地址'>
                        <span>暂无发货地址</span>
                      </FormItem>
                }
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
    )
  }
}

const SalesUserInfo = Form.create()(SalesUserInfoForm)

export default SalesUserInfo
