import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Card } from 'antd'
import TOTAL_ICON from '@/assets/images/icon/upload_total.png'
import SUCCESS_ICON from '@/assets/images/icon/upload_success.png'
import FAIL_ICON from '@/assets/images/icon/upload_fail.png'
import styles from './uploadinfo.module.scss'
class uploadinfo extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    success: this.props.history.location.state.data, // 成功的个数
    total: this.props.history.location.state.total.length, // 总数
    hoverKey: 'totalHover'
  }

  handleMouseEnter = (key) => {
    this.setState({ hoverKey: key })
  }

  handleMouseLeave = (key) => {
    this.setState({ hoverKey: '' })
  }

  handleBack = () => {
    this.props.history.push({ pathname: '/customer/customerList' })
  }

  render () {
    const { success, total, hoverKey } = this.state
    return (
      <Card bodyStyle={{ padding: '15px' }} bordered={false}>
        <div className={`${styles.header}`}>
          <div className={`${styles.title}`}>全部数据：{total > 0 ? (success / total * 100).toFixed(2) : '0.00'}%</div>
          <Button type='primary' onClick={() => this.handleBack()}>返回列表</Button>
        </div>

        <div className={`${styles.content}`}>
          <div className={`${styles.backgroundBox}`}>
            <div className={`${styles.totalLegendWrap}`}>
              <p className={`${styles.totalLegendLine}`} /><span className={`${styles.totalPoint}`} />
              <p className={`${styles.legendContent} ${styles.totalLegend} ${hoverKey === 'totalHover' ? styles.isHover : ''}`} onMouseEnter={() => this.handleMouseEnter('totalHover')} onMouseLeave={() => this.handleMouseLeave('totalHover')}>数据已上传</p>
            </div>
            <div className={`${styles.successLegendWrap}`}>
              <p className={`${styles.successLegendLine}`} /><span className={`${styles.successPoint}`} />
              <p className={`${styles.legendContent} ${styles.successLegend} ${hoverKey === 'successHover' ? styles.isHover : ''}`} onMouseEnter={() => this.handleMouseEnter('successHover')} onMouseLeave={() => this.handleMouseLeave('successHover')}>数据完全匹配</p>
            </div>
            <div className={`${styles.failLegendWrap}`}>
              <p className={`${styles.failLegendLine}`} /><span className={`${styles.failPoint}`} />
              <p className={`${styles.legendContent} ${styles.failLegend} ${hoverKey === 'failHover' ? styles.isHover : ''}`} onMouseEnter={() => this.handleMouseEnter('failHover')} onMouseLeave={() => this.handleMouseLeave('failHover')}>数据需要修改</p>
            </div>

            <div className={`${styles.totalLabelWrap}`}>
              <p className={`${styles.labelContent} ${styles.totalLegend} ${hoverKey === 'totalHover' ? styles.isHover : ''}`} onMouseEnter={() => this.handleMouseEnter('totalHover')} onMouseLeave={() => this.handleMouseLeave('totalHover')}>{total}</p>
              <span className={`${styles.totalPoint}`} /><p className={`${styles.totalLegendLine}`} />
            </div>
            <div className={`${styles.successLabelWrap}`}>
              <p className={`${styles.labelContent} ${styles.successLegend} ${hoverKey === 'successHover' ? styles.isHover : ''}`} onMouseEnter={() => this.handleMouseEnter('successHover')} onMouseLeave={() => this.handleMouseLeave('successHover')}>{success}</p>
              <span className={`${styles.successPoint}`} /><p className={`${styles.successLegendLine}`} />
            </div>
            <div className={`${styles.failLabelWrap}`}>
              <p className={`${styles.labelContent} ${styles.failLegend} ${hoverKey === 'failHover' ? styles.isHover : ''}`} onMouseEnter={() => this.handleMouseEnter('failHover')} onMouseLeave={() => this.handleMouseLeave('failHover')}>{total - success}</p>
              <span className={`${styles.failPoint}`} /><p className={`${styles.failLegendLine}`} />
            </div>
          </div>
        </div>

        <div className={`${styles.footer}`}>
          <div className={`${styles.flexbox} ${styles.total} ${hoverKey === 'totalHover' ? styles.backgoundHover : ''}`} onMouseEnter={() => this.handleMouseEnter('totalHover')} onMouseLeave={() => this.handleMouseLeave('totalHover')}>
            <img className={`${styles.iconBox}`} src={TOTAL_ICON} />
            <div className={`${styles.flexboxBody}`}>
              <div className={`${styles.bodyContent}`}>{total}<span className={`${styles.unit}`}>个</span></div>
              <p className={`${styles.description}`}>数据已上传</p>
            </div>
          </div>

          <div className={`${styles.flexbox} ${styles.success} ${hoverKey === 'successHover' ? styles.backgoundHover : ''}`} onMouseEnter={() => this.handleMouseEnter('successHover')} onMouseLeave={() => this.handleMouseLeave('successHover')}>
            <img className={`${styles.iconBox}`} src={SUCCESS_ICON} />
            <div className={`${styles.flexboxBody}`}>
              <div className={`${styles.bodyContent}`}>{success}<span className={`${styles.unit}`}>个</span></div>
              <p className={`${styles.description}`}>数据完全匹配</p>
            </div>
          </div>

          <div className={`${styles.flexbox} ${styles.fail} ${hoverKey === 'failHover' ? styles.backgoundHover : ''}`} onMouseEnter={() => this.handleMouseEnter('failHover')} onMouseLeave={() => this.handleMouseLeave('failHover')}>
            <img className={`${styles.iconBox}`} src={FAIL_ICON} />
            <div className={`${styles.flexboxBody}`}>
              <div className={`${styles.bodyContent}`}>{total - success}<span className={`${styles.unit}`}>个</span></div>
              <p className={`${styles.description}`}>数据需要修改</p>
            </div>
          </div>
        </div>
      </Card>
    )
  }
}

export default uploadinfo
