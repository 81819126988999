import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Form, Button, Modal } from 'antd'
import moment from 'moment'

import DrawerContents from '../drawerContents/index'

import styles from './index.module.scss'

const { confirm } = Modal

class PurchaseDrawerForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    visible: PropTypes.bool,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }

  state = {
    sendAddress: {}
  }

  // 点击确定
  handleConfirm = () => {
    const { form, detailInfo = {}, onConfirm } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        const { sendAddress = {} } = this.state
        const { logisticsWayEnum, pickUpTimes } = values

        if (logisticsWayEnum && logisticsWayEnum !== '') {
          const logisticsWay = logisticsWayEnum.split('-')

          values.logisticsWayEnum = logisticsWay[0]

          if (logisticsWay[0] === 'SAAS') {
            values.courierCode = logisticsWay[1]
            values.sendAddress = sendAddress
          }
        }

        values.id = detailInfo.id
        values.type = detailInfo.type
        values.status = 'AUDIT_SUCCESS'
        values.sendStartTime = pickUpTimes && pickUpTimes.length === 2 ? moment(pickUpTimes[0]).format('YYYY-MM-DD HH:mm:ss') : undefined
        values.sendEndTime = pickUpTimes && pickUpTimes.length === 2 ? moment(pickUpTimes[1]).format('YYYY-MM-DD HH:mm:ss') : undefined

        onConfirm(values)
      }
    })
  }

  // 点击驳回
  handleRefuse = () => {
    confirm({
      title: '确定驳回申请？',
      onOk: () => {
        const { detailInfo = {}, onConfirm } = this.props

        const params = {
          id: detailInfo.id,
          type: detailInfo.type,
          status: 'AUDIT_FAILED'
        }

        onConfirm(params)
      }
    })
  }

  // 监听地址选择
  handleChangeSendAddress = (values) => {
    this.setState({
      sendAddress: values
    })
  }

  render () {
    const { form, visible = false, detailInfo = {}, onCancel } = this.props

    return (
      <Drawer title='售后申请'
        width={1300} bodyStyle={{ paddingBottom: '80px' }}
        visible={visible}
        destroyOnClose
        placement='right'
        closable={false}
        onClose={() => onCancel()}
      >
        <Form>
          {
            visible
              ? <DrawerContents form={form} detailInfo={detailInfo}
                  onSelectSendAddress={(values) => this.handleChangeSendAddress(values)}
              />
              : null
          }
        </Form>

        <div className={`${styles.btns}`}>
          <Button onClick={() => onCancel()}>取消</Button>
          {
            detailInfo.status === 'WAITING_AUDIT'
              ? <>
                <Button type='danger' style={{ marginLeft: '16px' }} onClick={() => this.handleRefuse()}>驳回</Button>
                <Button type='primary' style={{ marginLeft: '16px' }} onClick={() => this.handleConfirm()}>通过</Button>
              </>
              : null
          }
        </div>
      </Drawer>
    )
  }
}

const PurchaseDrawer = Form.create()(PurchaseDrawerForm)

export default PurchaseDrawer
