import Ajax from '../utils/ajax'

export default {
  // 用户登录
  login (params) {
    return Ajax.post('/api/user/login', params)
  },

  // 获取当前登录用户信息
  getLoginUserInfo (params) {
    return Ajax.get('/api/home/page/get/account/info', { params })
  }
}
