import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, Cascader, Switch, Row, message } from 'antd'
import $lodash from 'lodash'

import DiscernAddress from '@/components/discernAddress/index'
import { addressToTree } from '@/common/utils/mUtils'

import styles from './index.module.scss'

const FormItem = Form.Item
const { TextArea } = Input
const cityOptions = addressToTree()

class AddressForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number, // 1-新增 2-修改
    endUserId: PropTypes.number,
    detailInfo: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }

  state = {
    detailInfo: this.props.detailInfo
  }

  componentDidMount () {

  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { type = 1, detailInfo = {}, endUserId = 0 } = this.props
        const { name, mobile, citys, address, status } = values

        if ($lodash.trim(name).length === 0) { return message.warning('请输入发货人名称') }

        if ($lodash.trim(address).length === 0) { return message.warning('请输入详细地址') }

        if (+citys[1] === +citys[2]) { return message.warning('请选择行政区！') }

        const params = {
          name: $lodash.trim(name),
          mobile: $lodash.trim(mobile),
          province: citys[0],
          city: citys[1],
          county: citys[2],
          address: $lodash.trim(address),
          endUserId: endUserId,
          status: status ? 'ENABLE' : 'DISABLE'
        }

        if (type === 2) {
          params.id = detailInfo.id
        }

        this.props.onConfirm(params)
      }
    })
  }

  handleCancel = () => {
    this.props.onCancel()
  }

  // 渲染默认地址
  renderCascaderDefaultValue = () => {
    const { detailInfo = {} } = this.state
    const options = []

    if (+detailInfo.province > 0) {
      options.push(`${detailInfo.province}`, `${detailInfo.city}`, `${detailInfo.county}`)
    }

    return options
  }

  // 智能地址设置显示
  setIntelAddress = (params) => {
    params.id = this.props.detailInfo.id
    params.category = this.props.detailInfo.category
    params.name = params.receiver
    params.mobile = params.phoneNumber
    this.setState({
      detailInfo: params,
      intelModal: false
    })
  }

  render () {
    const { type = 1 } = this.props
    const { getFieldDecorator } = this.props.form
    const { detailInfo = {} } = this.state

    return (
      <>
        <Modal visible width={560} title={+type === 1 ? '新增地址' : '修改地址'}
          bodyStyle={{ padding: '5px' }}
          maskClosable={false}
          onOk={this.handleConfirm}
          onCancel={this.handleCancel}
        >
          <div style={{ width: '480px', margin: '0px auto' }}>
            <Row>
              <DiscernAddress onConfirm={(params) => this.setIntelAddress(params)} />
            </Row>
            <Form className={styles.from}>

              <FormItem label='发货人姓名' colon={false}>
              {
                  getFieldDecorator('name', {
                    initialValue: detailInfo.name || '',
                    rules: [
                      { required: true, message: '请您填写发货人姓名' }
                    ]
                  })(
                    <Input placeholder='请输入发货人姓名' autoComplete='off' />
                  )
                }
              </FormItem>
              <FormItem label='联系方式' colon={false}>
              {
                  getFieldDecorator('mobile', {
                    initialValue: detailInfo.mobile || '',
                    rules: [
                      { required: true, message: '请您填写发货人联系方式' }
                    ]
                  })(
                    <Input placeholder='请输入发货人手机号码' autoComplete='off' />
                  )
                }
              </FormItem>
              <FormItem label='发货地区' colon={false}>
              {
                  getFieldDecorator('citys', {
                    rules: [
                      { required: true, message: '请选择省、市、区' }
                    ],
                    initialValue: this.renderCascaderDefaultValue()
                  })(
                    <Cascader placeholder='请选择省市区'
                      fieldNames={{ value: 'code', label: 'name', children: 'childList' }}
                      options={cityOptions}
                    />
                  )
                }
              </FormItem>
              <FormItem label='详细地址' colon={false}>
                {
                  getFieldDecorator('address', {
                    rules: [
                      { required: true, message: '请填写详细地址' }
                    ],
                    initialValue: detailInfo.address || ''
                  })(
                    <TextArea rows={4} placeholder='请输入详细地址' autoComplete='off' />
                  )
                }
              </FormItem>

              <FormItem style={{ marginBotom: '0px' }}>
                <FormItem style={{ display: 'inline-block', width: 'calc(100% - 200px)' }}><label style={{ color: '#333333' }}>设置为默认地址</label></FormItem>
                <FormItem style={{ display: 'inline-block', width: '200px', textAlign: 'right' }}>
                  {
                    getFieldDecorator('status', {
                      initialValue: detailInfo.status === 'ENABLE',
                      valuePropName: 'checked'
                    })(
                    <Switch checkedChildren='是' unCheckedChildren='否' />
                    )
                  }
                </FormItem>
              </FormItem>
            </Form>
          </div>
        </Modal>

      </>
    )
  }
}

const AddressModal = Form.create()(AddressForm)

export default AddressModal
