import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col, Statistic } from 'antd'
import moment from 'moment'

import styles from './index.module.scss'

export default class CreditDetails extends Component {
  static propTypes = {
    detailInfo: PropTypes.object
  }

  render () {
    const { detailInfo = {} } = this.props

    return (
      <div style={{ marginBottom: '20px' }}>
        <Card bodyStyle={{ padding: '15px' }}>
          <div style={{ marginBottom: '10px' }}>
            <span>{detailInfo.enterpriseName}</span>
          </div>
          <Row>
            <Col span={8}>
              <div className={`${styles.colWrap} ${styles.borderRight}`}>
                <div className={`${styles.coltitle}`}>使用额度</div>
                <Statistic prefix={<span>&yen;</span>} value={+detailInfo.usedAmount} precision={2} />
              </div>
            </Col>
            <Col span={8}>
              <div className={`${styles.colWrap} ${styles.borderRight}`}>
                <div className={`${styles.coltitle}`}>信用额度</div>
                <Statistic prefix={<span>&yen;</span>} value={+detailInfo.maxAmount} precision={2} />
              </div>
            </Col>
            <Col span={8}>
              <div className={`${styles.colWrap}`}>
                <div style={{ marginBottom: '10px' }}>
                  <label className={`${styles.label}`}>最近到期日</label>
                  <span>{ detailInfo.lastPeriodTime ? moment(detailInfo.lastPeriodTime).format('YYYY-MM-DD') : '----' }</span>
                </div>
                <div>
                  <label className={`${styles.label}`}>账期</label>
                  <span>{+detailInfo.period}天</span>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    )
  }
}
