import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Select } from 'antd'

import { renderAddressLabel } from '@/common/utils/mUtils'
import styles from './index.module.scss'

const FormItem = Form.Item
const Option = Select.Option

export default class InvoiceReceiving extends Component {
  static propTypes = {
    form: PropTypes.object,
    sendAddressList: PropTypes.array,
    selectedAddressKey: PropTypes.number,
    receivingAddress: PropTypes.object
  }

  render () {
    const { form, receivingAddress = {}, sendAddressList = [], selectedAddressKey = null } = this.props
    const { getFieldDecorator, getFieldValue } = form

    return (
      <div style={{ marginTop: '24px' }}>
        <div className={`${styles.formitemRow}`}>
          <FormItem label='收票地址'>
            {renderAddressLabel(receivingAddress.province, receivingAddress.city, receivingAddress.county, receivingAddress.address)} {receivingAddress.name} {receivingAddress.mobile}
          </FormItem>
        </div>

        {
          getFieldValue('logisticsWayEnum') === 'SAAS'
            ? <div className={`${styles.formitemRow}`}>
              <FormItem label='发货地址'>
                {
                  getFieldDecorator('addressId', {
                    initialValue: selectedAddressKey,
                    rules: [
                      { required: false, message: '请选择发货地址' }
                    ]
                  })(
                    <Select>
                      {
                        sendAddressList.map((opt, n) => (
                          <Option value={opt.id} key={`addressOpt-${opt.id}-${n}`}>
                            {renderAddressLabel(opt.province, opt.city, opt.county, opt.address)} {opt.name} {opt.mobile}
                          </Option>
                        ))
                      }
                    </Select>
                  )
                }
              </FormItem>
            </div>
            : null
        }

      </div>
    )
  }
}
