import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'antd'

import LogisticsTrace from '@/components/logisticsTrace/index'

import Api from '@/common/api/index'

export default class LogisticsDetails extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    courierNumberList: PropTypes.array
  }

  state = {
    selectedCourier: null, // 选中的运单号
    selectedCourierCode: null, // 选中的物流公司编码
    logisticsTraces: []
  }

  componentDidMount () {
    const { courierNumberList = [] } = this.props

    if (courierNumberList.length > 0) {
      this.handleSelectedCourierNumber(courierNumberList[0])
    }
  }

  // 获取物流轨迹
  getLogisticsTraceByMailNo = async () => {
    const { detailInfo = {} } = this.props
    const { selectedCourier } = this.state

    const params = {
      ShipperCode: detailInfo.courierCode,
      LogisticCode: selectedCourier,
      CustomerName: detailInfo.consigneePhoneNumber ? detailInfo.consigneePhoneNumber.substr(-4) : ''
    }

    const res = await Api.getLogisticsTraceByMailNo(params)
    const { code, data } = res
    if (+code === 10000) {
      const _list = data.traces || []

      this.setState({
        logisticsTraces: _list.length > 0 ? _list.reverse() : _list,
        showTrace: true
      })
    }
  }

  // 点击选择包裹
  handleSelectedCourierNumber = (key) => {
    this.setState({
      selectedCourier: key
    }, () => {
      this.getLogisticsTraceByMailNo()
    })
  }

  render () {
    const { detailInfo = {}, courierNumberList = [] } = this.props
    const { selectedCourier, logisticsTraces = [] } = this.state

    return (
      <>
        <div>
          <Radio.Group buttonStyle='solid' value={selectedCourier}
            onChange={(e) => this.handleSelectedCourierNumber(e.target.value)}
          >
            {
              courierNumberList.map((cour, n) => (
                <Radio.Button value={cour} key={`cour-${n}`}>运单号{cour}</Radio.Button>
              ))
            }
          </Radio.Group>
        </div>

        <LogisticsTrace
          logisticsTraces={logisticsTraces}
          courierCode={detailInfo.courierCode}
        />
      </>
    )
  }
}
