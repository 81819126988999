import React, { Component } from 'react'
import { Row, message, Modal, Icon } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import BillCard from './billCard'
import BilldetailCard from './billdetailCard'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'

const { downIIASaaSDepponExpressBillByMonth } = DownloadApi
const { billStatusMaps } = filter

export default class TransportBill extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'dueMonth',
        formType: 'MonthPicker',
        itemParams: {
          label: '账单日期'
        },
        cptParams: {

        },
        options: []
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '账单状态'
        },
        rules: {
          initialValue: 'ALL'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: billStatusMaps
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20, options: ['10', '20', '50', '100', '200'] },
    query: {

    },
    billData: [],
    detailData: [],
    dueMonthList: []
  }

  componentDidMount () {
    this.getTransportBill()
  }

  // 获取IIASaaS快递运费账单
  getTransportBill = () => {
    const { query = {} } = this.state
    const params = {
      ...query,
      pageNo: 1,
      pageSize: 999
    }

    Api.getTransportBill(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        const list = data.list || []
        const dueMonthList = []

        if (list.length > 0) {
          list.forEach((item) => {
            dueMonthList.push(item.dueMonth)
          })
        }

        this.setState({
          billData: data.list || [],
          dueMonthList: dueMonthList
        }, () => {
          if (dueMonthList.length > 0) {
            this.getTransportBillDetail()
          }
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 获取运费流水
  getTransportBillDetail = () => {
    const { pages, dueMonthList = [] } = this.state

    const params = {
      dueMonthList: dueMonthList,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getTransportBillDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          detailData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    search.dueMonth = search.dueMonth ? moment(search.dueMonth).format('YYYYMM') : undefined

    this.setState({
      query: search,
      detailData: [],
      pages: {
        ...pages,
        pageNo: 1,
        total: 0
      }
    }, () => {
      this.getTransportBill()
    })
  }

  // 点击导出月度账单
  handleDownloadBill = () => {
    const { query = {} } = this.state
    if (!query.dueMonth) {
      Modal.warning({
        icon: <Icon type='exclamation-circle' style={{ color: '#FF3434' }} />,
        title: <span style={{ color: '#FF3434' }}>请选择您想导出的月份</span>,
        content: <span>请在搜索栏——账单日期中<br />选择您想导出的日期<br />然后点击搜索</span>
      })

      return false
    }

    downIIASaaSDepponExpressBillByMonth({ dueMonth: query.dueMonth })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getTransportBillDetail()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getTransportBillDetail()
    })
  }

  render () {
    const { searchData, pages, billData = [], detailData = [] } = this.state

    return (
      <>
        <Row style={{ marginBottom: '15px' }}>
          <Row style={{ marginBottom: '15px' }}>
            <SearchForm {...this.props} dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Row>

          <BillCard
            tableData={billData}
          />
        </Row>

        <BilldetailCard
          pages={pages}
          tableData={detailData}
          onDownload={() => this.handleDownloadBill()}
          pageChange={(page, pageSize) => this.pageChange(page, pageSize)}
          pageChangeSize={(current, size) => this.pageChangeSize(current, size)}
        />

      </>
    )
  }
}
