import CloudCredit from '@/pages/financial/cloudCredit/index' // 云平台信用管理
import SupplyInvoice from '@/pages/financial/supplyInvoice/index'// 云供应开票详情
import TransportBill from '@/pages/financial/Bill/index' // 运费账单
import PlatceditDetail from '@/pages/financial/platcredit-detail/index' // 云平台信用管理详情

export default [
  {
    path: '/financial/cloudCredit',
    name: '云平台信用管理',
    component: CloudCredit
  },
  {
    path: '/financial/cloudInvoice',
    name: '云供应开票管理',
    component: SupplyInvoice
  },
  {
    path: '/financial/freightBill',
    name: '运费账单',
    component: TransportBill
  },
  {
    path: '/financial/platcreditDetail',
    name: '信用详情',
    component: PlatceditDetail
  }
]
