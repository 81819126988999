import Ajax from '../utils/ajax'

export default {
  // 云供应售后- 获取云供应订单售后申请列表
  getSupplyOrderSaledApplyList (params) {
    return Ajax.post('/backend/cloud/supply/afterSale/listPage', params)
  },

  // 云供应售后- 获取云供应订单售后详情
  getSupplyOrderSaledDetails (params) {
    return Ajax.post('/backend/cloud/supply/afterSale/detail', params)
  },

  // 云供应售后- 云供应订单售后审核
  saveSupplyOrderSaledAudit (params) {
    return Ajax.post('/backend/cloud/supply/afterSale/audit', params)
  }
}
