import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Radio } from 'antd'

import SupplySpotProducts from './supplySpotProducts/index' // 现货云供应
import SupplyFuturesProducts from './supplyFuturesProducts/index' // 期货云供应

const tabPaneMaps = [
  { id: 'CLOUD_SPOT', name: '现货云供应' },
  { id: 'CLOUD_FUTURES', name: '期货云供应' }
]

export default class CloudSupplyProducts extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    activeTabKey: 'CLOUD_SPOT'
  }

  componentDidMount () {
    const { state = {} } = this.props.history.location

    this.setState({
      activeTabKey: state.activeTabKey && state.activeTabKey !== '' ? state.activeTabKey : 'CLOUD_SPOT'
    })
  }

  // 切换Tab
  handleChangeTabPane = (key) => {
    this.setState({
      activeTabKey: key
    })
  }

  render () {
    const { activeTabKey = 'CLOUD_SPOT' } = this.state

    return (
      <>
        <Card bordered={false}>
          <div style={{ marginBottom: '24px' }}>
            <Radio.Group buttonStyle='solid' value={activeTabKey} onChange={(e) => this.handleChangeTabPane(e.target.value)}>
              {
                tabPaneMaps.map((item, index) => (
                  <Radio.Button value={item.id} key={item.id}>{ item.name }</Radio.Button>
                ))
              }
            </Radio.Group>
          </div>

          {
            activeTabKey === 'CLOUD_SPOT'
              ? <SupplySpotProducts history={this.props.history} />
              : null
          }

          {
            activeTabKey === 'CLOUD_FUTURES'
              ? <SupplyFuturesProducts history={this.props.history} />
              : null
          }

        </Card>
      </>
    )
  }
}
