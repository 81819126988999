import React, { Component } from 'react'
import { Card, Table, message, Button } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'
import InvoiceModal from './invoiceModal/index'
import LogisticsModal from '@/components/logisticsModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderInvoiceOpenedStatusLabel } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

const { SERIALWIDTH } = consts
const { pageSizeOptions, invoiceOpenedStatusMaps } = filter
const _invoiceOpenedStatusMaps = $lodash.filter(invoiceOpenedStatusMaps, (o) => { return o.id !== 'DISCARDED' })

export default class SupplyInvoice extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'invoiceRecordId',
        formType: 'Input',
        itemParams: {
          label: '开票申请编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'applyTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '申请时间'
        },
        cptParams: {

        }
      },
      {
        name: 'invoiceRecordStatus',
        formType: 'Select',
        itemParams: {
          label: '开票状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ..._invoiceOpenedStatusMaps
        ]
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '开票时间'
        },
        cptParams: {

        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {},
    invoiceModal: false,
    logisticsModal: false
  }

  columns = [
    {
      title: '序号',
      key: 'supplyInvoiceCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '开票申请编号',
      key: 'supplyInvoiceCol-1',
      dataIndex: 'id'
    },
    {
      title: '客户名称',
      key: 'supplyInvoiceCol-2',
      width: 180,
      ellipsis: true,
      render: (text, record, index) => (
        <>萨斯云科技（上海）有限责任公司</>
      )
    },
    {
      title: '开票金额',
      key: 'supplyInvoiceCol-3',
      dataIndex: 'invoiceAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.invoiceAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '申请时间',
      key: 'supplyInvoiceCol-4',
      dataIndex: 'invoiceApplyTime',
      render: (text, record, index) => (
        <>
          { record.invoiceApplyTime ? moment(record.invoiceApplyTime).format('YYYY-MM-DD HH:mm:ss') : '----'}
        </>
      )
    },
    {
      title: '开票状态',
      key: 'supplyInvoiceCol-5',
      dataIndex: 'status',
      render: (text, record, index) => (
        <>{ renderInvoiceOpenedStatusLabel(record.status) }</>
      )
    },
    {
      title: '开票时间',
      key: 'supplyInvoiceCol-6',
      dataIndex: 'openedTime',
      render: (text, record, index) => (
        <>
          { record.openedTime ? moment(record.openedTime).format('YYYY-MM-DD HH:mm:ss') : '----'}
        </>
      )
    },
    {
      title: '开票人',
      key: 'supplyInvoiceCol-7',
      dataIndex: 'adminUserName'
    },
    {
      title: '修改时间',
      key: 'supplyInvoiceCol-8',
      dataIndex: 'modifyTime',
      render: (text, record, index) => (
        <>
          { record.modifyTime ? moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss') : '----'}
        </>
      )
    },
    {
      title: '修改人',
      key: 'supplyInvoiceCol-9',
      dataIndex: 'modifyName'
    },
    {
      title: '操作',
      key: 'supplyInvoiceCol-10',
      width: 180,
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.showInvoiceModal(record)}>查看详情</Button>
          {
            record.status === 'OPENED'
              ? <Button type='link' size='small' onClick={() => this.showLogisticsModal(record)}>查看物流</Button>
              : null
          }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getSupplyOrderInvoiceApplyOpeningList()
  }

  // 云供应发票- 获取开票申请列表
  getSupplyOrderInvoiceApplyOpeningList = () => {
    const { pages, query = {} } = this.state
    const params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize,
      ...query
    }

    Api.getSupplyOrderInvoiceApplyOpeningList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 云供应发票- 确认云供应开票
  saveSupplyOrderInvoiceOpened = async (params) => {
    const res = await Api.saveSupplyOrderInvoiceOpened(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getSupplyOrderInvoiceApplyOpeningList()
    }
  }

  // 云供应发票- 确认云供应重新开票
  saveSupplyOrderInvoiceRepeatOpened = async (params) => {
    const res = await Api.saveSupplyOrderInvoiceRepeatOpened(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getSupplyOrderInvoiceApplyOpeningList()
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.applyTimes) {
      search.applyBeginTime = search.applyTimes.length === 2 ? search.applyTimes[0].format('YYYY-MM-DD') : undefined
      search.applyEndTime = search.applyTimes.length === 2 ? search.applyTimes[1].format('YYYY-MM-DD') : undefined

      delete search.applyTimes
    }

    if (search.times) {
      search.openedBeginTime = search.times.length === 2 ? search.times[0].format('YYYY-MM-DD') : undefined
      search.openedEndTime = search.times.length === 2 ? search.times[1].format('YYYY-MM-DD') : undefined

      delete search.times
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getSupplyOrderInvoiceApplyOpeningList()
    })
  }

  // 点击确定开票
  handleConfirmInvoiceOpened = (params, type) => {
    if (type === 'OPENING') {
      this.saveSupplyOrderInvoiceOpened(params)
    }

    if (type === 'OPENED') {
      this.saveSupplyOrderInvoiceRepeatOpened(params)
    }
  }

  // 点击查看开票详情
  showInvoiceModal = (record) => {
    this.setState({
      rowDetail: record || {},
      invoiceModal: true
    })
  }

  // 点击物流跟踪
  showLogisticsModal = (record = {}) => {
    this.setState({
      rowDetail: { courierCode: record.courierCode, courierNumber: record.courierNumber, mobile: record.phoneNumber },
      logisticsModal: true
    })
  }

  closeModal = () => {
    this.setState({
      invoiceModal: false,
      logisticsModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSupplyOrderInvoiceApplyOpeningList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSupplyOrderInvoiceApplyOpeningList()
    })
  }

  render () {
    const { pages, searchData, tableData = [], rowDetail = {}, invoiceModal = false, logisticsModal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }
    return (
      <>
        <Card bordered={false}>
          <div style={{ marginBottom: '20px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </div>

          <Table rowKey='id'
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
          />
        </Card>

        {
          invoiceModal
            ? <InvoiceModal
              ids={[rowDetail.id]}
              openStatus={'OPENING'}
              isRepeat={rowDetail.status === 'OPENED'}
              onCancel={() => this.closeModal()}
              onConfirm={(params, status) => this.handleConfirmInvoiceOpened(params, status)}
            />
            : null
        }

        {
          logisticsModal
            ? <LogisticsModal
              courierNumberList={[rowDetail]}
              onCancel={this.closeModal}
            />
            : null
        }
      </>
    )
  }
}
