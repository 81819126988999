import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table, Badge } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

class SoldInLogModal extends Component {
  static propTypes = {
    skuType: PropTypes.string,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }

  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }

  columns = [
    {
      title: '操作人',
      key: 'logList-0',
      width: 150,
      dataIndex: 'createName'
    },
    {
      title: '供应价格',
      key: 'logList-1',
      render: (text, record, index) => (
        <>
          {
            record.newCloudSupplyStatus === 'ENABLE'
              ? <>&yen;{+record.newCloudSupplyPrice}</>
              : '----'
          }
        </>
      )
    },
    {
      title: '供应状态',
      key: 'logList-2',
      render: (text, record, index) => (
        <div>
          {
            record.newCloudSupplyStatus === 'ENABLE'
              ? <Badge status='success' text='已上架' />
              : <Badge status='error' text='未上架' />
          }
        </div>
      )
    },
    {
      title: '操作时间',
      key: 'logList-3',
      width: 200,
      render: (text, record, index) => (
        <div>{moment(record.createTime).format('YYYY-MM-DD HH:mm:ss')}</div>
      )
    }
  ]

  componentDidMount () {
    this.getCloudSupplyLogList()
  }

  getCloudSupplyLogList = async () => {
    const { detailInfo = {}, skuType } = this.props
    const { pages } = this.state

    const res = await Api.getCloudSupplyLogList({ commoditySkuId: detailInfo.commoditySkuId, skuType: skuType, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCloudSupplyLogList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCloudSupplyLogList()
    })
  }

  render () {
    const { pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <Modal title='云供应日志' width={1000} bodyStyle={{ padding: '16px' }}
        visible
        maskClosable={false}
        onCancel={this.props.onCancel}
        footer={null}
      >
        <Table rowKey='id'
          columns={this.columns}
          dataSource={tableData}
          pagination={pagination}
          scroll={{ y: 350 }}
        />
      </Modal>
    )
  }
}

export default SoldInLogModal
