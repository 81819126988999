import axios from 'axios'
import config from '@/common/utils/config'
import { getStore } from './mUtils'
import XLSX from 'xlsx'

const { host } = config

export const downloadFileByGet = async (url, params) => {
  const res = await axios({
    method: 'get',
    url: `${host}${url}`,
    params: params,
    headers: {
      Token: getStore('token') || ''
    },
    responseType: 'blob'
  })

  // 提取文件名
  const fileName = decodeURI(res.headers['content-disposition'].match(/filename=(.*)/)[1])
  const blob = new Blob([res.data])

  if ('download' in document.createElement('a')) { // 非IE下载
    const elink = document.createElement('a')
    elink.download = fileName
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href)// 释放URL 对象
    document.body.removeChild(elink)
  } else { // IE10+下载
    navigator.msSaveBlob(blob, fileName)
  }
}

export const downloadFileByPost = async (url, params) => {
  const res = await axios({
    method: 'post',
    url: `${host}${url}`,
    data: params,
    headers: {
      Token: getStore('token') || ''
    },
    responseType: 'blob'
  })
  // 提取文件名
  const fileName = decodeURI(res.headers['content-disposition'].match(/filename=(.*)/)[1])
  const blob = new Blob([res.data])

  if ('download' in document.createElement('a')) { // 非IE下载
    const elink = document.createElement('a')
    elink.download = fileName
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href)// 释放URL 对象
    document.body.removeChild(elink)
  } else { // IE10+下载
    navigator.msSaveBlob(blob, fileName)
  }
}

// 字符串转为字符流
export const stringToBuffer = (str) => {
  const buf = new ArrayBuffer(str.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i !== str.length; ++i) {
    view[i] = str.charCodeAt(i) & 0xFF
  }
  return buf
}

/**
 * 前端导出EXCEL
 */
export const downloadExcel = (data, name) => {
  const sheet = XLSX.utils.aoa_to_sheet(data)
  const sheetName = name || 'sheet1'
  const workbook = {
    SheetNames: [sheetName],
    Sheets: {}
  }
  workbook.Sheets[sheetName] = sheet

  const wopts = {
    bookType: 'xlsx',
    bookSST: false,
    type: 'binary'
  }

  const wbout = XLSX.write(workbook, wopts)

  const blob = new Blob([stringToBuffer(wbout)], { type: 'application/octet-stream' })

  const elink = document.createElement('a')
  elink.href = URL.createObjectURL(blob)
  elink.download = name
  document.body.appendChild(elink)
  elink.click()
  URL.revokeObjectURL(elink.href) // 释放URL 对象
  document.body.removeChild(elink)
}
