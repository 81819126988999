import React, { Component } from 'react'
import { Row, Card, Button, Table, message, Radio } from 'antd'
import $lodash from 'lodash'
import PropTypes from 'prop-types'
import styles from './beforeload.module.scss'
import DownloadApi from '@/common/api/downloadApi'
import Api from '@/common/api/index'
import HandleModal from '@/components/import-setting/index'
import consts from '@/common/utils/consts'
import SUCCESS_ICON from '@/assets/images/icon/success_before_icon.jpg'
import TOTAL_ICON from '@/assets/images/icon/total_before_icon.jpg'
import FAIL_ICON from '@/assets/images/icon/fail_before_icon.jpg'
const { downloadfailList } = DownloadApi
const { SERIALWIDTH } = consts
const _letterArrMaps = ['客', '户', '导', '入', '中']
class beforeuoload extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    tableData: this.props.history.location.state,
    total: this.props.history.location.state,
    isSuccess: $lodash.filter(this.props.history.location.state, (item) => { return item.isSuccess === true }),
    noSuccess: $lodash.filter(this.props.history.location.state, (item) => { return item.isSuccess === false }),
    type: 0,
    handleModal: false,
    hoverKey: 'totalHover'
  }

  columns = [
    {
      title: '序号',
      key: 'customer-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '真实姓名',
      width: 150,
      key: 'customer-3',
      dataIndex: 'adminName'
    },
    {
      title: '手机号码',
      key: 'customer-4',
      width: 150,
      dataIndex: 'adminPhoneNumber'
    },
    {
      title: '公司名称',
      width: 240,
      key: 'customer-5',
      dataIndex: 'name'
    },
    {
      title: '法人姓名',
      width: 150,
      key: 'customer-6',
      dataIndex: 'accountName'
    },
    {
      title: '注册地址',
      width: 240,
      key: 'customer-7',
      dataIndex: 'address'
    },
    {
      title: '统一社会信用代码',
      width: 240,
      key: 'customer-8',
      dataIndex: 'taxId'
    },
    {
      title: '开户银行',
      width: 240,
      key: 'customer-9',
      dataIndex: 'bank'
    },
    {
      title: '开票电话',
      width: 240,
      key: 'customer-10',
      dataIndex: 'telephone'
    },
    {
      title: '银行账号',
      width: 240,
      key: 'customer-11',
      dataIndex: 'bankAccount'
    },
    {
      title: '是否成功',
      width: 150,
      key: 'customer-12',
      render: (text, record, index) => {
        return (
          <span className={record.isSuccess === true ? styles.divgreen : styles.divred}>{ record.isSuccess === true ? '是' : '否'}</span>
        )
      }
    },
    {
      title: '原因',
      width: 300,
      key: 'customer-13',
      dataIndex: 'failReason',
      render: (text, record, index) => {
        return (
          <span className={styles.divred}>{ record.failReason}</span>
        )
      }
    }
  ]

  componentDidMount () {
  }

  // 返回上一级
  goback = () => {
    this.props.history.push({ pathname: '/customer/customerList' })
  }

  // 导出失败详情
  uploadfile = () => {
    const { noSuccess } = this.state
    if (noSuccess.length === 0) {
      message.warning('无失败数据，不能导出失败详情')
    } else {
      downloadfailList({ templates: noSuccess })
    }
  }

  // 确认导入
  getTonext = () => {
    this.setState({
      handleModal: true
    }, () => {
      this.uploadinfoOk()
    })
  }

  // 导入
  uploadinfoOk = () => {
    const { total } = this.state
    const _arr = []
    total.forEach((item, index) => {
      _arr.push(Object.assign({}, item, { password: '111111' }))
    })

    Api.downloadList({ euEnterprises: _arr }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        setTimeout(() => {
          this.props.history.push({ pathname: '/customer/Uploadinfo', state: { total, data: data } })
        }, 3000)
      } else {
        message.error(res.data.message)
      }
    })
  }

  // 点击切换按钮
  handleChangeTabPane = (e) => {
    const key = e.target.value

    const { isSuccess, noSuccess, total } = this.state
    let data = []
    if (key === 'ALL') { data = total }
    if (key === 'SUCCESS') { data = isSuccess }
    if (key === 'FAIL') { data = noSuccess }
    this.setState({
      tableData: data
    })
  }

  handleMouseEnter = (key) => {
    this.setState({ hoverKey: key })
  }

  handleMouseLeave = (key) => {
    this.setState({ hoverKey: '' })
  }

  render () {
    const { tableData, handleModal = false, isSuccess, noSuccess, total, hoverKey } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }}>
          <Row className={styles.titlenum}>
              <div className={`${styles.flexbox} ${styles.total} ${hoverKey === 'totalHover' ? styles.backgoundHover : ''}`} onMouseEnter={() => this.handleMouseEnter('totalHover')} onMouseLeave={() => this.handleMouseLeave('totalHover')}>
                <img className={`${styles.iconBox}`} src={TOTAL_ICON} />
                <div className={`${styles.flexboxBody}`}>
                  <div style={{ fontSize: '28px', color: '#3E7BFA' }}>{total.length}</div>
                  <div style={{ fontWeight: '600', marginBottom: '4px' }}>总共</div>
                </div>
              </div>

              <div className={`${styles.flexbox} ${styles.success} ${hoverKey === 'successHover' ? styles.backgoundHover : ''}`} onMouseEnter={() => this.handleMouseEnter('successHover')} onMouseLeave={() => this.handleMouseLeave('successHover')}>
                <img className={`${styles.iconBox}`} src={SUCCESS_ICON} />
                <div className={`${styles.flexboxBody}`}>
                  <div style={{ fontSize: '28px', color: '#00B667' }}>{isSuccess.length}</div>
                  <div style={{ fontWeight: '600', marginBottom: '4px' }}>匹配成功</div>
                </div>
              </div>

              <div className={`${styles.flexbox} ${styles.fail} ${hoverKey === 'failHover' ? styles.backgoundHover : ''}`} onMouseEnter={() => this.handleMouseEnter('failHover')} onMouseLeave={() => this.handleMouseLeave('failHover')}>
                <img className={`${styles.iconBox}`} src={FAIL_ICON} />
                <div className={`${styles.flexboxBody}`}>
                  <div style={{ fontSize: '28px', color: '#BC1D1D' }}>{noSuccess.length}</div>
                  <div style={{ fontWeight: '600', marginBottom: '4px' }}>需要修正</div>
                </div>
              </div>
          </Row>
        </Card>

        <div style={{ marginTop: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <div className={styles.resultbtn}>
              <Radio.Group buttonStyle='solid' defaultValue="ALL" onChange={this.handleChangeTabPane}>
              <Radio.Button value="ALL">全部数据</Radio.Button>
              <Radio.Button value="SUCCESS">匹配成功</Radio.Button>
              <Radio.Button value="FAIL">需要修改</Radio.Button>
              </Radio.Group>
            </div>

            <div className={styles.opeatesbtn}>
              <Button style={{ margin: '0 10px' }} onClick={() => { this.goback() }}>取消匹配</Button>
              <Button onClick={() => { this.uploadfile() }} type='primary'>导出失败详情</Button>
              <Button style={{ marginLeft: '10px' }} type='primary' onClick={() => { this.getTonext() }}>导入数据</Button>
            </div>

            <Row style={{ marginTop: '20px' }}>
              <Table rowKey='euId'
                columns={this.columns}
                dataSource={tableData}
                scroll={{ x: 1500 }}
              />
            </Row>
          </Card>
        </div>

        {

          handleModal
            ? <HandleModal
              letterArr={_letterArrMaps}
            />
            : null
        }
      </>
    )
  }
}

export default beforeuoload
