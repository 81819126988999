import config from '@/common/utils/config'

const { host } = config

// 上传图片-不需要水印的图片
const uploadOrdinaryImageUrl = `${host}/api/image/uploadByOthers`

// 上传图片-需要水印的图片
const uploadNeedLogoImageUrl = `${host}/api/image/uploadByProduct`

// 图文识别-上传图片
const uploadIntelDiscern = host + '/api/image/text/parse'

// 库存导入
const uploadStockFile = host + '/api/excel/import/importCommodityStock '

// 客户预导入
const beforeuploadEndUser = host + '/api/guest/supplier/endUser/preImport'

// 客户导入
const uploadEndUser = host + '/api/guest/supplier/endUser/import'

// 保存客户编辑
const saveEditEnduser = host + '/api/guest/supplier/guest/euEdit'

// 保存新增客户
const saveAddEnduser = host + '/api/guest/supplier/guest/edAdd'

// 上传用户服务协议
const agreementUpload = host + '/api/mall/manage/save/userServiceAgreementManager'

// 客价BOM单导入
const bomUtilUpload = host + '/api/excel/import/priceBomUtil'

// 云供应价格导入
const supplyPriceUpload = host + '/api/excel/import/cloudSupplyPrice'

// 物料编号导入
const uploadMateria = host + '/api/excel/import/syncItemNo'

// 最低价格导入
const uploadDefaultPrice = host + '/api/excel/import/import/priceDefaultSku'

// 导入产品折扣到价格组
const uploadDIscountByPriceGroup = host + '/api/excel/import/priceGroupDiscount'

// 手工订单-上传产品
const uploadManualSearchSku = host + '/api/quick/order/excelImport'

// EXCEL上传匹配sheet
const uploadMatchExcelUrl = host + '/api/excel/import/getSheetNames'

// 订单管理-云供应订单- 上传订单附件
const uploadSupplyOrderEnclosure = host + '/api/cloud/supply/order/file/upload'

export default {
  uploadOrdinaryImageUrl,
  uploadNeedLogoImageUrl,
  uploadIntelDiscern,
  uploadStockFile,
  uploadEndUser,
  beforeuploadEndUser,

  saveEditEnduser,
  saveAddEnduser,

  agreementUpload,

  bomUtilUpload,
  supplyPriceUpload,
  uploadMateria,
  uploadDefaultPrice,

  uploadDIscountByPriceGroup,
  uploadManualSearchSku,

  uploadMatchExcelUrl,
  uploadSupplyOrderEnclosure
}
