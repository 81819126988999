import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Card, Table, InputNumber, Input } from 'antd'
import $lodash from 'lodash'

import consts from '@/common/utils/consts'
import styles from './index.module.scss'

const { TextArea } = Input
const { SERIALWIDTH } = consts

class SendProductTableForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    remarks: PropTypes.string,
    futures: PropTypes.bool, // 是否为期货
    isRedispatch: PropTypes.bool, // 是否为重新发货
    tableData: PropTypes.array,
    onChangeSendingNumber: PropTypes.func
  }

  state = {}

  columns = [
    {
      title: '序号',
      key: 'senddetail-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'senddetail-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'senddetail-2',
      ellipsis: true,
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'senddetail-3',
      ellipsis: true,
      dataIndex: 'itemNumber'
    },
    {
      title: '数量',
      key: 'senddetail-4',
      dataIndex: 'quantity'
    },
    {
      title: '已发货数量',
      key: 'senddetail-5',
      render: (text, record, index) => {
        const { isRedispatch = false } = this.props
        return <>
          {
            !isRedispatch ? <span>{ record.deliveryQuantity}</span> : '----'
          }
        </>
      }
    },
    {
      title: '已订货数量',
      key: 'senddetail-9',
      dataIndex: 'arrivalQuantity'
    },
    {
      title: '发货数量',
      key: 'senddetail-6',
      render: (text, record, index) => {
        const { isRedispatch = false } = this.props

        return (
          <>
            {
              !isRedispatch
                ? <InputNumber size='small' min={0}
                  value={record.sendNumber}
                  disabled={record.quantity - record.deliveryQuantity === 0}
                  onChange={(val) => this.props.onChangeSendingNumber({ value: val, index: index })}
                />
                : <span>{record.sendNumber}</span>
            }
          </>
        )
      }
    },
    {
      title: '单位',
      key: 'senddetail-7',
      dataIndex: 'unit'
    },
    {
      title: '货物金额',
      key: 'senddetail-8',
      render: (text, record, index) => (
        <>&yen;{ ((+record.sendNumber) * (+record.sellingPrice * 100) / 100).toFixed(2) }</>
      )
    }
  ]

  componentDidMount () {}

  render () {
    const { form, remarks = '', futures = false, tableData = [] } = this.props
    const { getFieldDecorator } = form
    const columns = futures ? this.columns : $lodash.filter(this.columns, (o) => { return o.title !== '已订货数量' })

    return (
      <div style={{ marginTop: '15px' }}>
        <Card title='发货明细' bodyStyle={{ padding: '15px' }}>
          <Table rowKey='commoditySkuId'
            dataSource={tableData}
            columns={columns}
            pagination={false}
            footer={() => (
              <Form>
                <div className={`${styles.formitemRow}`}>
                  <Form.Item style={{ marginBottom: '0px' }} label='拣货单备注'>
                    {
                      getFieldDecorator('remarks', {
                        initialValue: remarks
                      })(<TextArea placeholder='请输入内容， 不超过200字...' rows={4} maxLength={200} />)
                    }
                  </Form.Item>
                </div>
              </Form>
            )}
          />
        </Card>
      </div>
    )
  }
}

const SendProductTable = Form.create()(SendProductTableForm)

export default SendProductTable
