import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class StoreoutTable extends Component {
  static propTypes = {
    orderInfo: PropTypes.object,
    orderDetail: PropTypes.array
  }

  state = {}

  render () {
    const { orderInfo = {}, orderDetail = [] } = this.props

    return (
      <div id='outboundTableHtm' style={{ display: 'none' }}>
        <div style={{ marginBottom: '12px', height: '30px', lineHeight: '30px', fontSize: '16pt', fontWeight: 'bold', textAlign: 'center' }}>拣货单</div>
        <table width='100%' style={{ marginBottom: '15px', fontSize: '10pt' }}>
          <tbody>
            <tr>
              <td width='33.33%'>
                <p style={{ marginBottom: '6px' }}>出库单号：{orderInfo.distributorOrderNo}</p>
              </td>
              <td width='33.33%'>
                <p style={{ marginBottom: '6px' }}>出库日期：{orderInfo.time}</p>
              </td>
            </tr>
            <tr>
              <td colSpan='3'>
                <p>客户名称：{orderInfo.customerName}</p>
              </td>
            </tr>
          </tbody>
        </table>

        <div style={{ marginBottom: '30px' }}>
          {
            orderDetail && orderDetail.length > 0
              ? <table border='1' cellSpacing='0' cellPadding='2' width='100%'
                style={{ fontSize: '10pt', borderColor: '#333333', borderCollapse: 'collapse', textAlign: 'center' }}
              >
                <thead>
                  <tr>
                    <th width='5%' style={{ fontWeight: '500' }}>序号</th>
                    <th width='20%' style={{ fontWeight: '500' }}>品牌</th>
                    <th width='35%' style={{ fontWeight: '500' }}>型号/订货号</th>
                    <th width='10%' style={{ fontWeight: '500' }}>数量</th>
                    <th width='10%' style={{ fontWeight: '500' }}>货物金额</th>
                  </tr>
                </thead>

                <tfoot>
                  <tr>
                    <th colSpan={5} style={{ textAlign: 'left' }}>
                      <label style={{ fontSize: '10pt', fontWeight: '500' }}>备注：</label>
                      { orderInfo.remarks }
                    </th>
                  </tr>
                </tfoot>

                <tbody>
                  {
                    orderDetail.map((item, i) => (
                      <tr key={`pickProduct-${i}`}>
                        <td style={{ textAlign: 'center' }}>{i + 1}</td>

                        <td>{item.brandName}</td>

                        <td>{item.model}</td>

                        <td>{item.quantity}</td>

                        <td>￥{(+item.amount * 100 / 100).toFixed(2)}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              : null
          }

        </div>

        <table width='100%' style={{ marginTop: '1.5em' }}>
          <tbody>
            <tr>
              <td width='25%'><p style={{ fontSize: '10pt' }}>拣货员：</p></td>
              <td width='25%'><p style={{ fontSize: '10pt' }}>清点员：</p></td>
              <td width='25%'><p style={{ fontSize: '10pt' }}>复核员：</p></td>
              <td width='25%'><p style={{ fontSize: '10pt' }}>打包员：</p></td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
