import React, { Component } from 'react'
import { Timeline, Icon } from 'antd'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import EmptyTraces from './../EmptyTraces/index'

import { getCourierName } from '@/common/utils/mUtils'

export default class index extends Component {
  static propTypes = {
    logisticsTraces: PropTypes.array,
    rowDetail: PropTypes.object
  }

  render () {
    const { logisticsTraces = [], rowDetail = {} } = this.props
    return (
      <>
        <div className={styles.scrond_label}>
          <span>{getCourierName(rowDetail.courierCode)}</span>
          <span>IIASAAS提供服务</span>
        </div>

        <div className={styles.scrond_info}>
          {
            logisticsTraces && logisticsTraces.length > 0
              ? <Timeline className={styles.scron_timeline} >
                {
                  logisticsTraces.map((item, n) => (
                    <div style={{ position: 'relative' }} key={n}>
                      <p className={styles.scrond_info_time}>{item.acceptTime}</p>
                      <Timeline.Item className={`${n === logisticsTraces.length - 1 ? 'ant-timeline-item-last' : ''}`} dot={<Icon style={{ color: n === 0 ? '#F72109' : '', fontSize: n === 0 ? '20px' : '' }} type={n === 0 ? 'check-circle' : 'up-circle'} />}>{`${item.acceptStation}`}</Timeline.Item>
                    </div>
                  ))
                }
              </Timeline>
              : <EmptyTraces />
          }
        </div>
      </>
    )
  }
}
