import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Table, InputNumber, Input } from 'antd'
import $lodash from 'lodash'

import Api from '@/common/api/index'
import consts from '@/common/utils/consts'

const { SERIALWIDTH, MAX_NUMBER } = consts

// 可编辑单元行
const EditableContext = React.createContext()

class EditableCell extends Component {
  static propTypes = {
    form: PropTypes.object,
    editing: PropTypes.bool,
    dataIndex: PropTypes.string,
    title: PropTypes.string,
    inputType: PropTypes.string,
    record: PropTypes.object,
    index: PropTypes.number,
    children: PropTypes.array
  }

  renderCell = ({ getFieldDecorator }) => {
    const { form, editing, dataIndex, title, inputType, record, index, children, ...restProps } = this.props
    const _max = dataIndex === 'storeInQuantity' ? (+record.quantity) - (+record.arrivalQuantity) : MAX_NUMBER

    const inputNode = inputType === 'number' ? <InputNumber min={0} max={_max} disabled={dataIndex === 'storeInQuantity' && _max === 0} /> : <Input />

    return (
      <td {...restProps}>
        {editing
          ? (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(`${record.commoditySkuId}-${dataIndex}`, {
                initialValue: (+record.quantity) - (+record.arrivalQuantity),
                rules: [
                  { required: false, message: `请输入${title}!` }
                ]
              })(inputNode)}
            </Form.Item>
            )
          : (
              children
            )}
      </td>
    )
  }

  render () {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
  }
}

class EditableTable extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }

  state = {
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'futuresProductCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'futuresProductCol-1',
      width: 100,
      dataIndex: 'brandName',
      ellipsis: true
    },
    {
      title: '型号',
      key: 'futuresProductCol-2',
      width: 120,
      dataIndex: 'model',
      ellipsis: true
    },
    {
      title: '订货号',
      key: 'futuresProductCol-3',
      width: 120,
      dataIndex: 'itemNumber',
      ellipsis: true
    },
    {
      title: '订单数量',
      key: 'futuresProductCol-5',
      dataIndex: 'quantity',
      ellipsis: true
    },
    {
      title: '已订数量',
      key: 'futuresProductCol-6',
      dataIndex: 'arrivalQuantity',
      ellipsis: true
    },
    {
      title: '待订数量',
      key: 'futuresProductCol-7',
      dataIndex: 'receiptQuantity',
      render: (text, record, index) => (
        <>{ (+record.quantity) - (+record.arrivalQuantity) }</>
      )
    },
    {
      title: '入库数量',
      key: 'futuresProductCol-8',
      dataIndex: 'storeInQuantity',
      editable: true
    }
  ]

  componentDidMount () {
    const { detailInfo = {} } = this.props
    const params = {
      orderId: detailInfo.orderId,
      purchaseOrderId: detailInfo.orderType === 'ORDER_CLOUD_FUTURES' ? detailInfo.outboundOrderNo : null,
      pageNo: 1,
      pageSize: 999
    }
    this.getCloudfuturesOrderProductDetails(params)
  }

  // 获取云期货供应订单产品详情
  getCloudfuturesOrderProductDetails = async (params) => {
    const res = await Api.getCloudfuturesOrderProductDetails(params)
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || []
      })
    }
  }

  // 点击确定
  handleConfirm = () => {
    const { form, detailInfo = {}, onConfirm } = this.props

    form.validateFields((err, values) => {
      if (!err) {
        const putInQuantity = $lodash.map(values, (value, key) => {
          return {
            commoditySkuId: key.split('-')[0],
            quantity: +value
          }
        })

        const params = {
          orderId: detailInfo.orderId,
          purchaseOrderId: detailInfo.orderType === 'ORDER_CLOUD_FUTURES' ? detailInfo.outboundOrderNo : null,
          putInQuantity: $lodash.filter(putInQuantity, (o) => { return +o.quantity > 0 })
        }

        onConfirm(params)
      }
    })
  }

  render () {
    const { tableData = [] } = this.state

    const components = {
      body: {
        cell: EditableCell
      }
    }

    const columns = this.columns.map((col, i) => {
      if (!col.editable) { return col }
      return {
        ...col,
        onCell: record => ({
          record,
          dataIndex: col.dataIndex,
          inputType: 'number',
          title: col.title,
          index: i,
          editing: col.editable
        })
      }
    })

    return (
      <>
        <Modal title='期货入库' width={1000} bodyStyle={{ padding: '16px' }}
          visible
          maskClosable={false}
          onOk={() => this.handleConfirm()}
          onCancel={() => this.props.onCancel()}
        >
          <EditableContext.Provider value={this.props.form}>
            <Table rowKey='commoditySkuId'
              components={components}
              columns={columns}
              dataSource={tableData}
              pagination={false}
            />
          </EditableContext.Provider>
        </Modal>
      </>
    )
  }
}

const FuturesInModal = Form.create()(EditableTable)

export default FuturesInModal
