import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Row, message, Button, Modal } from 'antd'
import moment from 'moment'
import { getLodop } from '@/assets/plugin/LodopFuncs'

import SearchForm from '@/components/tool-form/index'
import ExpressMail from '@/components/expressMail/index'
import DetailModal from './detailModal'

import Api from '@/common/api'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'
import { renderAddressLabel, renderOrderTypeLabel, renderPickingOrderSendStatusLabel } from '@/common/utils/mUtils'
import styles from './goodsout.module.scss'

const { pageSizeOptions, pickingOrderSendStatusMaps, logisticsWayMaps } = filter
const { SERIALWIDTH } = consts
const { confirm, warning } = Modal

export default class StoreEnter extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '订单日期'
        },
        cptParams: {
          placeholder: '请选择...'
        }
      },
      {
        name: 'pickingStatus',
        formType: 'Select',
        itemParams: {
          label: '发货状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...pickingOrderSendStatusMaps
        ]
      }
    ],
    query: {},
    tableData: [],
    rowDetail: {}, // 当前选择的行数据
    curOrderType: 1,
    ewaybillInfo: {}, // 物流面单信息
    courierNumberList: [], // 运单号集合
    printerModal: false, // 打印机弹窗
    detailModal: false, // 明细弹窗
    printerList: [] // 打印机列表
  }

  columns = [
    {
      title: '序号',
      key: 'storelist-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '订单日期',
      key: 'storelist-1',
      render: (text, record, index) => (
        <>
          { record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----'}
        </>
      )
    },
    {
      title: '订单编号',
      key: 'storelist-2',
      dataIndex: 'orderId'
    },
    {
      title: '客户名称',
      key: 'storelist-3',
      ellipsis: true,
      dataIndex: 'customerName'
    },
    {
      title: '联系人',
      key: 'storelist-4',
      dataIndex: 'consignee'
    },
    {
      title: '手机号',
      key: 'storelist-5',
      dataIndex: 'consigneePhoneNumber'
    },
    {
      title: '收货地址',
      key: 'storelist-6',
      ellipsis: true,
      render: (text, record, index) => (
        <span>----</span>
      )
    },
    {
      title: '订单类型',
      key: 'storelist-7',
      dataIndex: 'orderType',
      render: (text, record, index) => (
        <>{renderOrderTypeLabel(record.orderType)}</>
      )
    },
    {
      title: '发货状态',
      key: 'storelist-8',
      width: 100,
      render: (text, record, index) => (
        <>{ renderPickingOrderSendStatusLabel(record.pickingStatus) }</>
      )
    },
    {
      title: '操作',
      key: 'storelist-9',
      width: 120,
      render: (text, record, index) => (
        <div>
          {
            record.deliverButtonStatus
              ? <Button type='link' size='small' onClick={() => this.handleJumpToSend(record)}>去发货</Button>
              : null
          }
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getStoreOutOrderList()
  }

  // 获取出库订单列表
  getStoreOutOrderList = () => {
    const { pages, query } = this.state

    const params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getStoreOutOrderList(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取发货批次列表
  getSendedBatchList = (expanded, record) => {
    if (!expanded) { return false }

    Api.getSendedBatchList({ pickingId: record.pickingId }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        const { tableData } = this.state
        const i = tableData.findIndex((item) => { return item.pickingId === record.pickingId })

        tableData[i].batchList = data || []

        this.setState({
          tableData
        })
      }
    })
  }

  // 模糊搜索客户
  getGuesteuUserLike = (val = '') => {
    const params = {
      pageNo: 1,
      pageSize: 999,
      searchName: val
    }
    Api.getGuesteuUserLike(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        const { searchData } = this.state
        searchData[0].options = data.list || []

        this.setState({
          searchData
        })
      }
    })
  }

  // 查询是否可取消快递
  getAllowCancelExpress = async (record) => {
    const res = await Api.getAllowCancelExpress({ pickingId: record.pickingId, batchNum: record.batchNum })
    const { code, data } = res
    if (+code === 10000) {
      if (!data) {
        warning({
          title: <><label className={`${styles.label}`}>揽件情况为</label><span>已揽件</span></>,
          content: <>很抱歉，此单快递不可以取消，请联系IIASaaS客服反馈问题</>
        })

        return false
      }

      confirm({
        title: <><label className={`${styles.label}`}>揽件情况为</label><span>未揽件</span></>,
        content: <>可以进行取消操作，是否要取消快递？</>,
        onOk: () => {
          this.saveCancelExpress({ pickingId: record.pickingId, batchNum: record.batchNum })
        }
      })
    }
  }

  // 取消物流订单
  saveCancelExpress = async (params) => {
    const res = await Api.saveCancelExpress(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      const { tableData = [] } = this.state
      const i = tableData.findIndex((row) => { return row.pickingId === params.pickingId })
      const n = tableData[i].batchList.findIndex((o) => { return o.batchNum === params.batchNum })

      tableData[i].batchList[n].logisticsOrderStatus = 'CANCEL'

      this.setState({
        tableData
      })
    } else {
      message.warning(res.message)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.times) {
      search.beginDate = search.times.length > 0 ? search.times[0].format('YYYY-MM-DD') : ''
      search.endDate = search.times.length > 0 ? search.times[1].format('YYYY-MM-DD') : ''

      delete search.times
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => this.getStoreOutOrderList())
  }

  // 查看订单明细
  handleShowDetailModal = (record) => {
    this.setState({
      rowDetail: record,
      detailModal: true
    })
  }

  // 确定修改拣货单备注
  setRowDetailRemarks = (outboundRemarks) => {
    const { tableData = [], rowDetail = {} } = this.state
    const i = tableData.findIndex((row) => { return row.pickingId === rowDetail.pickingId })
    const n = tableData[i].batchList.findIndex((o) => { return o.batchNum === rowDetail.batchNum })

    tableData[i].batchList[n].remarks = outboundRemarks

    this.setState({
      tableData: [
        ...tableData
      ],
      rowDetail: {
        ...rowDetail,
        remarks: outboundRemarks
      }
    })
  }

  // 点击去发货
  handleJumpToSend = (record) => {
    this.props.history.push({ pathname: '/warehouse/goodsSend', state: record })
  }

  // 打印
  printEWayBill = (name) => {
    const ewayHtml = document.getElementById('expressPrintArea').innerHTML
    const LODOP = getLodop()
    const { courierNumberList = [] } = this.state
    for (let i = 0; i < courierNumberList.length; i++) {
      LODOP.PRINT_INIT('初始化打印') // 打印初始化
      LODOP.SET_PRINTER_INDEXA(name)
      LODOP.SET_PRINT_PAGESIZE(0, '75mm', '130mm', 'CreateCustomPage')
      LODOP.SET_SHOW_MODE('BKIMG_WIDTH', '75mm')
      LODOP.SET_SHOW_MODE('BKIMG_HEIGHT', '130mm')
      LODOP.SET_PRINT_MODE('CUSTOM_TASK_NAME', 'EWaybill' + i)

      LODOP.ADD_PRINT_TABLE(0, 0, '75mm', '130mm', ewayHtml)
      LODOP.ADD_PRINT_BARCODE('61mm', '12mm', '50mm', '18mm', '128B', courierNumberList[i])

      LODOP.PRINT()
    }
  }

  // 点击重新发货
  handleLinkToStoreSend = (record) => {
    this.props.history.push({ pathname: '/warehouse/goodsSend', state: { ...record, type: 'redispatched' } })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      detailModal: false,
      printerModal: false,
      rowDetail: {}
    })
  }

  // 渲染展开子节点内容
  renderExpandRow = (row) => {
    const rowColumns = [
      {
        title: '发货批次',
        key: `expandRow-${row.pickingId}-0`,
        dataIndex: 'batchNum'
      },
      {
        title: '发货方式',
        key: `expandRow-${row.pickingId}-1`,
        render: (text, record, index) => {
          return (
            <>
              {
                ['ORDER_CLOUD_STOCK_PROCUREMENT', 'ORDER_CLOUD_FUTURES_PROCUREMENT'].includes(row.orderType) || record.logisticsOrderStatus !== 'CANCEL'
                  ? this.renderLogisticsWayLabel(record.logisticsWay)
                  : '----'
              }
            </>
          )
        }
      },
      {
        title: '快递公司',
        key: `expandRow-${row.pickingId}-2`,
        render: (text, record, index) => (
          <>
            {
              ['ORDER_CLOUD_STOCK_PROCUREMENT', 'ORDER_CLOUD_FUTURES_PROCUREMENT'].includes(row.orderType) || record.logisticsOrderStatus !== 'CANCEL'
                ? <>{ record.courierName && record.courierName !== '' ? record.courierName : '----' }</>
                : '----'
            }
          </>
        )
      },
      {
        title: '运单号',
        key: `expandRow-${row.pickingId}-3`,
        render: (text, record, index) => (
          <>
            {
              ['ORDER_CLOUD_STOCK_PROCUREMENT', 'ORDER_CLOUD_FUTURES_PROCUREMENT'].includes(row.orderType) || record.logisticsOrderStatus !== 'CANCEL'
                ? <>{ record.courierNumberList && record.courierNumberList.length > 0 ? record.courierNumberList.join(',') : '----' }</>
                : '----'
            }
          </>
        )
      },
      {
        title: '发货时间',
        key: `expandRow-${row.pickingId}-4`,
        render: (text, record, index) => (
          <>
            {
              ['ORDER_CLOUD_STOCK_PROCUREMENT', 'ORDER_CLOUD_FUTURES_PROCUREMENT'].includes(row.orderType) || record.logisticsOrderStatus !== 'CANCEL'
                ? <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
                : '----'
            }
          </>
        )
      },
      {
        title: '发货地址',
        key: `expandRow-${row.pickingId}-6`,
        ellipsis: true,
        render: (text, record, index) => (
          <>
            {
              ['ORDER_CLOUD_STOCK_PROCUREMENT', 'ORDER_CLOUD_FUTURES_PROCUREMENT'].includes(row.orderType) || record.logisticsOrderStatus !== 'CANCEL'
                ? <div title={<>{renderAddressLabel(record.province, record.city, record.area)}{record.address}</>}>
                { renderAddressLabel(record.province, record.city, record.area) }{record.address}
                </div>
                : '----'
            }
          </>
        )
      },
      {
        title: '操作',
        key: `expandRow-${row.pickingId}-5`,
        render: (text, record, index) => (
          <div>
            <Button type='link' size='small' onClick={() => this.handleShowDetailModal({ ...record, customerName: row.customerName, orderId: row.orderId, orderTime: row.orderTime, outboundOrderNo: row.outboundOrderNo })}>
              查看详情
            </Button>

            {
              !['ORDER_CLOUD_STOCK_PROCUREMENT', 'ORDER_CLOUD_FUTURES_PROCUREMENT'].includes(row.orderType) && record.logisticsOrderStatus === 'CREATE'
                ? <Button type='link' size='small' onClick={() => this.getAllowCancelExpress(record)}>取消快递</Button>
                : null
            }

            {
              !['ORDER_CLOUD_STOCK_PROCUREMENT', 'ORDER_CLOUD_FUTURES_PROCUREMENT'].includes(row.orderType) && record.logisticsOrderStatus === 'CANCEL'
                ? <Button type='link' size='small' onClick={() => this.handleLinkToStoreSend(record)}>重新发货</Button>
                : null
            }

          </div>
        )
      }
    ]

    return (
      <Row style={{ padding: '2px 5px' }}>
        <Table rowKey='batchNum'
          columns={rowColumns}
          dataSource={row.batchList}
          pagination={false}
        />
      </Row>
    )
  }

  // 渲染物流服务方式
  renderLogisticsWayLabel = (status) => {
    const obj = logisticsWayMaps.find(item => { return item.id === status })
    const label = obj !== undefined ? obj.name : null

    return label
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getStoreOutOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getStoreOutOrderList()
    })
  }

  render () {
    const { tableData, pages, searchData, rowDetail = {}, detailModal = false, ewaybillInfo = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bordered={false}>
          <Row style={{ marginBottom: '20px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Row>

          <Table rowKey='pickingId'
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
            onExpand={(expanded, record) => this.getSendedBatchList(expanded, record)}
            expandedRowRender={(record) => this.renderExpandRow(record)}
            scroll={{ x: 1200 }}
          />
        </Card>

        {
          detailModal
            ? <DetailModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
              onConfirmOutboundRemarks={(outboundRemarks) => this.setRowDetailRemarks(outboundRemarks)}
            />
            : null
        }

        <ExpressMail ewaybillInfo={ewaybillInfo} orderInfo={rowDetail} />

      </>
    )
  }
}
