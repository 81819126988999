import React, { Component } from 'react'
import { Card, Row, Descriptions, Button, Icon, message, Modal } from 'antd'

import Api from '@/common/api/index'
import { renderAddressLabel } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'
import AddressModal from './sendAddressModal'

import styles from './index.module.scss'

const { confirm } = Modal
const { ADDRESS_MAX_NUM } = consts

class SendaddressList extends Component {
  static propTypes = {

  }

  state = {
    type: 1, // 1-新增 2-编辑
    tableData: [], // 地址列表
    editModal: false,
    rowDetail: {}
  }

  componentDidMount () {
    this.getSendAddressList()
  }

  // 获取发货地址列表
  getSendAddressList = async () => {
    const res = await Api.getSendAddressList({})
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data || []
      })
    }
  }

  // 新增发货地址
  saveSendAddress = async (params) => {
    const res = await Api.saveSendAddress(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getSendAddressList()
    }
  }

  // 编辑发货地址
  saveEditSendAddress = async (params) => {
    const res = await Api.saveEditSendAddress(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getSendAddressList()
    }
  }

  // 删除发货地址
  deleteSendAddress = async (params) => {
    const res = await Api.deleteSendAddress(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getSendAddressList()
    }
  }

  // 点击保存
  handleConfirmSaveAddress = (params) => {
    const { type = 1 } = this.state
    if (type === 1) {
      this.saveSendAddress(params)
    } else {
      this.saveEditSendAddress(params)
    }
  }

  // 点击新增/编辑
  handleShowEditModal = (record = {}, type) => {
    this.setState({
      type: type,
      editModal: true,
      rowDetail: record
    })
  }

  // 点击删除地址
  handleDeleteAddress = (record) => {
    confirm({
      title: '确定删除该地址？',
      content: '',
      onOk: () => {
        this.deleteSendAddress({ id: record.id, deleteStatus: 'ENABLE' })
      }
    })
  }

  // 渲染DESC title
  renderCardItemTitle = (record) => {
    return (
      <>
        <span>{record.name}</span>
        {
          record.status === 'ENABLE' ? <span className={`${styles.defaultLabel}`}>默认地址</span> : null
        }
      </>
    )
  }

  closeModal = () => {
    this.setState({
      type: 1,
      editModal: false,
      rowDetail: {}
    })
  }

  render () {
    const { tableData = [], type = 1, rowDetail = {}, editModal = false } = this.state

    return (
      <>
        <Card title='发货地址' bordered={false}>
          <Row style={{ marginBottom: '15px' }}>
            <Button type='primary' disabled={tableData.length >= ADDRESS_MAX_NUM}
              onClick={() => this.handleShowEditModal({}, 1)}
            ><Icon type='plus' />新增地址
            </Button>

            <span style={{ marginLeft: '20px' }}>
              已创建{tableData.length}个发货地址，最多可创建{ADDRESS_MAX_NUM}个
            </span>
          </Row>

          {
            tableData.map((item, index) => (
              <div key={`address-${item.id}`} className={`${styles.addressRow} ${item.status === 'ENABLE' ? styles.ischeck : styles.nocheck}`}>
                <Descriptions title={this.renderCardItemTitle(item)}>
                  <Descriptions.Item label='发货人'>{item.name}</Descriptions.Item>
                  <Descriptions.Item label='联系方式'>{item.mobile}</Descriptions.Item>

                  <Descriptions.Item label='所在地区'>
                    {renderAddressLabel(item.province, item.city, item.county)}
                  </Descriptions.Item>
                  <Descriptions.Item label='详细地址'>{item.address}</Descriptions.Item>
                </Descriptions>
                <div className={`${styles.extraRow}`}>
                  <a className={`${styles.extraItem}`} onClick={() => this.handleShowEditModal(item, 2)}>编辑</a>

                  <a className={`${styles.extraItem}`} onClick={() => this.handleDeleteAddress(item)}>删除</a>
                </div>
              </div>
            ))
          }
        </Card>

        {
          editModal
            ? <AddressModal
              type={type}
              detailInfo={rowDetail}
              onConfirm={(params) => this.handleConfirmSaveAddress(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </>
    )
  }
}

export default SendaddressList
