import 'core-js/es'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

/* eslint-disable */
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import {ConfigProvider} from 'antd'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './common/utils/configureStore'
import zhCN from 'antd/lib/locale-provider/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import './index.css'

moment.locale('zh-cn')
const { persistor, store } = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider locale={zhCN}><App /></ConfigProvider>
    </PersistGate>
  </Provider>,

 document.getElementById('root')
)
