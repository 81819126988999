import SupplyProductList from '@/pages/cloud-supply/supplyProduct/index' // 云供应产品清单
import SupplyBrand from '@/pages/cloud-supply/supplyBrand/index' // 品牌云供应管理
import SupplyOrder from '@/pages/cloud-supply/supplyOrder/index' // 品牌云供应管理
import SaledOrder from '@/pages/cloud-supply/saledOrder/index' // 售后管理

export default [
  {
    path: '/product/supplyProdList',
    name: '产品云供应设置',
    component: SupplyProductList
  },
  {
    path: '/product/supplyCloudApply',
    name: '品牌云供应',
    component: SupplyBrand
  },
  {
    path: '/product/supplyOrderList',
    name: '云供应订单',
    component: SupplyOrder
  },
  {
    path: '/product/saledOrder',
    name: '售后管理',
    component: SaledOrder
  }
]
