import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Layout, Menu, Icon } from 'antd'

import Api from '@/common/api/index'

import styles from './index.module.scss'

const { Sider } = Layout
const { SubMenu } = Menu

class SiderMenu extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    menus: [],
    openKeys: [],
    selectedKeys: []
  }

  componentDidMount () {
    this.getMenus()
  }

  // 获取菜单信息
  getMenus = async () => {
    const res = await Api.getMenus({})
    const { code, data = [] } = res
    if (+code === 10000) {
      this.setState({
        menus: data || []
      })
    }
  }

  // 点击跳转
  handleLinkTo = (record) => {
    this.setState({
      selectedKeys: [record.path]
    }, () => {
      this.props.history.push({ pathname: record.path })
    })
  }

  // 展开菜单
  handleOpenChange = (keys) => {
    const { menus = [], openKeys = [] } = this.state
    const lastkeys = keys.find(key => { return openKeys.indexOf(key) === -1 })
    const i = menus.findIndex(item => { return item.code === lastkeys })

    this.setState({
      openKeys: i === -1 ? keys : (lastkeys ? [lastkeys] : [])
    })
  }

  render () {
    const { menus, openKeys, selectedKeys } = this.state

    return (
      <>
        <div style={{ width: '220px', overflow: 'hidden', flex: '0px 0px 220px', minWidth: '220px', maxWidth: '220px', minHeight: '100vh' }} />
        <Sider width='220' className={`${styles.leftSider}`}>
          <div className={`${styles.asideWrap}`}>
            <div className={`${styles.asideContent}`}>
              <Menu mode="inline"
                selectedKeys={selectedKeys}
                openKeys={openKeys}
                onOpenChange={(keys) => this.handleOpenChange(keys)}
              >
                {
                  menus.map((menu, i) => (
                  <SubMenu key={menu.code} title={<span><Icon type={menu.icon} /><span>{menu.menuName}</span></span>}>
                    {
                      menu.children && menu.children.map((item, n) => (
                        <Menu.Item key={item.path} onClick={() => this.handleLinkTo(item)}>
                          { item.menuName }
                        </Menu.Item>
                      ))
                    }
                  </SubMenu>
                  ))
                }
              </Menu>
            </div>
          </div>
        </Sider>
      </>
    )
  }
}

export default SiderMenu
