import AccountSettings from '@/pages/account/account-settings/' // 账户设置
import AccountInformation from '@/pages/account/account-information/' // 基本信息
import AccountPersonal from '@/pages/account/account-personal/' // 基本信息

export default [
  {
    path: '/account/settings',
    name: '账户设置',
    component: AccountSettings
  },
  {
    path: '/account/information',
    name: '基本信息',
    component: AccountInformation
  },
  {
    path: '/account/personal',
    name: '个人中心',
    component: AccountPersonal
  }
]
