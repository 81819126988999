import Ajax from '../utils/ajax'

export default {
  // 运供应发票- 获取云供应申请开票列表
  getSupplyOrderInvoiceApplyOpeningList (params) {
    return Ajax.post('/api/platformOrderInvoice/list/page/invoiceRecordHistory', params)
  },

  // 云供应发票- 获取开票详情
  getSupplyOrderInvoiceOpeningDetails (params) {
    return Ajax.post('/api/platformOrderInvoice/get/record/info', params)
  },

  // 云供应发票- 确认云供应开票
  saveSupplyOrderInvoiceOpened (params) {
    return Ajax.post('/api/platformOrderInvoice/confirmOpenInvoice', params)
  },

  // 云供应发票- 确认重新开票
  saveSupplyOrderInvoiceRepeatOpened (params) {
    return Ajax.post('/api/platformOrderInvoice/confirmOpenInvoiceReload', params)
  }
}
