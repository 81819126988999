import { downloadFileByGet, downloadFileByPost } from '../utils/downloadFile'

export default {
  // 客户列表导入模板下载
  downloadUploadEndUserTemplate (params) {
    return downloadFileByGet('/api/excel/download/endUserSupplierImportTemplate', params)
  },

  // 云供应产品-下载价格模板
  downloadCloudSupplyPriceTemplate (params) {
    return downloadFileByGet('/api/excel/download/justSupplyTemplate', params)
  },

  // 云供应产品-下载期货导入模板
  downloadCloudSupplyFuturesTemplate (params) {
    return downloadFileByGet('/api/excel/download/futures/justSupplyTemplate', params)
  },

  // 导出客户列表
  downloadEndUserList (params) {
    return downloadFileByPost('/api/excel/export/euUserInfo', params)
  },

  // 导出失败详情
  downloadfailList (params) {
    return downloadFileByPost('/api/guest/supplier/endUser/export', params)
  },

  // 导出IIASaaS的德邦快递服务月度账单
  downIIASaaSDepponExpressBillByMonth (params) {
    return downloadFileByGet('/api/excel/data/download/logistics/deppon/fee', params)
  },
  // 开票下载
  downloadInvoiceTpl (params) {
    return downloadFileByPost('/api/platformOrderInvoice/exportInvoiceInfo', params)
  },

  // 云供应发票-电子发票产品下载
  downloadProductsExcelBySupplyInvoiceElectronic (params) {
    return downloadFileByPost('/api/platformOrderInvoice/invoiceElectronicInfoExport', params)
  },

  // 云供应订单-导出云供应订单
  dowloadSupplyOrder (params) {
    return downloadFileByPost('/api/excel/data/download/cloudSupplyOrderInfo', params)
  },

  // 商户产品导入匹配结果导出
  downMatchResult (params) {
    return downloadFileByGet('/api/excel/export/product/import/result', params)
  },

  // 快递记录导出
  downloadExpressServiceRecords (params) {
    return downloadFileByPost('/api/warehouse/get/distributorPickingInfo/export', params)
  }

}
