import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button, Icon, message, Divider } from 'antd'
import moment from 'moment'

import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'
import Api from '@/common/api/index'
import { setStore } from '@/common/utils/mUtils'

import logoImage from '@/assets/images/login_logo.png'
import styles from './login.module.scss'

const FormItem = Form.Item

class LoginForm extends Component {
  static propTypes={
    form: PropTypes.object,
    history: PropTypes.object
  }

  componentDidMount () {
    window.addEventListener('keydown', this.handleEnterKey)
  }

  // 组件卸载时 关闭定时器
  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleEnterKey)
  }

  handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      this.handleLogin()
    }
  }

  handleLogin = (e) => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const jsencrypt = new JSEncrypt()
        const { password } = values
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
        values.password = jsencrypt.encrypt(password)

        this.login(values)
      }
    })
  }

  // 登录
  login = async (params) => {
    const res = await Api.login(params)
    const { code, data } = res
    if (+code === 10000) {
      setStore('token', data)
      this.props.history.push({ pathname: '/home' })
    } else {
      message.error(res.message)
    }
  }

  render () {
    const { getFieldDecorator } = this.props.form

    return (
      <>
        <div className={styles.main}>
          <div className={styles.formWrap}>
            <div className={`${styles.title}`}>IIASaaS供应平台</div>
            <Form>
              <FormItem>
                {
                  getFieldDecorator('username', {
                    rules: [
                      { required: true, message: '请输入用户名!' }
                    ]
                  })(
                    <Input placeholder='请输入用户名'
                      style={{ height: '44px' }}
                      prefix={<Icon type='user' style={{ color: 'rgba(0, 0, 0, 0.3)' }} />}
                    />
                  )
                }
              </FormItem>

              <FormItem>
                {
                  getFieldDecorator('password', {
                    rules: [
                      { required: true, message: '请输入密码!' }
                    ]
                  })(
                    <Input.Password
                      placeholder='请输入密码'
                      style={{ height: '44px' }}
                      prefix={<Icon type='lock' style={{ color: 'rgba(0, 0, 0, 0.3)' }} />}
                    />
                  )
                }
              </FormItem>

              <FormItem>
                <Button type='primary' onClick={(e) => { this.handleLogin(e) }} className={styles.btn}>登录</Button>
              </FormItem>
            </Form>
          </div>

          <div className={`${styles.logoWrap}`}>
            <img className={`${styles.pic}`} src={logoImage} />
            <div className={`${styles.subtitle}`}>供应平台</div>
            <Divider type="vertical" style={{ height: '36px', marginLeft: '24px', marginRight: '24px' }} />
            <p className={`${styles.descript}`}>Supply for Mining Platform</p>
          </div>
        </div>

        <div className={`${styles.footer}`}>
          CopyRight©IIASaaS&nbsp;2019-{moment().format('YYYY')}&nbsp;Corporation All Rights Reserved&nbsp;
          <a href='https://beian.miit.gov.cn/' target='_blank' rel='noopener noreferrer'>沪ICP备20011366号-691</a>
        </div>
      </>
    )
  }
}
const Login = Form.create()(LoginForm)
export default (Login)
