import React, { Component } from 'react'
import { Table } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter
const flowTypeMap = [
  { type: 'CASH_IN_BALANCE', label: '余额现金充入' },
  { type: 'CASH_ORDER_PAY', label: '现金支付' },
  { type: 'AFTER_SALE_REFUND', label: '售后退款' },
  { type: 'CHECK_RECHARGE', label: '承兑充值' },
  { type: 'CASH_RECHARGE', label: '现金充值' },
  { type: 'BALANCE_ORDER_PAY', label: '余额支付' },
  { type: 'CREDIT_ORDER_PAY', label: '信用支付' },
  { type: 'CASH_OUT', label: '余额提现' },
  { type: 'ORDER_CANCEL', label: '订单取消' }
]

export default class PlatcreditFlow extends Component {
  static propTypes = {

  }

  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }

  columns = [
    {
      title: '金额',
      key: 'pcreditFlow-1',
      render: (text, record, index) => (
        <div>
          {record.amountType === 'SUB' ? '-' : '+'}&yen;{(+record.amount * 100 / 100).toFixed(2)}
        </div>
      )
    },
    {
      title: '日期',
      key: 'pcreditFlow-2',
      render: (text, record, index) => (
        <div>
          {moment(record.createTime).format('YYYY-MM-DD HH:mm:ss')}
        </div>
      )
    },
    {
      title: '备注',
      key: 'pcreditFlow-3',
      dataIndex: 'remarks'
    }
  ]

  componentDidMount () {
    this.getPlatCreditFlow()
  }

  // 获取用户汇总
  getPlatCreditFlow = () => {
    const { pages } = this.state
    const params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getPlatCreditFlow(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  renderFlowTypeLabel = (status) => {
    const obj = flowTypeMap.find(item => { return item.type === status })
    const label = obj !== undefined ? obj.label : ''

    return label
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getPlatCreditFlow()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getPlatCreditFlow()
    })
  }

  render () {
    const { tableData = [], pages } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <Table rowKey='id'
        dataSource={tableData}
        columns={this.columns}
        pagination={pagination}
      />
    )
  }
}
