import React, { Component } from 'react'
import PropTypes from 'prop-types'
import $lodash from 'lodash'
import { Form, Row, Col, Button } from 'antd'

import HockFormComponent from './form-component.jsx'

import styles from './tool.module.scss'

class Forms extends Component {
  static defaultProps = {
    horizontal: {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 }
    }
  }

  handleSearch = e => {
    e.preventDefault()
    this.props.handleSubmit(this.props.form.getFieldsValue())
  }

  cleanForm = () => {
    this.props.form.resetFields()
    this.props.handleSubmit({})
  }

  renderForm = () => {
    const { getFieldDecorator, getFieldValue } = this.props.form
    const { dataSource } = this.props

    const len = dataSource.length
    const offsetN = len > 0 ? (4 - (len % 4) - 1) : 0

    return (
      <Form>
        <Row gutter={12}>
          {
            dataSource.map((item, i) => {
              let num = 0
              const ruleValid = []
              // 联动关系校验
              if (item.linkageRule) {
                item.linkageRule.forEach(t => {
                  if (getFieldValue(t.name) === t.value) {
                    num += 1
                  }
                })
              }
              // 是否满足所有联动关系校验，满足则显示组件
              if (num === $lodash.get(item, 'linkageRule', []).length) {
                // const addValid = Valids[item.validType]
                // // 是否存在自定义规则，存在则合并
                // if (addValid) {
                //   ruleValid = [...$lodash.get(item, 'rules.rules', []), addValid]
                // } else {
                //   ruleValid = [...$lodash.get(item, 'rules.rules', [])]
                // }
                return (
                  <Col span={6} key={`toolcol${i}`} className={`${styles.searchCol}`}>
                    <Form.Item style={{ marginBottom: '0px' }}
                      {...item.itemParams}
                    >
                      {getFieldDecorator(item.name, {
                        ...item.rules,
                        rules: ruleValid
                      })(
                        this.typeData[item.formType + 'User'](item)
                      )}
                    </Form.Item>
                  </Col>
                )
              }

              return null
            })
          }
          <Col span={6} offset={offsetN * 6} className={`${styles.searchOperation}`}>
            <Form.Item style={{ marginBottom: '0px' }}>
              <Button type='primary' style={{ marginLeft: '10px' }} onClick={(obj) => this.handleSearch(obj)}>搜索</Button>

              <Button style={{ marginLeft: '10px' }} onClick={this.cleanForm}>重置</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  render () {
    return <React.Fragment>{this.renderForm()}</React.Fragment>
  }
}

Forms.propTypes = {
  form: PropTypes.object,
  layout: PropTypes.string,
  dataSource: PropTypes.array,
  handleSubmit: PropTypes.func
}

export default Form.create()(HockFormComponent(Forms))
