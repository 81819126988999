import React, { Component } from 'react'
import PropTypes from 'prop-types'

const ewayPaymethodMaps = [
  { type: '', label: '' },
  { type: 'MONTHLY_STATEMENT', label: '月结' },
  { type: 'FREIGHT_COLLECT', label: '到付' }
]

export default class PrintLogistics extends Component {
  static propTypes = {
    orderInfo: PropTypes.object,
    ewaybillInfo: PropTypes.object
  }

  state = {}

  renderEwaybillPayTypeLabel = (status) => {
    const obj = ewayPaymethodMaps.find(item => { return item.type === status })
    const str = obj !== undefined ? obj.label : ''

    return str
  }

  render () {
    const { ewaybillInfo = {}, orderInfo = {} } = this.props

    return (
      <div id='printContext'
        style={{ marginTop: '15px', width: '630px', height: '665px', overflowY: 'auto', textAlign: 'center', display: 'none' }}
      >
        <div id='expressPrintArea'>
          <table style={{ width: '75mm', border: '1px solid #000000', borderCollapse: 'collapse', backgroundColor: '#fff', borderSpacing: '0px' }}>
            <tbody>
              <tr>
                <td colSpan={10} style={{ height: '10mm', padding: '0px', textAlign: 'right', borderBottom: '1px solid #000000', fontSize: '8pt' }}>
                  <span style={{ display: 'inline-block', padding: '0px 4px', marginRight: '10px' }}>
                    {this.renderEwaybillPayTypeLabel(ewaybillInfo.payType)}
                  </span>
                  <span style={{ display: 'inline-block', padding: '0px 4px' }}>标准快递</span>
                </td>
              </tr>

              <tr>
                <td colSpan={10} style={{ height: '10mm', fontSize: '10pt', borderBottom: '1px solid #000000' }}>
                  {ewaybillInfo.arrivedOrgSimpleName || ''}
                </td>
              </tr>

              <tr style={{ height: '8mm', fontSize: '10pt', fontFamily: '黑体' }}>
                <td colSpan={10} style={{ borderBottom: '1px solid #000000' }}>
                  <span style={{ display: 'inline-block', width: '36mm', padding: '0px 4px' }}>
                    {ewaybillInfo.receiverCounty}
                  </span>
                  <span style={{ display: 'inline-block', width: '36mm', padding: '0px 4px', textAlign: 'right' }}>1/{ewaybillInfo.totalNumber}</span>
                </td>
              </tr>

              <tr style={{ height: '20mm' }}>
                <td colSpan={2} style={{ padding: '0px 10px', borderBottom: '1px solid #000000', textAlign: 'center', fontSize: '10pt', fontFamily: '黑体' }}>收</td>
                <td colSpan={8} style={{ borderBottom: '1px solid #000000' }}>
                  <div style={{ lineHeight: 'normal', fontSize: '8pt' }}>{ewaybillInfo.receiverName}</div>
                  <div style={{ lineHeight: 'normal', fontSize: '8pt' }}>{ewaybillInfo.receiverPhone}</div>
                  <p style={{ marginBottom: '0px', lineHeight: 'normal', fontSize: '8pt' }}>
                    {ewaybillInfo.receiverProvince}{ewaybillInfo.receiverCity}{ewaybillInfo.receiverCounty}{ewaybillInfo.receiverAddress}
                  </p>
                </td>
              </tr>

              <tr>
                <td colSpan={2} style={{ padding: '0px 10px', borderBottom: '1px solid #000000', textAlign: 'center', fontSize: '10pt', fontFamily: '黑体', height: '10mm' }}>
                  寄
                </td>
                <td colSpan={8} style={{ borderBottom: '1px solid #000000', height: '10mm', position: 'relative', paddingRight: '60px' }}>
                  <span style={{ fontSize: '8pt', fontFamily: '黑体', lineHeight: '10mm' }}>
                    {ewaybillInfo.senderName}&nbsp;{ewaybillInfo.senderPhone}
                  </span>
                  <span style={{ position: 'absolute', top: '0px', right: '4px', bottom: '0px', width: '56px', lineHeight: '10mm', fontSize: '8pt', textAlign: 'right' }}>实名认证</span>
                </td>
              </tr>

              <tr style={{ height: '25mm' }}>
                <td colSpan={10} style={{ borderBottom: '1px solid #000000' }}>
                  <div style={{ height: '15mm' }} />
                  <p style={{ marginBottom: '0px', fontSize: '8pt', fontFamily: '黑体', textAlign: 'right', paddingRight: '4px' }}>
                    已验视
                  </p>
                </td>
              </tr>

              <tr style={{ height: '8mm' }}>
                <td colSpan={10} style={{ borderBottom: '1px solid #000000' }}>
                  <span style={{ display: 'inline-block', verticalAlign: 'middle', width: '36mm', fontSize: '8pt', fontFamily: '黑体' }}>
                    签收单返收:
                  </span>

                  <span style={{ display: 'inline-block', verticalAlign: 'middle', width: '36mm', fontSize: '8pt', fontFamily: '黑体' }}>
                    保价金额:
                  </span>
                </td>
              </tr>

              <tr>
                <td colSpan={10} style={{ fontSize: '8pt' }}>
                  <div style={{ height: '30mm', padding: '2px 4px' }}>
                    <div>
                      客户订单号: {orderInfo.orderId}
                    </div>
                    <div>
                      重量:
                    </div>
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
