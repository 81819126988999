import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { Spin } from 'antd'
import Login from './pages/login/login' // 登录页面
import { getStore } from './common/utils/mUtils'

import BasicLayout from './components/basicLayout'
// import Main from './pages/main/index' // 首页

import './App.css'

export default class App extends Component {
  render () {
    return (
      <>
        <Router>
          <Switch>
            <Route
              exact
              path='/'
              render={props => {
                const token = getStore('token')
                return token ? <Redirect to='/home' /> : <Login {...props} />
              }}
            />

            <Route path='/'
              render={props => {
                const token = getStore('token')
                return token ? <BasicLayout {...props}></BasicLayout> : <Redirect to='/'></Redirect>
              }}
            >
            </Route>
            <Route exact path='/login' component={Login}></Route>
          </Switch>
        </Router>

        <div className={'loading-wrapper'} id='loadingWrapper'>
          <Spin size='large' />
        </div>
      </>

    )
  }
}
