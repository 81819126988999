import Ajax from '../utils/ajax'

export default {
  // 云供应品牌列表
  getSupplyBrandList (params) {
    return Ajax.post('/api/cloud/supply/list/page/brandAuth', params)
  },

  // 云供应品牌授权申请
  supplyBrandAuthApply (params) {
    return Ajax.post('/api/cloud/supply/brandAuthApply', params)
  },

  // 获取云供应品牌授权日志
  getSupplyBrandAuthFlow (params) {
    return Ajax.post('/api/cloud/supply/list/page/brandAuthFlow', params)
  },

  // 获取可添加到云供应的产品列表
  getAllowAddToSupplyProductList (params) {
    return Ajax.post('/api/cloud/just/supply/manage/listPageAdd', params)
  },

  // 云供应商品列表
  getCloudProductList (params) {
    return Ajax.post('/api/cloud/just/supply/manage/listPage', params)
  },

  // 获取云供应期货产品列表
  getCloudFuturesProductList (params) {
    return Ajax.post('/api/cloud/just/supply/manage/listPage', params)
  },

  // 保存云供应上架
  saveCloudSupplySoldIn (params) {
    return Ajax.post('/api/cloud/just/supply/manage/enable', params)
  },

  // 云供应下架
  saveCloudSupplySoldOut (params) {
    return Ajax.post('/api/cloud/just/supply/manage/disable', params)
  },

  // 删除云供应产品
  saveCloudSupplyDelete (params) {
    return Ajax.post('/api/cloud/just/supply/manage/delete', params)
  },

  // 云供应设置日志列表
  getCloudSupplyLogList (params) {
    return Ajax.post('/api/cloud/supply/list/page/info/log', params)
  },

  // 保存添加产品到云供应
  saveAddProductToCloudSupply (params) {
    return Ajax.post('/api/cloud/just/supply/manage/add', params)
  },

  // 云供应订单列表
  getSupplyOrderList (params) {
    return Ajax.post('api/cloud/supply/order/list/page/spot', params)
  },

  // 云供应订单明细
  getSupplyOrderDetail (params) {
    return Ajax.post('/api/cloud/supply/order/list/page/spot/goods', params)
  },

  // 云供应订单确认收款
  confirmSupplyCollect (params) {
    return Ajax.post('/api/cloud/supply/order/receipt/confirm', params)
  },

  // 获取导出自定义项 --导出EU订单
  getOrderDownColList (params) {
    return Ajax.get('/api/excel/export/getExcelExportOrderFieldMap', { params })
  },

  // 获取合同下载链接
  getCloudSupplyContractDownFile (params) {
    return Ajax.post('/api/platform/contract/purchase/downloadFile', params)
  }
}
