import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Switch, InputNumber, Input, Row, Button } from 'antd'

import VerifyModal from '@/components/verify-modal/index'

import Api from '@/common/api/index'
import styles from './setCreditmodal.module.scss'

const FormItem = Form.Item

class SetcreditForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number,
    detailInfo: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }

  state = {
    enable: 'ENABLE_ON',
    btnDisabled: false,
    yztime: 59,
    adminPhone: '',
    verifyModal: false,
    rowDetail: {}
  }

  componentDidMount () {
    const { detailInfo } = this.props

    this.setState({
      enable: detailInfo.enable === 'ENABLE_UNKNOWN' ? 'ENABLE_ON' : detailInfo.enable
    }, () => {
      this.getAdminPhoneToDesensitization()
    })
  }

  // 组件卸载时 关闭定时器
  componentWillUnmount () {
    this.clearInterval()
  }

  // 获取手机号码
  getAdminPhoneToDesensitization = () => {
    Api.getAdminPhoneToDesensitization({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          adminPhone: data || ''
        })
      }
    })
  }

  // 发送短信验证码
  sendSmsCaptchaAndValidate = (params) => {
    Api.sendSmsCaptchaAndValidate(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.setInterval()
      }
    })
  }

  // 点击确定
  handleConfirm = () => {
    const { enable } = this.state
    const { type = 0 } = this.props
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (enable === 'ENABLE_ON') {
          values.sendSmsCaptchaEnum = +type === 99 ? 'SMS_CAPTCHA_TO_ADMIN' : 'EU_CREDIT_OPEN'
        } else {
          values.sendSmsCaptchaEnum = +type === 99 ? 'SMS_CAPTCHA_TO_ADMIN' : 'EU_CREDIT_CLOSE'
        }

        values.enable = enable
        this.props.onConfirm(values)
        this.closeModal()
      }
    })
  }

  // 点击选择启用、禁用
  handleChangeEnable = (e) => {
    this.setState({
      enable: e === true ? 'ENABLE_ON' : 'ENABLE_OFF'
    })
  }

  // 点击获取验证码
  handleGetMessCode = () => {
    const values = { }
    const { detailInfo, type = 0 } = this.props
    const { enable } = this.state

    if (+type !== 99) {
      values.endUserId = detailInfo.endUserId
    }

    if (enable === 'ENABLE_ON') {
      values.sendSmsCaptchaEnum = +type === 99 ? 'SMS_CAPTCHA_TO_ADMIN' : 'EU_CREDIT_OPEN'
    } else {
      values.sendSmsCaptchaEnum = +type === 99 ? 'SMS_CAPTCHA_TO_ADMIN' : 'EU_CREDIT_CLOSE'
    }

    values.enable = enable

    this.setState({
      verifyModal: true,
      rowDetail: values
    })
  }

  // 滑块验证码验证成功
  handleVerifySlideCheckSuccess = (params) => {
    this.setState({
      verifyModal: false
    }, () => {
      const { rowDetail = {} } = this.state
      this.sendSmsCaptchaAndValidate({ ...params, ...rowDetail, operation: '开启信用支付' })
    })
  }

  // 倒计时
  countDown = () => {
    const { yztime = 59 } = this.state
    if (yztime === 1) {
      this.clearInterval()
    } else {
      this.setState({ btnDisabled: true, yztime: yztime - 1 })
    }
  }

  // 开始定时器
  setInterval = () => {
    this.timer = setInterval(this.countDown, 1000)
  }

  // 清除定时器
  clearInterval = () => {
    clearInterval(this.timer)
    this.setState({
      btnDisabled: false,
      yztime: 59
    })
  }

  renderModalFooterInner = () => {
    const arr = [
      <Button onClick={this.props.onCancel} key='creditCancel'>取消</Button>,
      <Button type='primary' key='creditConfirm'
        onClick={this.handleConfirm}
      >确定</Button>
    ]

    return arr
  }

  closeModal = () => {
    this.setState({
      verifyModal: false,
      rowDetail: {}
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { detailInfo } = this.props
    const { enable, verifyModal = false, adminPhone, btnDisabled, yztime } = this.state

    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 }
    }

    return (
      <div>
        <Modal title='设置信用支付'
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          footer={this.renderModalFooterInner()}
        >
          <Form className={styles.from}>
            <FormItem {...formItemLayout} label='启用信用支付' colon={false}>
                {
                  getFieldDecorator('enable', {
                    initialValue: detailInfo.enable === 'ENABLE_ON',
                    valuePropName: 'checked',
                    rules: [
                      { required: false, message: '请选择' }
                    ]
                  })(
                    <Switch className={styles.Switch} onChange={(checked) => { this.handleChangeEnable(checked) }} checkedChildren='是' unCheckedChildren='否' />
                  )
                }
              </FormItem>

            {
              enable === 'ENABLE_ON'
                ? <Row>
                  <FormItem label='信用额度' colon={false}>
                    {
                      getFieldDecorator('amount', {
                        initialValue: detailInfo.maxAmount || 0,
                        rules: [
                          { required: true, message: '该项必填' }
                        ]
                      })(
                        <InputNumber style={{ width: '100%' }}
                          placeholder='请输入...'
                        />
                      )
                    }

                  </FormItem>
                  <FormItem label='账期' colon={false}>
                    {
                      getFieldDecorator('period', {
                        initialValue: detailInfo.period || 0,
                        rules: [
                          { required: true, message: '该项必填' }
                        ]
                      })(
                        <Input placeholder='请输入...' autoComplete='off' suffix='天' />
                      )
                    }
                  </FormItem>
                  <div>
                    <span>绑定手机号为:</span>
                    <span>{adminPhone || '----'}</span>
                    <span className={styles.sendcode}>
                      {
                        !btnDisabled ? <a type="primary" onClick={this.handleGetMessCode}>获取验证码</a> : `${yztime} s 后重新发送`
                      }
                    </span>
                  </div>
                     <FormItem>
                   {
                      getFieldDecorator('code', {
                        initialValue: '',
                        rules: [
                          { required: true, message: '验证码必填' }
                        ]
                      })(<Input />)
                    }
                   </FormItem>
                </Row>
                : null
            }

          </Form>
        </Modal>

        {
          verifyModal
            ? <VerifyModal
              onConfirm={(params) => this.handleVerifySlideCheckSuccess(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </div>
    )
  }
}

const SetcreditModal = Form.create()(SetcreditForm)

export default SetcreditModal
