import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Table, Button, Descriptions } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import { renderOrderTypeLabel } from '@/common/utils/mUtils'

class OccupyModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }

  state = {
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'occupy-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '订单编号',
      key: 'occupy-1',
      dataIndex: 'orderId',
      ellipsis: true
    },
    {
      title: '订单时间',
      key: 'occupy-2',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '订单类型',
      key: 'occupy-3',
      dataIndex: 'orderType',
      render: (text, record, index) => (
        <>{ renderOrderTypeLabel(record.orderType) }</>
      )
    },
    {
      title: '占用库存',
      key: 'occupy-4',
      dataIndex: 'occupyQuantity'
    }
  ]

  componentDidMount () {
    this.getStockOccupyDetail()
  }

  // 查询订单占用库存
  getStockOccupyDetail = () => {
    const { detailInfo = {} } = this.props
    Api.getStockOccupyDetail({ commoditySkuId: detailInfo.commoditySkuId }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data || []
        })
      }
    })
  }

  render () {
    const { detailInfo = {} } = this.props
    const { tableData = [] } = this.state

    return (
      <>
        <Modal title='订单库存明细' width={800} bodyStyle={{ padding: '15px' }}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          footer={[
            <Button key={'btn-0'} type='primary'
              onClick={() => this.props.onCancel()}>关闭</Button>
          ]}
        >
          <Row>
            <Descriptions>
              <Descriptions.Item label='品牌'>{detailInfo.brandName}</Descriptions.Item>
              <Descriptions.Item label='型号'><span dangerouslySetInnerHTML={{ __html: detailInfo.model }} /></Descriptions.Item>
              <Descriptions.Item label='订货号'><span dangerouslySetInnerHTML={{ __html: detailInfo.itemNumber }} /></Descriptions.Item>
              <Descriptions.Item label='订单库存'>{detailInfo.orderStock}</Descriptions.Item>
            </Descriptions>
          </Row>
          <Row>
            <Table rowKey='orderId'
              dataSource={tableData}
              columns={this.columns}
              pagination={false}
            />
          </Row>
        </Modal>
      </>
    )
  }
}

export default OccupyModal
