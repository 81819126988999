import React, { Component } from 'react'
import { Row, Col, Form, Input, Button, message } from 'antd'
import PropTypes from 'prop-types'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'
import { withRouter } from 'react-router-dom'

import VerifyModal from '@/components/verify-modal/index'
import { Phonenum, removeStore } from '@/common/utils/mUtils'
import filter from '@/common/utils/filter'
import Api from '@/common/api/index'

import styles from './index.module.scss'

const { passReg } = filter
const FormItem = Form.Item

class SetAccountForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    history: PropTypes.object
  }

  state={
    faly: 1,
    rowDetail: {},
    btnDisabled: false,
    yztime: 59,
    verifyModal: false
  }

  componentDidMount () {
    this.getUserInfo()
  }

  // 组件卸载时 关闭定时器
  componentWillUnmount () {
    this.clearInterval()
  }

   // 获取用户信息
   getUserInfo = () => {
     Api.getLoginUserInfo({}).then(res => {
       const { code, data } = res
       if (+code === 10000) {
         this.setState({
           rowDetail: data

         })
       }
     })
   }

  // 点击提交
  handleSumit=() => {
    this.props.form.validateFields((err, values) => {
      const { rowDetail } = this.state
      if (!err) {
        if (values.confirmPassword !== values.password) {
          return message.warning('两次输入密码不一致！')
        } else {
          const { password } = values
          const jsencrypt = new JSEncrypt()
          jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
          values.newPassword = jsencrypt.encrypt(password)
          values.phoneNumber = rowDetail.loginUserPhone
          this.UpdatePassword(values)
        }
      }
    })
  }

  // 修改密码
  UpdatePassword = (params) => {
    Api.UpdatePassword(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('保存成功')
        removeStore('token')
        this.props.history.push({ pathname: '/login' })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 点击修改密码
  handlePassword=() => {
    this.setState({
      faly: 2
    })
  }

  // 点击发送验证码
  handleSendCode = () => {
    this.setState({
      verifyModal: true
    })
    this.setInterval()
  }

  // 开始定时器
  setInterval = () => {
    this.timer = setInterval(this.countDown, 1000)
  }

  // 倒计时
  countDown = () => {
    const { yztime = 59 } = this.state
    if (yztime === 1) {
      this.clearInterval()
    } else {
      this.setState({ btnDisabled: true, yztime: yztime - 1 })
    }
  }

  // 清除定时器
  clearInterval = () => {
    clearInterval(this.timer)
    this.setState({
      btnDisabled: false,
      yztime: 59
    })
  }

  // 滑块验证码验证成功
  handleVerifySlideCheckSuccess = (params) => {
    this.setState({
      verifyModal: false
    }, () => {
      const { rowDetail = {} } = this.state
      this.sendSmsCaptchaAndValidate({ ...params, ...rowDetail, sendSmsCaptchaEnum: 'SMS_CAPTCHA_TO_ADMIN' })
    })
  }

  // 发送短信验证码
  sendSmsCaptchaAndValidate = (params) => {
    Api.sendSmsCaptchaAndValidate(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.setInterval()
      }
    })
  }

 closeModal = () => {
   this.setState({
     msgModal: false,
     verifyModal: false
   })
   this.clearInterval()
 }

 render () {
   const { getFieldDecorator } = this.props.form
   const { faly, rowDetail = {}, btnDisabled = 59, yztime, verifyModal = false } = this.state
   return (
      <>
         <div><h1>账户设置</h1></div>
        {
         faly === 1
           ? <>
              <div className={styles.maindiv}>
                 <div className={styles.dectxt}>登录名</div>
                 <div className={styles.txt}>{rowDetail.username}</div>
              </div>
              <div className={styles.maindiv}>
                 <div className={styles.dectxt}>已绑定手机号</div>
                 <div className={styles.txt}>{Phonenum(rowDetail.loginUserPhone + '') || '----'}</div>
              </div>
              <div className={styles.maindiv}>
                 <div className={styles.dectxt}>账户密码</div>
                 <div className={`${styles.txt} ${styles.decaLink}`}>
                   <div>******</div>
                   <div onClick={() => { this.handlePassword() }} className={styles.ClickLink}>修改密码</div>
                 </div>
              </div>
           </>
           : <Col>
              <Row>
                 <Form className={styles.form}>
                     <div>
                       <span>绑定手机号为:</span>
                       <span>{Phonenum(rowDetail.loginUserPhone + '') || '----'}</span>
                       <span className={styles.sendcode}> {
                        !btnDisabled ? <a type="primary" onClick={this.handleSendCode}>发送验证码</a> : `${yztime} s 后重新发送`
                      }</span>
                     </div>

                   <FormItem label='验证码为' >
                   {
                      getFieldDecorator('randomCode', {
                        initialValue: '',
                        rules: [
                          { required: true, message: '验证码必填' }
                        ]
                      })(<Input />)
                    }
                   </FormItem>
                   <FormItem label='输入新密码'>

                   {
                      getFieldDecorator('password', {
                        initialValue: '',
                        rules: [
                          { required: true, message: '密码必填' },
                          {
                            validator (rule, value, callback) {
                              if (value && !passReg.test(value)) {
                                /* eslint-disable */
                                  callback('登录密码格式不正确')
                                /* eslint-enable */
                              } else {
                                callback()
                              }
                            }
                          }
                        ]
                      })(<Input.Password placeholder='密码长度为6-20位，英文字母或数字' autoComplete='off' />)
                    }
                   </FormItem>
                   <FormItem label='确认新密码'>
                   {
                      getFieldDecorator('confirmPassword', {
                        initialValue: '',
                        rules: [
                          { required: true, message: '密码必填' }
                        ]
                      })(<Input.Password placeholder='请确认密码' autoComplete='off'/>)
                    }
                   </FormItem>

                 </Form>
              </Row>
               <Row className={styles.btn }>
                      <Button type='primary' onClick={() => { this.handleSumit() }}>保存</Button>
               </Row>
            </Col>
         }
           {
          verifyModal
            ? <VerifyModal
              onConfirm={(params) => this.handleVerifySlideCheckSuccess(params)}
              onCancel={this.closeModal}
            />
            : null
        }
        </>

   )
 }
}

const SetAccount = Form.create()(SetAccountForm)
export default withRouter(SetAccount)
