import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Table, Row, Button, Upload, message, Tooltip, InputNumber, Popconfirm, Icon } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'
import BatchSearchMenu from '@/components/batchSearchMenu/index'
import OccupyModal from '../component/occupyModal/index'
import UploadSheetModal from '@/components/uploadSheetModal/index'

import Api from '@/common/api'
import UploadApi from '@/common/api/uploadApi'
import { setShowLoading } from '@/reducers/action'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'
import { getStore } from '@/common/utils/mUtils'

const { uploadMatchExcelUrl } = UploadApi
const { pageSizeOptions, importConfig } = filter
const { SERIALWIDTH } = consts
const totalStockSyncTypeLabels = [
  { id: 'SELF_SINGLE_SYNC', name: '单个更新' },
  { id: 'SELF_FILE_SYNC', name: '导入更新' },
  { id: 'SYSTEM_SYNC', name: '系统同步' },
  { id: 'FUTURES_STOCK_IMPORT', name: '期货导入' }
]

class GoodsIn extends Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func
  }

  state = {
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showLabel']
      },
      {
        name: 'keyword',
        formType: 'Input',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入型号或订货号',
          autoComplete: 'off'
        }
      },
      {
        name: 'orderStock',
        formType: 'Select',
        itemParams: {
          label: '是否存在订单库存'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'ENABLE', name: '是' },
          { id: 'DISABLE', name: '否' }
        ]
      },
      {
        name: 'totalStockSyncType',
        formType: 'Select',
        itemParams: {
          label: '数据传输类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...totalStockSyncTypeLabels
        ]
      },
      {
        name: 'modifyTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '更新时间'
        },
        cptParams: {

        }
      }
    ],
    modaltype: 1,
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    rowDetail: {}, // 当前选择的行数据
    uploadModal: false,
    handleModal: false,
    occupyModal: false, // 占用库存明细弹窗
    editingKey: '',
    batchKey: '',
    batchModal: false
  }

  columns = [
    {
      title: '序号',
      key: 'storeIn-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'storeIn-1',
      width: 120,
      dataIndex: 'brandName',
      ellipsis: true
    },
    {
      title: '系列',
      key: 'storeIn-2',
      width: 120,
      dataIndex: 'series',
      ellipsis: true
    },
    {
      title: '型号',
      key: 'storeIn-3',
      width: 160,
      dataIndex: 'model',
      ellipsis: true,
      render: (text, record, index) => (
        <span dangerouslySetInnerHTML={{ __html: record.model }} />
      )
    },
    {
      title: '订货号',
      key: 'storeIn-4',
      width: 130,
      dataIndex: 'itemNumber',
      ellipsis: true,
      render: (text, record, index) => (
        <span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
      )
    },
    {
      title: '产品名称',
      key: 'storeIn-5',
      width: 180,
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '总库存',
      key: 'storeIn-6',
      render: (text, record, index) => {
        const editable = this.isEditing(record)
        return (
          <div>
            {
              editable
                ? <InputNumber size='small'
                    onChange={(value) => this.onChangeTotalStock(value, index)}
                  />
                : <>
                  <span>{record.totalStock}</span>
                </>
            }
          </div>
        )
      }
    },
    {
      title: '销售库存',
      key: 'storeIn-7',
      width: 130,
      dataIndex: 'stock'
    },
    {
      title: '订单库存',
      key: 'storeIn-8',
      width: 130,
      render: (text, record, index) => (
        <div>
          {
            +record.orderStock > 0
              ? <Tooltip placement='top' title={'点击可查看订单库存明细'}>
                <a style={{ textDecoration: 'underline' }} onClick={() => this.handleShowOccupyDetailModal(record)}>
                  {+record.orderStock}
                </a>
              </Tooltip>
              : <>{+record.orderStock}</>
          }
        </div>
      )
    },
    {
      title: '数据传输类型',
      key: 'storeIn-9',
      dataIndex: 'totalStockSyncType',
      render: (text, record, index) => (
        <>{this.renderTotalStockSyncTypeLabel(record.totalStockSyncType)}</>
      )
    },
    {
      title: '更新时间',
      key: 'storeIn-10',
      dataIndex: 'modifyTime',
      render: (text, record, index) => (
        <>
          { record.modifyTime ? moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '操作',
      key: 'storeIn-11',
      width: 140,
      render: (text, record, index) => {
        const { editingKey } = this.state
        const editable = this.isEditing(record)

        return editable
          ? (
          <>
            <Button type='link' size='small' onClick={() => this.handleSave(record)}>保存</Button>

            <Popconfirm title='确定放弃编辑?' onConfirm={() => this.handleCancelEdit(index)}>
              <Button type='link' size='small' style={{ marginLeft: '8px' }}>取消</Button>
            </Popconfirm>
          </>
            )
          : (
            <Button type='link' size='small' disabled={editingKey !== ''} onClick={() => this.handleEdit(record.commoditySkuId)}>
              编辑库存
            </Button>
            )
      }
    }
  ]

  componentDidMount () {
    this.getBrandList({ brandListType: 'CLOUD_JUST_SUPPLY_BRAND' })
    this.getStoreInProductList()
  }

  // 获取品牌
  getBrandList = async (params) => {
    const res = await Api.getBrandList(params)
    const { code, data } = res

    if (+code === 10000) {
      const { searchData } = this.state

      if (data && data.length > 0) {
        data.forEach(item => {
          item.showLabel = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[0].options = $lodash.concat([{ brandId: 0, showLabel: '全部' }], data)
      }

      this.setState({
        searchData
      })
    }
  }

  // 获取产品列表
  getStoreInProductList = () => {
    const { pages, query } = this.state

    const params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getStoreInProductList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        data.list && data.list.forEach((item) => {
          item.copyTotalStock = item.totalStock || 0
        })

        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 单个更新总库存
  updateTotalStockInSingle = async (params) => {
    const res = await Api.updateTotalStockInSingle(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.setState({
        editingKey: ''
      }, () => this.getStoreInProductList())
    } else {
      message.error(res.message)
    }
  }

  // 上传EXCEL智能解析
  saveUploadExcelMatchSmartparse = async (params) => {
    const res = await Api.saveUploadExcelMatchSmartparse(params)
    const { code, data } = res
    if (+code === 10000) {
      this.closeModal()
      this.changeUploadSuccess(data)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    const { modifyTimes } = search

    if (modifyTimes) {
      search.beginDate = modifyTimes.length === 2 ? modifyTimes[0].format('YYYY-MM-DD') : undefined
      search.endDate = modifyTimes.length === 2 ? modifyTimes[1].format('YYYY-MM-DD') : undefined
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: {
        ...search,
        brandIdList: +search.brandId > 0 ? [search.brandId] : null
      }
    }, () => {
      this.getStoreInProductList()
    })
  }

  // 导入库存
  onChangeUploadFile = (info) => {
    this.props.dispatch(setShowLoading({ loading: true }))

    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        setTimeout(() => {
          this.props.dispatch(setShowLoading({ loading: false }))
          this.setState({
            uploadSheetDetails: {
              name: data.tableName || '',
              list: data.sheetNames || [],
              file: info.file.originFileObj
            },
            uploadSheetModal: true
          })
        }, 2000)
      } else {
        this.props.dispatch(setShowLoading({ loading: false }))
        message.error(info.file.response.message || `${info.file.name}上传失败`)
      }
    } else if (info.file.status === 'error') {
      this.props.dispatch(setShowLoading({ loading: false }))
      message.error(`${info.file.name}上传失败`)
    }
  }

  // 点击确定选择导入EXCEL的sheet
  handleConfirmUploadSheet = (values) => {
    const formData = new FormData()

    $lodash.forEach(values, (value, key) => {
      formData.append(key, value)
    })

    this.saveUploadExcelMatchSmartparse(formData)
  }

  // 监听上传成功
  changeUploadSuccess = (params) => {
    const config = {
      pageSourch: 'storeInSetting',
      sheetSourch: importConfig.storeInSetting.sheetSourch
    }

    this.props.history.push({ pathname: '/matchs/sheetsConfirm', state: { config: config, data: { importId: params } } })
  }

  // 点击查看失败详情
  showFailDetailModal = () => {
    this.setState({
      uploadModal: true
    })
  }

  // 点击查看订单占用明细弹窗
  handleShowOccupyDetailModal = (record) => {
    this.setState({
      rowDetail: record,
      occupyModal: true
    })
  }

  // 点击编辑库存
  handleEdit = (key) => {
    this.setState({
      editingKey: key
    })
  }

  // 监听库存输入
  onChangeTotalStock = (value, i) => {
    const { tableData } = this.state

    if (i > -1) {
      tableData[i].totalStock = value
    }

    this.setState({
      tableData: tableData
    })
  }

  // 点击保存库存输入
  handleSave = (record) => {
    this.updateTotalStockInSingle({ commoditySkuId: record.commoditySkuId, totalStock: record.totalStock })
  }

  // 取消编辑
  handleCancelEdit = (i) => {
    const { tableData } = this.state

    tableData[i].totalStock = tableData[i].copyTotalStock

    this.setState({
      tableData,
      editingKey: ''
    })
  }

  // 渲染总库存标签
  renderTotalStockSyncTypeLabel = (status) => {
    const obj = totalStockSyncTypeLabels.find(item => { return item.id === status })
    const label = obj !== undefined ? obj.name : null

    return label
  }

  // 编辑中
  isEditing = (record) => record.commoditySkuId === this.state.editingKey

  closeModal = () => {
    this.setState({
      uploadModal: false,
      handleModal: false,
      occupyModal: false,
      uploadSheetModal: false,
      uploadSheetDetails: {},
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getStoreInProductList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getStoreInProductList()
    })
  }

  render () {
    const { searchData, tableData, pages, rowDetail = {}, occupyModal = false, uploadSheetModal = false, uploadSheetDetails = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bordered={false}>
          <Row style={{ marginBottom: '20px' }}>
            <Row style={{ display: 'inline-block', width: '120px', lineHeight: '40px', verticalAlign: 'top' }}>
              <BatchSearchMenu
                onConfirm={(values) => this.handleSearch(values)}
              />
            </Row>
            <Row style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>
              <SearchForm {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
          </Row>

          <Row style={{ marginBottom: '15px' }}>
            <Upload name='file' showUploadList={false}
              headers={{
                Token: getStore('token')
              }}
              accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              action={uploadMatchExcelUrl}
              onChange={this.onChangeUploadFile}
            >
              <Button type='primary'><Icon type="upload" />库存导入</Button>
            </Upload>
          </Row>

          <Row>
            <Table rowKey='commoditySkuId'
              columns={this.columns}
              dataSource={tableData}
              pagination={pagination}
              scroll={{ x: 1300 }}
            />
          </Row>
        </Card>

        {
          occupyModal
            ? <OccupyModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {
          uploadSheetModal
            ? <UploadSheetModal
              name={uploadSheetDetails.name}
              list={uploadSheetDetails.list}
              file={uploadSheetDetails.file}
              onConfirm={(values) => this.handleConfirmUploadSheet(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

export default connect()(GoodsIn)
