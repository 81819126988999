import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Badge, message, Button } from 'antd'
import moment from 'moment'

import SetcreditModal from './setCreditModal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter
const creditMap = [
  { type: 'ENABLE_UNKNOWN', label: '未开通', status: 'warning' },
  { type: 'ENABLE_ON', label: '启用中', status: 'success' },
  { type: 'ENABLE_OFF', label: '禁用中', status: 'error' }
]

export default class PlatCredit extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    setcreditModal: false,
    rechargeModal: false, // 余额充值弹窗
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {},
    rowDetail: {}
  }

  columns = [
    {
      title: '公司名称',
      key: 'plat-0',
      dataIndex: 'customerName'
    },
    {
      title: '信用支付',
      key: 'plat-1',
      render: (text, record, index) => (
        <>
          <Badge status={this.renderCreditBadge(record.enable)} />
          <span>{this.renderCreditStatusLabel(record.enable)}</span>
        </>
      )
    },
    {
      title: '信用额度',
      key: 'plat-2',
      render: (text, record, index) => (
        <>{record.usedAmount}/{record.maxAmount}</>
      )
    },
    {
      title: '账期时长(天)',
      key: 'plat-3',
      render: (text, record, index) => (
        <span>{record.period}</span>
      )
    },
    {
      title: '最近还款日',
      key: 'plat-4',
      render: (text, record, index) => (
        <>
          {
            +record.lastPeriodTime > 0
              ? <span>{moment(record.lastPeriodTime).format('YYYY-MM-DD')}</span>
              : ''
          }

        </>
      )
    },
    {
      title: '操作',
      key: 'plat-5',
      width: 220,
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.showSetCreditModal(record)}>设置信用</Button>

          <Button type='link' size='small' onClick={() => this.handleJumpToDetail(record)}>查看详情</Button>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getIIACredit()
  }

  // 获取IIA平台信息
  getIIACredit = async () => {
    const res = await Api.getIIACredit({})
    const { code, data = {} } = res
    if (+code === 10000) {
      this.setState({
        tableData: [data]
      })
    }
  }

  // 保存客户信用设置
  saveSetCredit = (params) => {
    Api.savePlatCreditConfig(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getIIACredit()
      }
    })
  }

  // 点击查看详情
  handleJumpToDetail = (record) => {
    this.props.history.push({ pathname: '/financial/platcreditDetail', state: { detailInfo: record } })
  }

  // 点击设置信用支付
  showSetCreditModal = (record) => {
    this.setState({
      setcreditModal: true,
      rowDetail: record || {}
    })
  }

  // 渲染信用支付状态
  renderCreditStatusLabel = (type = '') => {
    const obj = creditMap.find(item => { return item.type === type })
    const label = obj !== undefined ? obj.label : ''

    return label
  }

  // 选用Badge 状态
  renderCreditBadge = (type = '') => {
    const obj = creditMap.find(item => { return item.type === type })
    const status = obj !== undefined ? obj.status : ''

    return status
  }

  closeModal = () => {
    this.setState({
      setcreditModal: false,
      rechargeModal: false,
      rowDetail: {}
    })
  }

  render () {
    const { pages, tableData = [], setcreditModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bordered={false}>
          <Table rowKey='id'
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
          />
        </Card>

        {
          setcreditModal
            ? <SetcreditModal
              type={99}
              detailInfo={rowDetail}
              onConfirm={(params) => this.saveSetCredit(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </>
    )
  }
}
