import Ajax from '../utils/ajax'

export default {
  // 客户信息模糊查询
  getGuesteuUserLike (params) {
    return Ajax.post('/api/guest/list/page/euUser/like', params)
  },

  // 获取客户列表&客户详情
  getGuestListBySeller (params) {
    return Ajax.post('/api/guest/supplier/endUser/guestListBySeller', params)
  },

  // 企业列表模糊查询
  getOnSearchCompanyList (params) {
    return Ajax.post('/api/enterprise/info/fuzzySearch', params)
  },

  // 精确查找企业详细信息
  getCompanyDetailInfo (params) {
    return Ajax.post('/api/enterprise/info/getBasicInfo', params)
  },

  // 客户确认导入
  downloadList (params) {
    return Ajax.post('/api/guest/supplier/endUser/import', params)
  },

  // 保存客户编辑
  saveEditEnduser (params) {
    return Ajax.post('/api/guest/supplier/guest/euEdit', params)
  },

  // 新增客户
  saveAddEnduser (params) {
    return Ajax.post('/api/guest/supplier/guest/euAdd', params)
  },

  // 重置客户账号
  resetAccount (params) {
    return Ajax.post('/api/guest/supplier/guest/resetPassword', params)
  },

  // 校验公司名是否存在
  checkExistsName (params) {
    return Ajax.post('/api/common/getExists', params)
  }
}
