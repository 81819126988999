import SendAddress from '@/pages/warehouse/sendAddress/index'
import GoodsIn from '@/pages/warehouse/goodsIn/index'
import GoodsOut from '@/pages/warehouse/goodsOut/index'
import GoodsSend from '@/pages/warehouse/goodsSend/index'
import ExpressService from '@/pages/warehouse/expressService/index'
import ExpressRecord from '@/pages/warehouse/expressRecord/index'

import FuturesIn from '@/pages/warehouse/futuresIn/index' // 期货入库
import FuturesOut from '@/pages/warehouse/futuresOut/index' // 期货出库

export default [
  {
    path: '/warehouse/SendAddress',
    name: '发货地址',
    component: SendAddress
  },
  {
    path: '/warehouse/goodsIn',
    name: '商品入库',
    component: GoodsIn
  },
  {
    path: '/warehouse/goodsOut',
    name: '商品出库',
    component: GoodsOut
  },
  {
    path: '/warehouse/futuresIn',
    name: '期货入库',
    component: FuturesIn
  },
  {
    path: '/warehouse/futuresOut',
    name: '期货出库',
    component: FuturesOut
  },
  {
    path: '/warehouse/goodsSend',
    name: '商品出库',
    component: GoodsSend
  },
  {
    path: '/warehouse/expressService',
    name: '快递服务',
    component: ExpressService
  },
  {
    path: '/warehouse/expressRecord',
    name: '快递记录',
    component: ExpressRecord
  }
]
