import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Col, Select, Input, Upload, Button, Icon, Tooltip, message } from 'antd'
import $lodash from 'lodash'

import UploadApi from '@/common/api/uploadApi'
import { getStore, renderInvoiceTypeLabel } from '@/common/utils/mUtils'
// import SAASCourierJson from '@/assets/json/saasCourier.json'
import CourierJson from '@/assets/json/courier.json'

import filter from '@/common/utils/filter'
import styles from './index.module.scss'

const SIZE = 2
const FormItem = Form.Item
const Option = Select.Option
const { uploadOrdinaryImageUrl } = UploadApi
const { saasCouierCodeMaps } = filter

/**
 * 过略跨越速运
 */
const newSAASCourierJson = $lodash.filter(saasCouierCodeMaps, (item) => { return item.id !== 'SAAS-KYSY' })
const courierList = [ // 快递公司列表
  ...newSAASCourierJson,
  ...CourierJson
]

export default class InvoiceExpress extends Component {
  static propTypes = {
    form: PropTypes.object,
    openStatus: PropTypes.string,
    invoiceType: PropTypes.string, // 发票类型
    invoiceVoucherInfo: PropTypes.object,
    invoiceCodeKeys: PropTypes.array,
    addInvoiceCodeFormItem: PropTypes.func,
    removeInvoiceCodeFormItem: PropTypes.func
  }

  state = {

  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < SIZE
    if (!isLt1M) {
      message.warning(`图片大小不可超过 ${SIZE}MB！`)
    }

    return isLt1M
  }

  // 上传图片
  handleChangeUpload = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response

      if (+code === 10000) {
        message.success('上传成功')
        setTimeout(() => {
          this.props.form.setFieldsValue({
            invoiceImageUrl: data.imageUrl
          })
        }, 20)
      } else {
        message.error('上传文件失败')
      }
    }
  }

  // 删除图片
  handleRemoveInvoiceImage = () => {
    setTimeout(() => {
      this.props.form.setFieldsValue({ invoiceImageUrl: '' })
    }, 20)
  }

  // 选择物流公司
  handleChangeLogistics = (code) => {
    const logisticsWayEnum = $lodash.includes(code, 'SAAS') ? 'SAAS' : 'SELF'

    setTimeout(() => {
      this.props.form.setFieldsValue({ logisticsWayEnum: logisticsWayEnum })
    }, 20)
  }

  render () {
    const { form, openStatus, invoiceType, invoiceVoucherInfo = {}, invoiceCodeKeys = [], addInvoiceCodeFormItem, removeInvoiceCodeFormItem } = this.props
    const { getFieldDecorator, getFieldValue } = form

    return (
      <>
        {
          openStatus === 'OPENED'
            ? <Row gutter={24}>
              <Col span={24}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='发票类型'>{ renderInvoiceTypeLabel(invoiceType) }</FormItem>
                </div>
              </Col>

              <Col span={12}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='发票号码'>{ invoiceVoucherInfo.invoiceCode }</FormItem>
                </div>
              </Col>
              <Col span={12}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='发票图片'>{ invoiceVoucherInfo.invoiceImageUrl }</FormItem>
                </div>
              </Col>
            </Row>
            : <Row gutter={24}>
              <Col span={24}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='发票类型'>{ invoiceType && invoiceType !== '' ? renderInvoiceTypeLabel(invoiceType) : '----' }</FormItem>
                </div>
              </Col>
              <Col span={12}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem style={{ display: 'none' }}>
                    {
                      getFieldDecorator('logisticsWayEnum', {
                        initialValue: invoiceVoucherInfo.logisticsWay || 'SELF'
                      })(<Input disabled autoComplete='off' />)
                    }
                  </FormItem>
                  <FormItem label='物流公司'>
                    {
                      getFieldDecorator('courierCode', {
                        initialValue: invoiceVoucherInfo.courierCode,
                        rules: [
                          { required: false, message: '请选择物流公司' }
                        ]
                      })(
                        <Select placeholder='请选择...'
                          showSearch
                          optionFilterProp='children'
                          onChange={(value) => this.handleChangeLogistics(value)}>
                          {
                            courierList.map((item, i) => (
                              <Option value={item.courierCode} key={item.courierCode}>
                                { item.courierName }
                              </Option>
                            ))
                          }
                        </Select>
                      )
                    }
                  </FormItem>
                </div>
              </Col>
              <Col span={12}>
                {
                  getFieldValue('logisticsWayEnum') === 'SAAS'
                    ? <div className={`${styles.formitemRow}`}>
                      <FormItem label='快递单号'><div>IIASaaS服务将为您生成快递单号</div></FormItem>
                    </div>
                    : <div className={`${styles.formitemRow}`}>
                      <FormItem label='快递单号'>
                        {
                          getFieldDecorator('courierNumber', {
                            initialValue: invoiceVoucherInfo.courierNumber,
                            rules: [
                              { required: false, message: '请输入快递单号' }
                            ]
                          })(
                            <Input placeholder='请输入...' autoComplete='off' />
                          )
                        }
                      </FormItem>
                    </div>
                }
              </Col>

              {
                invoiceCodeKeys.map((item, index) => (
                  <Col span={12} key={`invoiceCodeInput-${index}`}>
                    <div className={`${styles.formitemRow}`}>
                      <FormItem label='发票号码'>
                        {
                          getFieldDecorator(`invoiceCodeKeys[${item.id}]`, {
                            initialValue: item.value
                          })(
                            <Input placeholder='请输入...' autoComplete='off' style={{ width: 'calc(100% - 60px)' }} maxLength={18} />
                          )
                        }
                        <div style={{ display: 'inline-block' }}>
                          <a className={`${styles.formitemBtn}`} onClick={() => addInvoiceCodeFormItem(index)}><Icon type='plus' /></a>
                          <a className={`${styles.formitemBtn}`} onClick={() => removeInvoiceCodeFormItem(index)}><Icon type='minus' /></a>
                        </div>
                      </FormItem>
                    </div>
                  </Col>
                ))
              }

              <Col span={12} style={{ overflow: 'hidden' }}>
                <FormItem label='发票图片' style={{ display: 'none' }}>
                  {
                    getFieldDecorator('invoiceImageUrl', {
                      initialValue: invoiceVoucherInfo.invoiceImageUrl
                    })(<Input disabled autoComplete='off' />)
                  }
                </FormItem>

                {
                  getFieldValue('invoiceImageUrl') === undefined || getFieldValue('invoiceImageUrl') === ''
                    ? <div className={`${styles.formitemRow}`}>
                      <FormItem label='发票图片'>
                        <Upload name='file' showUploadList={false}
                          accept='image/png, image/jpg, image/jpeg'
                          headers={{ Token: getStore('token') }}
                          action={uploadOrdinaryImageUrl}
                          beforeUpload={this.beforeUpload}
                          onChange={(info) => this.handleChangeUpload(info)}
                        >
                          <Button>
                            <Icon type='upload' />点击上传
                          </Button>
                        </Upload>

                      </FormItem>
                    </div>
                    : <div className={`${styles.row}`}>
                      <label className={`${styles.formitemLabel}`}>发票图片</label>
                      <div className={`${styles.rowInner}`}>
                        <Tooltip placement='topLeft' title='点击可查看详情'>
                          <a href={getFieldValue('invoiceImageUrl')} target='_blank' rel="noopener noreferer noreferrer">{getFieldValue('invoiceImageUrl')}</a>
                        </Tooltip>
                      </div>
                      <a className={`${styles.formitemBtn}`} onClick={() => this.handleRemoveInvoiceImage()}><Icon type='delete' style={{ fontSize: '16px' }} /></a>
                    </div>
                }
              </Col>
            </Row>
        }
      </>
    )
  }
}
