console.log(PATH_ENV)

const BASEURL = {
  prod: 'https://manage.eselling.china-iia.com',
  test: 'http://manage-eselling.iiasaas.net:8080',
  dev: 'http://manage-eselling.iiasaas.net:8080'
}

/** 关于测试环境url
 * 测试域名: http
 * 测试环境api BASEURL: http://manage.test-iia-eselling.com
 *
 * 测试穿透域名: http://eselling.iiasaas.net:8080
 * 测试穿透api BASEURL: http://manage-eselling.iiasaas.net:8080
*/

// websoket链接
const SOCKETWS = {
  prod: 'ws://ws.iiasaas.com:7999',
  test: 'ws://192.168.1.11:7999',
  dev: 'ws://192.168.1.11:7999'
}

const host = BASEURL[PATH_ENV]
const socketWs = SOCKETWS[PATH_ENV]

export default {
  host,
  socketWs
}
