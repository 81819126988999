import React, { Component } from 'react'
import { Menu } from 'antd'

import AccountInformation from '../account-information/index'
import AccountSettings from '../account-settings/index'

import styles from './index.module.scss'

const menuMaps = [
  { key: 'SETTING', name: '账户设置' },
  { key: 'INFORMATION', name: '基本信息' }
]

export default class AccountPersonal extends Component {
  state = {
    activeKey: 'SETTING'
  }

  handleChangeMenu = (key) => {
    this.setState({
      activeKey: key
    })
  }

  componentDidMount () {

  }

  render () {
    const { activeKey = 'SETTING' } = this.state

    return (
      <div className={`${styles.accountSettings}`}>
        <div className={`${styles.accountLeftMenu}`}>
          <Menu mode='inline' theme='light'
            defaultSelectedKeys={['SETTING']}
            onClick={(e) => this.handleChangeMenu(e.key)}
          >
            {
              menuMaps.map((item, i) => (
                <Menu.Item key={item.key}>{item.name}</Menu.Item>
              ))
            }
          </Menu>
        </div>

        <div className={`${styles.accountMain}`}>
          {
            activeKey === 'SETTING'
              ? <AccountSettings />
              : null
          }

          {
            activeKey === 'INFORMATION'
              ? <AccountInformation />
              : null
          }
        </div>
      </div>
    )
  }
}
