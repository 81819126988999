import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Card, Row, Col, Radio, Select, InputNumber, Input, DatePicker } from 'antd'
import $lodash from 'lodash'

// import FreightCalculator from '../../goodsSend/freightCalculator/index'
import SendHelp from '../../goodsSend/sendHelp/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderAddressLabel } from '@/common/utils/mUtils'
import CourierJson from '@/assets/json/courier.json'
// import SaasCourierJson from '@/assets/json/saasCourier.json'
import LogisticsExplainJson from '@/assets/json/logisticsExplain.json'

import styles from './index.module.scss'

const FormItem = Form.Item
const Option = Select.Option
const { TextArea } = Input
const { RangePicker } = DatePicker
const { saasCouierCodeMaps, expressTypeMaps, expressReceiptFlags, expressReceiptReturnRequirements, expressPackWoodenFrames } = filter

class SendExpressForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    onSelectAddress: PropTypes.func
  }

  state = {
    sendAddressList: [],
    selectedSenderDetail: {},
    tips: ''
  }

  componentDidMount () {
    this.getSendAddressList()
  }

  // 获取发货地址列表
  getSendAddressList = async () => {
    const res = await Api.getSendAddressList({})
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        sendAddressList: data || []
      }, () => {
        if (data && data.length > 0) {
          this.handleSelectedAddress(data[0].id)
        }
      })
    }
  }

  // 选择快递服务
  handleChangeServerType = (value) => {
    this.setState({
      tips: ''
    }, () => {
      this.props.form.setFieldsValue({
        transportType: null
      })
    })
  }

  // 选择快递方式
  handleChangeTransport = (value, courierCode) => {
    this.setState({
      tips: LogisticsExplainJson[courierCode][value]
    })
  }

  // 选择的发货地址
  handleSelectedAddress = (addressId) => {
    const { sendAddressList = [] } = this.state
    const obj = $lodash.find(sendAddressList, (o) => { return o.id === addressId })

    if (obj !== undefined) {
      this.props.onSelectAddress(obj)
    }

    this.setState({
      selectedSenderDetail: obj !== undefined ? obj : {}
    })
  }

  // 渲染快递方式选项
  renderTransportTypeOptions = (courierCode) => {
    const newArr = expressTypeMaps[courierCode]

    return (
      <Select onChange={(value) => this.handleChangeTransport(value, courierCode)}>
        {
          newArr.map((item) => (
            <Option value={item.id} key={item.id}>{ item.name }</Option>
          ))
        }
      </Select>
    )
  }

  findCourierNameByCode = () => {
    const wayEnums = this.props.form.getFieldValue('logisticsWayEnum').split('-')
    const obj = CourierJson.find((item) => { return item.courierCode === wayEnums[1] })
    const name = obj !== undefined ? obj.courierName : ''

    return name
  }

  render () {
    const { getFieldDecorator, getFieldValue } = this.props.form
    const { sendAddressList = [], selectedSenderDetail = {}, tips = '' } = this.state

    return (
      <div style={{ marginTop: '15px' }}>
        <Card title='快递信息' bodyStyle={{ padding: '15px' }} bordered={false}>
          <Row gutter={24}>
            <Col span={18} className={`${styles.bodyContainer}`}>
              <div className={`${styles.serverWrap}`}>
                <Form>
                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='快递服务'>
                      {
                        getFieldDecorator('logisticsWayEnum', {
                          initialValue: 'SAAS-DBL',
                          rules: [
                            { required: true, message: '请选择快递服务' }
                          ]
                        })(
                          <Radio.Group onChange={(e) => this.handleChangeServerType(e.target.value)}>
                            {
                              saasCouierCodeMaps.map((item, index) => (
                                <Radio value={item.id} key={item.id}>{ item.courierName }</Radio>
                              ))
                            }
                          </Radio.Group>
                        )
                      }
                    </FormItem>
                  </div>

                  <div className={`${styles.formitemRow}`}>
                    <FormItem label={<label className={`${styles.required}`}>快递公司</label>}>
                      {
                        this.findCourierNameByCode()
                      }
                    </FormItem>
                  </div>

                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='快递方式'>
                      {
                        getFieldDecorator('transportType', {
                          rules: [
                            { required: true, message: '请选择快递方式' }
                          ]
                        })(
                          this.renderTransportTypeOptions(getFieldValue('logisticsWayEnum'))
                        )
                      }
                    </FormItem>
                  </div>

                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='包裹数量'>
                      {
                        getFieldDecorator('totalNumber', {
                          rules: [
                            { required: true, message: '请填写包裹数量' }
                          ]
                        })(
                          <InputNumber min={1} />
                        )
                      }
                    </FormItem>
                  </div>

                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='发货地址'>
                      {
                        getFieldDecorator('sendAddressId', {
                          initialValue: sendAddressList && sendAddressList.length > 0 ? sendAddressList[0].id : null,
                          rules: [
                            { required: true, message: '请选择快递发货地址' }
                          ]
                        })(
                          <Select onChange={(value) => this.handleSelectedAddress(value)}>
                            {
                              sendAddressList.map((opt, n) => (
                              <Option value={opt.id} key={`addressOpt-${opt.id}-${n}`}>
                                {renderAddressLabel(opt.province, opt.city, opt.county)}{opt.address} {opt.name} {opt.mobile}
                              </Option>
                              ))
                            }
                          </Select>
                        )
                      }
                    </FormItem>
                  </div>

                  <div style={{ display: 'none' }}>
                    <FormItem>
                      {
                        getFieldDecorator('senderName', {
                          initialValue: selectedSenderDetail.name || ''
                        })(
                          <Input disabled autoComplete='off' />
                        )
                      }
                      {
                        getFieldDecorator('senderMobile', {
                          initialValue: selectedSenderDetail.mobile || ''
                        })(
                          <Input disabled autoComplete='off' />
                        )
                      }
                    </FormItem>
                    <FormItem>
                      {
                        getFieldDecorator('senderProvince', {
                          initialValue: selectedSenderDetail.province || ''
                        })(
                          <Input disabled autoComplete='off' />
                        )
                      }
                    </FormItem>
                    <FormItem>
                      {
                        getFieldDecorator('senderCity', {
                          initialValue: selectedSenderDetail.city || ''
                        })(
                          <Input disabled autoComplete='off' />
                        )
                      }
                    </FormItem>
                    <FormItem>
                      {
                        getFieldDecorator('senderArea', {
                          initialValue: selectedSenderDetail.county || ''
                        })(
                          <Input disabled autoComplete='off' />
                        )
                      }
                    </FormItem>
                    <FormItem>
                      {
                        getFieldDecorator('senderAddress', {
                          initialValue: selectedSenderDetail.address || ''
                        })(
                          <Input disabled autoComplete='off' />
                        )
                      }
                    </FormItem>
                  </div>

                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='付费类型'>
                    {
                      getFieldDecorator('logisticsPayType', {
                        initialValue: 'MONTHLY_STATEMENT',
                        rules: [
                          { required: true, message: '请选择付费类型' }
                        ]
                      })(
                        <Radio.Group buttonStyle='solid'>
                          <Radio.Button value='MONTHLY_STATEMENT'>包邮</Radio.Button>
                          { getFieldValue('logisticsWayEnum') !== 'SAAS-JD' ? <Radio.Button value='FREIGHT_COLLECT'>到付</Radio.Button> : null }
                        </Radio.Group>
                      )
                    }
                    </FormItem>
                  </div>

                  {
                    ['SAAS-DBL', 'SAAS-KYSY'].includes(getFieldValue('logisticsWayEnum'))
                      ? <div className={`${styles.formitemRow}`}>
                        <FormItem label='保价金额'>
                          {
                            getFieldDecorator('insuranceValue', {
                              rules: [
                                { required: false, message: '请填写保价金额' }
                              ]
                            })(
                              <InputNumber formatter={value => `￥ ${value}`} style={{ width: '120px' }} />
                            )
                          }
                        </FormItem>
                      </div>
                      : null
                  }

                  {
                    ['SAAS-DBL', 'SAAS-SF', 'SAAS-JD', 'SAAS-KYSY'].includes(getFieldValue('logisticsWayEnum'))
                      ? <div className={`${styles.formitemRow}`}>
                          <FormItem label='签单返还'>
                            {
                              getFieldDecorator('receiptType', {
                                initialValue: expressReceiptFlags[getFieldValue('logisticsWayEnum')][0].id,
                                rules: [
                                  { required: true, message: '请选择签单返还' }
                                ]
                              })(
                                <Select>
                                  {
                                    expressReceiptFlags[getFieldValue('logisticsWayEnum')].map((receiOpt, i) => (
                                      <Option value={receiOpt.id} key={`JDRECEIPT-${i}`}>{ receiOpt.name }</Option>
                                    ))
                                  }
                                </Select>
                              )
                            }
                          </FormItem>
                        </div>
                      : null
                  }

                  {
                    ['SAAS-DBL'].includes(getFieldValue('logisticsWayEnum')) && ['DEPPON_1', 'DEPPON_2'].includes(getFieldValue('receiptType'))
                      ? <div className={`${styles.formitemRow}`}>
                        <FormItem label='签收回单返单类型'>
                          {
                            getFieldDecorator('returnRequirement', {
                              rules: [
                                { required: true, message: '请选择签收回单返单类型' }
                              ]
                            })(
                              <Select>
                                {
                                  expressReceiptReturnRequirements[getFieldValue('logisticsWayEnum')].map((receiOpt, i) => (
                                    <Option value={receiOpt.id} key={`returnRequirement-${i}`}>{ receiOpt.name }</Option>
                                  ))
                                }
                              </Select>
                            )
                          }
                        </FormItem>
                      </div>
                      : null
                  }

                  { // 木架方式
                    ['SAAS-KYSY'].includes(getFieldValue('logisticsWayEnum'))
                      ? <div className={`${styles.formitemRow}`}>
                          <FormItem label='木架方式'>
                            {
                              getFieldDecorator('woodenFrame', {
                                initialValue: '0',
                                rules: [
                                  { required: true, message: '请选择木架方式' }
                                ]
                              })(
                                  <Select>
                                    {
                                      expressPackWoodenFrames[getFieldValue('logisticsWayEnum')].map((woodOpt, i) => (
                                        <Option value={woodOpt.id} key={`woodenFrameOpt-${i}`}>{ woodOpt.name }</Option>
                                      ))
                                    }
                                  </Select>
                              )
                            }
                          </FormItem>
                      </div>
                      : null
                  }

                  {
                    ['SAAS-DBL'].includes(getFieldValue('logisticsWayEnum'))
                      ? <div className={`${styles.formitemRow}`}>
                        <FormItem label='上门接货期限'>
                          {
                            getFieldDecorator('pickUpTimes', {

                            })(
                              <RangePicker showTime />
                            )
                          }
                        </FormItem>
                      </div>
                      : null
                  }

                  {
                    ['SAAS-KYSY'].includes(getFieldValue('logisticsWayEnum'))
                      ? <div className={`${styles.formitemRow}`}>
                        <FormItem label='预计重量'>
                          {
                            getFieldDecorator('actualWeight', {

                            })(
                              <InputNumber style={{ width: '120px' }} />
                            )
                          }
                          <span style={{ marginLeft: '8px' }}>kg</span>
                          <span className={'redColor'} style={{ marginLeft: '8px' }}>此项会影响调配车辆，请认真估计</span>
                        </FormItem>
                      </div>
                      : null
                  }

                  {
                    ['SAAS-DBL', 'SAAS-SF', 'SAAS-KYSY'].includes(getFieldValue('logisticsWayEnum'))
                      ? <div className={`${styles.formitemRow}`}>
                        <FormItem label='备注'>
                          {
                            getFieldDecorator('waybillRemark', {

                            })(
                              <TextArea placeholder={`请输入内容，不超过${['SAAS-DBL', 'SAAS-SF'].includes(getFieldValue('logisticsWayEnum')) ? 100 : 200}字...`}
                                rows={4}
                                maxLength={['SAAS-DBL', 'SAAS-SF'].includes(getFieldValue('logisticsWayEnum')) ? 100 : 200}
                              />
                            )
                          }
                        </FormItem>
                      </div>
                      : null
                  }
                </Form>
              </div>

              {/* <div className={`${styles.freightWrap}`}>
                <FreightCalculator info={{ ...receiverDetail }}
                  sendAddressId={this.props.form.getFieldValue('sendAddressId')}
                  serverTypes={this.props.form.getFieldValue('logisticsWayEnum')}
                  addressList={sendAddressList}
                />
              </div> */}
            </Col>

            <Col span={6}>
              <SendHelp iscloudServer={true} tips={tips} />
            </Col>
          </Row>
        </Card>
      </div>
    )
  }
}

const SendExpress = Form.create()(SendExpressForm)

export default SendExpress
