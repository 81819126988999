import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Badge } from 'antd'

import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'

const { billStatusMaps } = filter
const { SERIALWIDTH } = consts

export default class BillCard extends Component {
  static propTypes = {
    tableData: PropTypes.array
  }

  state = {}

  columns = [
    {
      title: '序号',
      key: 'paninsuranceBill-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '账单日期',
      key: 'paninsuranceBill-1',
      dataIndex: 'dueMonth'
    },
    {
      title: '账单状态',
      key: 'paninsuranceBill-2',
      render: (text, record, index) => (
        <div>
          { this.renderInsuranceStatusLabel(record.status) }
        </div>
      )
    },
    {
      title: '应付金额',
      key: 'paninsuranceBill-3',
      render: (text, record, index) => (
        <div>&yen;{ (+record.totalAmount * 100 / 100).toFixed(2) }</div>
      )
    },
    {
      title: '已付金额',
      key: 'paninsuranceBill-4',
      render: (text, record, index) => (
        <div>&yen;{(+record.payAmount * 100 / 100).toFixed(2)} </div>
      )
    },
    {
      title: '未付金额',
      key: 'paninsuranceBill-5',
      render: (text, record, index) => (
        <div>&yen;{ ((+record.totalAmount - (+record.payAmount)) * 100 / 100).toFixed(2) }</div>
      )
    }
  ]

  componentDidMount () {

  }

  // 渲染账单状态
  renderInsuranceStatusLabel = (status) => {
    let label = ''
    const obj = billStatusMaps.find((item) => { return item.id === status })

    if (obj) {
      label = <Badge status={obj.badge} text={<span style={{ fontSize: '12px' }}>{obj.name}</span>} />
    }

    return label
  }

  render () {
    const { tableData = [] } = this.props

    return (
      <>
        <Table rowKey='id'
          columns={this.columns}
          dataSource={tableData}
          pagination={false}
        />
      </>
    )
  }
}
