import React, { Component } from 'react'
import { Card, Table, message, Modal, Button, Icon } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import SupplyStatistic from './supplyStatistic'
import DetailModal from './detailModal'
import DowncolModal from '../component/downcolModal'
import PayVoucherModal from './payVoucherModal/index'
import OrderEnclosureDrawer from '../component/orderEnclosureDrawer/index'

import Api from '@/common/api'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import { renderOrderTypeLabel, renderOrderStatusLabel, renderOrderDeliveryStatusLabel, renderOrderPaymentStatusLabel } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

const { pageSizeOptions, cloudSupplyOrderStatusMaps, orderDeliveryStatusMaps, paymethodMaps, orderPaymentStatusMaps } = filter
const { SERIALWIDTH } = consts
const { confirm } = Modal

export default class SupplyOrder extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...'
        }
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '订单日期'
        },
        cptParams: {
          placeholder: '请选择...'
        }
      },
      {
        name: 'orderStatusList',
        formType: 'Select',
        itemParams: {
          label: '订单状态'
        },
        cptParams: {
          placeholder: '请选择...',
          mode: 'multiple'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...cloudSupplyOrderStatusMaps
        ]
      },
      {
        name: 'paymentStatus',
        formType: 'Select',
        itemParams: {
          label: '付款状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderPaymentStatusMaps
        ]
      },
      {
        name: 'deliveryStatus',
        formType: 'Select',
        itemParams: {
          label: '发货状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderDeliveryStatusMaps
        ]
      },
      {
        name: 'payType',
        formType: 'Select',
        itemParams: {
          label: '支付方式'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: -1, name: '全部' },
          ...paymethodMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    summary: {}, // 订单金额汇总
    supplyModal: false,
    downcolModal: false, // 自定义导出项弹窗
    payVoucherModal: false, // 付款凭证明细弹窗
    enclosureModal: false,
    rowDetail: {} // 当前选择的行数据
  }

  columns = [
    {
      title: '序号',
      key: 'supplyOrderCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '订单类型',
      key: 'supplyOrderCol-3',
      dataIndex: 'orderType',
      render: (text, record, index) => (
        <>{ renderOrderTypeLabel(record.orderType) }</>
      )
    },
    {
      title: '订单日期',
      key: 'supplyOrderCol-1',
      render: (text, record, index) => (
        <>
          {
            record.orderTime
              ? <>{moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss')}</>
              : <>----</>
          }
        </>
      )
    },
    {
      title: '订单编号',
      key: 'supplyOrderCol-2',
      dataIndex: 'orderId'
    },
    {
      title: '客户名称',
      key: 'supplyOrderCol-4',
      dataIndex: 'customerName'
    },
    {
      title: '订单金额',
      key: 'supplyOrderCol-5',
      dataIndex: 'payableAmount',
      render: (text, record, index) => (
        <>&yen;{ (+record.payableAmount * 100 / 100).toFixed(2) }</>
      )
    },
    {
      title: '已付金额',
      key: 'supplyOrderCol-6',
      dataIndex: 'paidAmount ',
      render: (text, record, index) => (
        <>&yen;{(+record.paidAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '未付款金额',
      key: 'supplyOrderCol-7',
      dataIndex: 'unpaidAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.unpaidAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '付款状态',
      key: 'supplyOrderCol-8',
      dataIndex: 'payStatus',
      render: (text, record, index) => (
        <>{ renderOrderPaymentStatusLabel(record.paymentStatus) }</>
      )
    },
    {
      title: '发货状态',
      key: 'supplyOrderCol-9',
      dataIndex: 'deliveryStatus',
      render: (text, record, index) => (
        <>{ renderOrderDeliveryStatusLabel(record.deliveryStatus) }</>
      )
    },
    {
      title: '订单状态',
      key: 'supplyOrderCol-10',
      dataIndex: 'orderStatus',
      render: (text, record, index) => (
        <>{ renderOrderStatusLabel(record.orderStatus) }</>
      )
    },
    {
      title: '付款凭证',
      key: 'supplyOrderCol-11',
      render: (text, record, index) => (
        <Button type='link' size='small' onClick={() => this.handleShowVoucherModal(record)}>点击显示</Button>
      )
    },
    {
      title: '支付方式',
      key: 'supplyOrderCol-12',
      dataIndex: 'payTypeDesc'
    },
    {
      title: '订单附件',
      key: 'supplyOrderCol-13',
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.showOrderEnclosureModal(record)}>点击查看</Button>
        </>
      )
    },
    {
      title: '操作',
      key: 'supplyOrderCol-14',
      width: 260,
      render: (text, record, index) => {
        const { orderType } = record
        return (
          <>
            <Button type='link' size='small' onClick={() => this.showSupplyDetailModal(record)}>查看明细</Button>

            {
              orderType !== 'ORDER_CLOUD_FUTURES' && ['PAYING', 'TAIL_PAYING'].includes(record.orderStatus)
                ? <Button type='link' size='small' onClick={() => this.handleConfirmCollect(record.purchaseOrderId)}>
                  确认收款
                </Button>
                : null
            }

            <Button type='link' size='small' onClick={() => this.handleDownloadContract(record)}>下载合同</Button>
          </>
        )
      }
    }
  ]

  componentDidMount () {
    this.getSupplyOrderList()
    this.getTradeOrderAmountSummary()
  }

  // 获取云供应订单列表
  getSupplyOrderList = () => {
    const { pages, query } = this.state

    const params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getSupplyOrderList(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total
          }
        })
      }
    })
  }

  // 获取订单金额汇总
  getTradeOrderAmountSummary = () => {
    const { pages } = this.state
    const params = {
      orderType: 'PLATFORM_ORDER',
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getTradeOrderAmountSummary(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          summary: data || {}
        })
      }
    })
  }

  // 获取订单明细
  getSupplyOrderDetail = (params) => {
    return Api.getSupplyOrderDetail(params)
  }

  // 确认收款
  confirmSupplyCollect = (purchaseOrderId) => {
    Api.confirmSupplyCollect({ purchaseOrderId: purchaseOrderId }).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getSupplyOrderList()
      } else {
        message.warning(res.message)
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.times) {
      search.beginDate = search.times.length > 0 ? search.times[0].format('YYYY-MM-DD') : ''
      search.endDate = search.times.length > 0 ? search.times[1].format('YYYY-MM-DD') : ''

      delete search.times
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => {
      this.getSupplyOrderList()
    })
  }

  // 查看订单明细
  showSupplyDetailModal = (record = {}) => {
    this.setState({
      supplyModal: true,
      rowDetail: record || {}
    })
  }

  // 点击确认收款
  handleConfirmCollect = (purchaseOrderId) => {
    confirm({
      title: '确认已经收款?',
      content: '',
      onOk: () => {
        this.confirmSupplyCollect(purchaseOrderId)
      }
    })
  }

  // 点击查看付款凭证
  handleShowVoucherModal = (record) => {
    this.setState({
      rowDetail: record,
      payVoucherModal: true
    })
  }

  // 点击下载合同
  handleDownloadContract = async (record) => {
    const res = await Api.getCloudSupplyContractDownFile({ purchaseOrderId: record.purchaseOrderId })
    const { code, data } = res
    if (+code === 10000) {
      window.open(data.fileKey)
    }
  }

  // 点击导出订单
  showDowncolModal = () => {
    const { query = {} } = this.state
    if ((!query.orderId || query.orderId === '') && !query.beginDate) {
      return message.warning('请选择要导出订单的时间段！')
    }

    this.setState({
      downcolModal: true
    })
  }

  // 点击导出订单
  downloadOrder = (fields) => {
    const { query } = this.state

    const params = {
      orderId: query.orderId,
      fieldList: fields,
      beginDate: query.beginDate,
      endDate: query.endDate,
      pageNo: 1,
      pageSize: 99999
    }

    DownloadApi.dowloadSupplyOrder(params)
  }

  // 点击查看订单附件
  showOrderEnclosureModal = (record) => {
    this.setState({
      rowDetail: record,
      enclosureModal: true
    })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      supplyModal: false,
      downcolModal: false,
      payVoucherModal: false,
      enclosureModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSupplyOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSupplyOrderList()
    })
  }

  render () {
    const { tableData, pages, searchData, supplyModal = false, downcolModal = false, payVoucherModal = false, enclosureModal = false, rowDetail = {}, summary = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bordered={false}>
          <div style={{ marginBottom: '20px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </div>

          <div style={{ marginBottom: '15px' }}>
            <Button type='primary' onClick={() => this.showDowncolModal()}><Icon type="download" />导出订单</Button>
          </div>

          <SupplyStatistic totalAmount={summary.payableAmount } paidAmount={summary.paidAmount} unPaidAmount={summary.payableAmount - summary.paidAmount} />

          <Table rowKey={(record, index) => { return `${record.orderId}-${index}` }}
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
            scroll={{ x: 1400 }}
          />
        </Card>

        {
          supplyModal
            ? <DetailModal
              detailInfo={rowDetail}
              getDetail={(params) => this.getSupplyOrderDetail(params)}
              onConfirm={this.closeModal}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          downcolModal
            ? <DowncolModal
              onCancel={() => this.closeModal()}
              onConfirm={(params) => this.downloadOrder(params)}
            />
            : null
        }

        {
          payVoucherModal
            ? <PayVoucherModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {/* 订单附件弹窗 */}
        <OrderEnclosureDrawer
          visible={enclosureModal}
          name={'SUPPLY_ENCLOSURE'}
          detailInfo={rowDetail}
          onCancel={() => this.closeModal()}
        />
      </>
    )
  }
}
