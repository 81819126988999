import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Row, Col, Statistic } from 'antd'

export default function SupplyStatistic (props) {
  const { totalAmount = 0, paidAmount = 0, unPaidAmount = 0 } = props

  return (
    <div style={{ marginBottom: '15px' }}>
      <Alert type='info' message={
        <Row gutter={24}>
          <Col span={6}>
            <Statistic title={<span style={{ color: '#3E7BFA', fontSize: '18px' }}>订单总金额</span>}
              value={totalAmount}
              precision={2}
              prefix={<>&yen;</>}
            />
          </Col>

          <Col span={6}>
            <Statistic title={<span style={{ color: '#F97011', fontSize: '18px' }}>已付总金额</span>}
              value={paidAmount}
              precision={2}
              prefix={<>&yen;</>}
            />
          </Col>

          <Col span={6}>
            <Statistic title={<span style={{ color: '#FF4B4A', fontSize: '18px' }}>未付总金额</span>}
              value={unPaidAmount}
              precision={2}
              prefix={<>&yen;</>}
            />
          </Col>
        </Row>
      } />
    </div>
  )
}

SupplyStatistic.propTypes = {
  totalAmount: PropTypes.number,
  paidAmount: PropTypes.number,
  unPaidAmount: PropTypes.number
}
