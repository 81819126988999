
// 正整数
const num = {
  validator: (rule, value, cb) => {
    if (value && !/^[0-9]*[1-9][0-9]*$/.test(value)) {
      const a = '请输入正整数'
      cb(a)
    } else {
      cb()
    }
  }
}
// 手机号校验
const phone = {
  validator: (rule, value, cb) => {
    if (value && !/^1[3456789]\d{9}$/.test(value)) {
      const b = '请输入正确的手机号'
      cb(b)
    } else {
      cb()
    }
  }
}

/* 密码规则
*  6-12位字符 可包含数字，大小写字母
*/
const passReg = /^[a-zA-Z0-9]{6,12}$/

/* 支付密码规则
*  6位数字
*/
const paywordReg = /^\d{6}$/

// 手机号码规则
const phoneReg = /^1[3456789]\d{9}$/

/* 登录名规则
*  4-16位字符 可包含数字、大小写字母、下划线
*/
const loginnameReg = /^[a-zA-Z0-9_]{4,16}$/

/* 邮箱规则
*/
const emailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/

// 去掉字符串首尾空格
const trim = (str) => {
  return str.replace(/(^\s*)|(\s*$)/g, '')
}

// 去掉字符串所有空格
const trimAll = (str) => {
  return str.replace(/\s+/g, '')
}

// 去掉中间空格
const CTrim = (str) => {
  return str.replace(/\s/g, '')
}

// 去掉字符串所有中横线
const trimLine = (str) => {
  return str.replace(/-/g, '')
}

// 替换字符串中的换行
const repLineBreak = (str, sym) => {
  return str.replace(/\n/g, sym)
}

/**
 * 分页配置
 */
const pageSizeOptions = ['10', '20', '50', '100', '200']

// 期货 货期单位
const shipmentUtilMap = {
  FUTURES: '天',
  CLOUD_FUTURES: '个工作日'
}

// 产品类型
const skuGoodsTypeMaps = [
  { id: 'SPOT', name: '现货', aliasName: '现货' },
  { id: 'FUTURES', name: '期货', aliasName: '期货' },
  { id: 'CLOUD_SPOT', name: '云现货', aliasName: '现货调货' },
  { id: 'CLOUD_FUTURES', name: '云期货', aliasName: '期货调货' }
]

// 现货 &期货 &云供应 上架状态
const productPutOnMaps = [
  { id: 'ENABLE', name: '已上架', color: '#269C42' },
  { id: 'DISABLE', name: '未上架', color: '#FF4B4A' }
]

// 代理证状态
const brandAgencyStatusMaps = [
  { id: 'AUDIT_SUCCESS', name: '已审核通过', color: '#269C42' },
  { id: 'AUDIT_FAIL', name: '缺少代理证', color: '#FF4B4A' }
]

// 云仓等级枚举
const cloudStoreLevelMaps = [
  { id: 'FIRST_LEVEL', name: '一级' },
  { id: 'SECOND_LEVEL', name: '二级' },
  { id: 'LAST_LEVEL', name: '三级' }
]

// 支付方式
const paymethodMaps = [
  { id: 1, name: '余额支付' },
  { id: 2, name: '记账宝支付' },
  { id: 4, name: '支付宝支付' },
  { id: 8, name: '信用支付' },
  { id: 16, name: '微信支付' },
  { id: 128, name: '银行转账' },
  { id: 64, name: '账期支付' },
  { id: 256, name: '承兑汇票' }
]

// 订单取消状态集合
const cancelOrderStatusMaps = [
  { id: 'CANCEL_IN_PAYING', code: '11', name: '未支付取消' },
  { id: 'CANCEL_OVERDUE', code: '12', name: '超时取消' },
  { id: 'CANCEL_MANUAL', code: '13', name: '手动取消' },
  { id: 'CANCEL_AFTER_PAYING', code: '90', name: '已付首款取消' }
]

// 发货-订单类型
const orderTypeMaps = [
  { id: 'ORDER_HYBRID', code: '0', name: '混合订单' },
  { id: 'ORDER_STOCK', code: '1', name: '自营现货订单' },
  { id: 'ORDER_FUTURES', code: '2', name: '自营期货订单' },
  { id: 'ORDER_CLOUD_STOCK', code: '3', name: '云供应现货订单' },
  { id: 'ORDER_CLOUD_FUTURES', code: '5', name: '云供应期货订单' },
  { id: 'ORDER_CLOUD_STOCK_PROCUREMENT', code: '4', name: '云采购现货订单' },
  { id: 'ORDER_CLOUD_FUTURES_PROCUREMENT', name: '云采购期货订单' }
]

// 订单付款状态
const orderPaymentStatusMaps = [
  { id: 'NO', code: 10, name: '未付款' },
  { id: 'PART', code: 50, name: '已付首款' },
  { id: 'COMPLETE', code: 100, name: '全部付款' }
]

// 订单到货状态
const orderArriverStatusMaps = [
  { id: 'UN_ARRIVED', name: '未到货' },
  { id: 'PART', name: '部分到货' },
  { id: 'ARRIVED', name: '已到货' }
]

// 订单列表 -订单发货状态
const orderDeliveryStatusMaps = [
  { id: 'NO', code: 10, name: '待发货' },
  { id: 'PART', code: 50, name: '部分发货' },
  { id: 'COMPLETE', code: 100, name: '已发货' }
]

// 发货- 拣货单货状态
const pickingOrderSendStatusMaps = [
  { id: 'AWAIT_SEND_OUT', code: '3', name: '待发货' },
  { id: 'PART_DELIVER_GOODS', code: '105', name: '部分发货' },
  { id: 'ALREADY_SEND_OUT', code: '4', name: '已发货' }
]

// 开票状态枚举
const invoiceOpenedMaps = [
  { id: 'ALL', name: '全部' },
  { id: 'OPENING', name: '未开票' },
  { id: 'OPENED', name: '已开票' }
]

// IIASaaS保险服务发票&IIASaaS快递服务发票状态
const serverInvoiceOpenedMaps = [
  { id: 'UN_INVOICED', name: '未开票', badge: 'error' },
  { id: 'INVOICED', name: '已开票', badge: 'success' }
]

// 账单状态(德邦 顺丰 保险)
const billStatusMaps = [
  { id: 'ALL', name: '全部' },
  { id: 'INIT', name: '初始化', badge: 'processing' },
  { id: 'OVERDUE', name: '已逾期', badge: 'error' },
  { id: 'SETTLED', name: '已结算', badge: 'success' },
  { id: 'NOT_OVERDUE', name: '未逾期', badge: 'warning' }
]

// 保险服务类型枚举
const insuranceTypeMaps = [
  { id: 'COMMON', name: '普通' },
  { id: 'YEAR', name: '年费' }
]

// 物流服务类型枚举
const logisticsWayMaps = [
  { id: 'SELF', name: '自营服务' },
  { id: 'SAAS', name: 'IIASaaS服务' },
  { id: 'TAKE_THEIR', name: '客户自提' }
]

// IIASaaS快递服务公司
const saasCouierCodeMaps = [
  {
    id: 'SAAS-DBL',
    courierCode: 'SAAS-DBL',
    courierName: 'IIASaaS服务（德邦）',
    aliasCode: 'DBL',
    aliasName: '德邦快递'
  },
  // {
  //   id: 'SAAS-SF',
  //   courierCode: 'SAAS-SF',
  //   courierName: 'IIASaaS服务（顺丰）',
  //   aliasCode: 'SF',
  //   aliasName: '顺丰速运'
  // },
  // {
  //   id: 'SAAS-JD',
  //   courierCode: 'SAAS-JD',
  //   courierName: 'IIASaaS服务（京东）',
  //   aliasCode: 'JD',
  //   aliasName: '京东快递'
  // },
  {
    id: 'SAAS-KYSY',
    courierCode: 'SAAS-KYSY',
    courierName: 'IIASaaS服务（跨越速运）',
    aliasCode: 'KYSY',
    aliasName: '跨越速运'
  }
]

// IIASaaS快递服务类型
const expressTypeMaps = {
  'SAAS-DBL': [
    { id: 'PACKAGE', name: '标准快递', keysort: 'courierDiscount' },
    { id: 'RCP', name: '大件快递360', keysort: 'courierDiscount' },
    { id: 'JZKH', name: '精准卡航', keysort: '--' },
    { id: 'JZQY_LONG', name: '精准汽运', keysort: '--' },
    { id: 'NZBRH', name: '重包入户', keysort: 'logisticsDiscount' },
    { id: 'ZBTH', name: '重包特惠', keysort: 'logisticsDiscount' }
  ],
  'SAAS-SF': [
    { id: '1', name: '顺丰特快（空运）' },
    { id: '2', name: '顺丰标快（陆运）' },
    { id: '255', name: '顺丰卡航（物流）' },
    { id: '111', name: '顺丰干配' },
    { id: '231', name: '陆运包裹' },
    { id: '263', name: '同城半日达（快递）' }
  ],
  'SAAS-JD': [
    { id: '1', name: '特惠送' },
    { id: '6', name: '特快零担' },
    { id: '25', name: '特快重货' }
  ],
  'SAAS-KYSY': [
    { id: '20', name: '次日达' },
    { id: '30', name: '隔日达' },
    { id: '40', name: '陆运件' },
    { id: '50', name: '同城次日' },
    { id: '160', name: '省内次日' }
  ]
}

// IIASaaS快递服务- 签单返还
const expressReceiptFlags = {
  'SAAS-DBL': [
    { id: 'DEPPON_0', name: '无需返单' },
    { id: 'DEPPON_1', name: '签收单原件返回' },
    { id: 'DEPPON_2', name: '电子签收单' }
  ],
  'SAAS-SF': [
    { id: 'SF_NO', name: '无需返单' },
    { id: 'SF_IN03', name: '纸质回单' },
    { id: 'SF_IN149', name: '电子回单' }
  ],
  'SAAS-JD': [
    { id: 'JD_0', name: '无签单' },
    { id: 'JD_1', name: '纸质签单' },
    { id: 'JD_3', name: '电子签单' },
    { id: 'JD_4', name: '纸质签单和电子签单' }
  ],
  'SAAS-KYSY': [
    { id: 'KY_20', name: '无需回单', key: '20' },
    { id: 'KY_10', name: '回单原件(含回单照片)', key: '10' },
    { id: 'KY_30', name: '回单照片', key: '30' },
    { id: 'KY_40', name: '电子回单', key: '40' }
  ]
}

// IIASaaS快递服务- 签收单返回类型枚举类
const expressReceiptReturnRequirements = {
  'SAAS-DBL': [
    { id: 'R1', name: '签名' },
    { id: 'R2', name: '盖章' },
    { id: 'R3', name: '签身份证号' },
    { id: 'R4', name: '身份证复印件' },
    { id: 'R5', name: '仓库收货回执单' }
  ]
}

// 快递服务木架包装方式枚举
const expressPackWoodenFrames = {
  'SAAS-KYSY': [
    { id: '0', name: '不打木架' },
    { id: '10', name: '打卡板' },
    { id: '20', name: '打木架' },
    { id: '30', name: '打木箱' }
  ]
}

// 物流价格对账状态
const dzMap = [
  { id: 'UNKNOWN', name: '未知', badge: 'error' },
  { id: 'NO_SUBMIT', name: '未申请', badge: 'warning' },
  { id: 'YES_SUBMIT', name: '已申请', badge: 'success' }
]

// 对账单提交状态
const expressmoenyMap = [
  { id: 'SUCCESS', name: '是', badge: 'success' },
  { id: 'FAIL', name: '否', badge: 'warning' },
  { id: 'UNABLE_PROCESS', name: '无法计算', badge: 'error' }
]

// 云供应品牌审核状态
const auditStatusMaps = [
  { id: 'AUDIT_UNKNOWN', name: '未申请', badge: 'warning' },
  { id: 'AUDIT_ACCEPTED', name: '审核中', badge: 'processing' },
  { id: 'AUDIT_SUCCESS', name: '已通过', badge: 'success' },
  { id: 'AUDIT_FAIL', name: '已拒绝', badge: 'error' },
  { id: 'AUDIT_OVERDUE', name: '已逾期', badge: 'default' }
]

// 云供应订单状态
const cloudSupplyOrderStatusMaps = [
  { id: 'INITIAL', name: '待采购方付款' },
  { id: 'PAYING', name: '待付款' },
  { id: 'CANCEL_IN_PAYING', name: '未支付取消' },
  { id: 'CANCEL_OVERDUE', name: '超时取消' },
  { id: 'CANCEL_AFTER_PAYING', name: '已付首款取消' },
  { id: 'TAIL_PAYING', name: '待付尾款' },
  { id: 'WAIT_TO_SHIP', name: '待发货' },
  { id: 'WAIT_ORDER_GOODS', name: '待订货' },
  { id: 'WAIT_TO_RECEIVE', name: '已发货' },
  { id: 'ORDER_COMPLETE', name: '已完成' }
]

// 导入类型
const importConfig = {
  storeInSetting: { sheetSourch: 'PRODUCT_STOCK_JUST_SUPPLY' }, // 现货入库导入
  supplyProductSetting: { sheetSourch: 'PRODUCT_CLOUD_JUST_SUPPLY' }, // 现货云供应价格导入
  supplyFuturesSetting: { sheetSourch: 'PRODUCT_CLOUD_FUTURES_JUST_SUPPLY' } // 期货云供应价格导入
}

// 发票- 发票类型枚举
const invoiceTypeMaps = [
  { id: 'ORDINARY_INVOICE', name: '增值税普通发票' },
  { id: 'SPECIAL_INVOICE', name: '增值税专用发票' }
]

// 发票- 开票状态枚举
const invoiceOpenedStatusMaps = [
  { id: 'OPENING', name: '待开票' },
  { id: 'OPENED', name: '已开票' },
  { id: 'DISCARDED', name: '已废弃' }
]

// 售后- 售后类型
const saledTypeMaps = [
  { id: 'REFUND_RETURN_PRODUCTS', name: '退货退款' },
  { id: 'REFUND', name: '退款' },
  { id: 'CHANGE_PRODUCTS', name: '换货' },
  { id: 'ADD_DELIVER', name: '补寄' }
]

// 售后- 售后状态
const selfSaledStatusMaps = [
  { id: 'WAITING_AUDIT', name: '待审核' },
  { id: 'AUDIT_SUCCESS', name: '审核通过' },
  { id: 'AUDIT_FAILED', name: '审核驳回' }
]

// 售后- 售后原因枚举
const saledReasonTypeMaps = [
  { id: 'PRODUCT_FOR_PAGE_NOT_SAME', name: '商品与页面描述不符' },
  { id: 'SKU_CHOOSE_ERROR', name: '型号订货号选择错误' },
  { id: 'GOODS_MISSED', name: '少件/发错货/未收到货' },
  { id: 'GOODS_WAS_BAD', name: '商品破损/包装破损/序列号模糊' },
  { id: 'PRODUCTS_QUALITY_PROBLEM', name: '商品质量问题/存在故障' },
  { id: 'FAKE_OR_SECOND_HAND_GOODS', name: '商品疑似假货/二手商品' }
]

// 售后- 退款方式枚举
const saledRefundTypeMaps = [
  { id: 'ORIGINAL_PAYMENT_TYPE', name: '原支付返还' }
]

export default {
  num,
  phone,
  loginnameReg,
  passReg,
  paywordReg,
  phoneReg,
  emailReg,

  trim,
  trimAll,
  CTrim,
  trimLine,
  repLineBreak,

  pageSizeOptions,
  shipmentUtilMap,
  skuGoodsTypeMaps,
  paymethodMaps,
  orderDeliveryStatusMaps, // 订单发货状态
  pickingOrderSendStatusMaps, // 拣货单发货状态
  orderTypeMaps,
  cloudSupplyOrderStatusMaps,
  brandAgencyStatusMaps, // 代理证状态
  cloudStoreLevelMaps,
  productPutOnMaps,
  auditStatusMaps,

  dzMap,
  orderPaymentStatusMaps,
  cancelOrderStatusMaps,
  orderArriverStatusMaps,

  logisticsWayMaps,
  invoiceOpenedMaps,
  serverInvoiceOpenedMaps,
  billStatusMaps,
  importConfig,

  saasCouierCodeMaps,
  expressTypeMaps,
  expressReceiptFlags,
  expressReceiptReturnRequirements,
  expressPackWoodenFrames,
  expressmoenyMap,
  insuranceTypeMaps,

  invoiceTypeMaps,
  invoiceOpenedStatusMaps,
  saledTypeMaps,
  selfSaledStatusMaps,
  saledReasonTypeMaps,
  saledRefundTypeMaps
}
