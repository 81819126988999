import Customerlist from '@/pages/customer/customerList/index' // 客户列表
import CustomerAdress from '@/pages/customer/customer-addressDetail/index' // 收货地址详情
import CustomerBeforeUpload from '@/pages/customer/customerList/beforeuoload' // 导入客户
import CustomerUploadinfo from '@/pages/customer/customerList/uploadinfo' // 成功客户导入信息展示
import EnduserDetail from '../pages/customer/customerInfo/index' // 添加/修改客户

export default [
  {
    path: '/customer/customerList',
    name: '客户列表',
    component: Customerlist
  },
  {
    path: '/customer/Adress',
    name: '客户地址',
    component: CustomerAdress
  },
  {
    path: '/customer/beforeUpload',
    name: '导入详情',
    component: CustomerBeforeUpload
  },
  {
    path: '/customer/Uploadinfo',
    name: '导入详情',
    component: CustomerUploadinfo
  },
  {
    path: '/customer/enduserDetail',
    name: '客户信息',
    component: EnduserDetail
  }
]
