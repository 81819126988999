import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Menu, Button } from 'antd'

import PersonalUesr from '../UesrInfo/index'

import styles from './index.module.scss'

export default class CustomerInfo extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    // MenuKey: // 1是个人,2是企业
    activeMenuKey: this.props.history.location.state && +this.props.history.location.state.enterpriseId > 0 ? '2' : '1'

  }

  handleChangeMenu = (e) => {
    if (+this.props.history.location.state.type === 1) {
      this.setState({
        activeMenuKey: e.key
      })
    }
  }

  // 返回客户列表
  handleBack = () => {
    this.props.history.goBack()
  }

  render () {
    const { activeMenuKey } = this.state

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <Button icon='left' onClick={() => this.handleBack()}>返回</Button>
          </Card>
        </div>

        <Card bodyStyle={{ padding: '15px' }}>
          <div className={`${styles.wrap}`}>
            <div className={`${styles.leftMenu}`}>
              <Menu mode='inline' theme='light' style={{ height: '380px' }}
                selectedKeys={[activeMenuKey]}
                onClick={(e) => this.handleChangeMenu(e)}
              >
                <Menu.Item key="1">个人用户</Menu.Item>
                <Menu.Item key="2"> 企业用户</Menu.Item>
              </Menu>
            </div>

            <div className={`${styles.rightContent}`}>
              <PersonalUesr menuKey={activeMenuKey} />
            </div>
          </div>
        </Card>
      </>
    )
  }
}
